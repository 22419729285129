import React from "react"
import { Input, Button, Row, Col } from "reactstrap"
import moment from "moment"
import Datetime from "react-datetime"

import { Loading } from "@common/EcosuiteComponent"
import EcosuiteForm, { FormError, NotesArrayFieldTemplate } from "@common/form/EcosuiteForm"
import EcosuiteView from "@common/module/EcosuiteView"
import Icon from "@common/display/Icon"

import EventService from "@dashboard/event/EventService"
import ServiceRequestService from "@dashboard/event/ServiceRequestService"
import EventUtils from "@dashboard/event/EventUtils"
import EventDatePickerWidget from "./EventDatePickerWidget"
import { Link } from "react-router-dom"
import Select from "react-select"

import "react-datetime/css/react-datetime.css"
import i18n from "src/i18n"
import Logger from "@common/Logger"

const jsprim = require("jsprim")

const { t } = i18n
class EventDetails extends EcosuiteView {
  constructor(props) {
    super(props)

    this.createEvent = this.createEvent.bind(this)
    this.updateEvent = this.updateEvent.bind(this)
    this.resolveEvent = this.resolveEvent.bind(this)
    this.deleteEvent = this.deleteEvent.bind(this)
    this.onFormChange = this.onFormChange.bind(this)
  }

  isReadOnly() {
    return !this.props.groups.includes("event-write")
  }

  updateFormDataWithDefaults(formData) {
    if (formData && this.props.projects.length === 1) {
      const project = this.props.projects[0]
      formData.location.project = project.code

      const sites = Object.keys(project.sites)
      if (sites.length === 1) {
        formData.location.site = sites[0]

        const site = project.sites[sites[0]]
        const systems = Object.values(site.systems)
        if (systems.length === 1) {
          formData.location.system = systems[0].code
        }
      }
    }

    if (formData && formData.location && formData.location.project) {
      const nodes = this.getEventNodes()
      const devices = this.getEventDevices()

      if (nodes.length === 1) {
        formData.location.node = nodes[0].id
      }

      if (devices.length === 1) {
        formData.location.device = devices[0]
      }
    }

    this.setState({ formData: formData })
  }

  componentDidMount() {
    super.componentDidMount()

    let formData = this.props.event
    if (formData && !formData.id) {
      // If it's a new event load
      formData = {
        type: formData.type, // we can pass in the type on new events
        ...formData,
      }
      let params = new URLSearchParams(window.location.search)
      if (params.has("type")) {
        formData.type = params.get("type")
      }

      // When making an event from the Finance and Energy module location is undefined
      if (formData.location) {
        if (params.has("project")) {
          formData.location.project = params.get("project")
        }
        if (params.has("site")) {
          formData.location.site = params.get("site")
        }
        if (params.has("system")) {
          formData.location.system = params.get("system")
        }
        if (params.has("node")) {
          formData.location.type = params.get("node")
        }
        if (params.has("device")) {
          formData.location.device = params.get("device")
        }
        if (formData) {
          this.updateFormDataWithDefaults(formData)
        } else {
          this.setState({ formData: formData })
        }
      }
      if (params.has("startDate")) {
        formData.startDate = params.get("startDate")
      } else {
        formData.startDate = this.props.range
          ? this.props.range.start.toISOString()
          : moment().startOf("day").toISOString()
        // default to the start of the day
      }
    }

    this.setState({ formData: formData })
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    if (this.props.event !== prevProps.event) {
      let formData = this.props.event
      if (formData && !formData.id) {
        // If it's a new event load
        if (!formData.location) {
          formData.location = {}
        }
        if (this.props.project) {
          formData.location.project = this.props.project.code
        }
        if (!formData.startDate) {
          formData.startDate = moment().startOf("day").toISOString() // default to the start of the day
        }
      }

      this.setState({ formData: this.props.event })
    }
  }

  createEvent(form) {
    this.setState({ loading: true })

    EventService.createEvent(form.formData)
      .then((event) => {
        this.setStateIfMounted({ loading: false })
        this.props.actions.eventUpdated(event) // updates the file list selected file
        this.props.actions.selectEvent() // clear the selected event
      })
      .catch((err) => {
        this.setStateIfMounted({ loading: false, error: err })
      })
  }

  updateEvent(form) {
    this.setState({ loading: true })

    EventService.updateEvent(this.props.event.id, form.formData)
      .then((event) => {
        this.setStateIfMounted({ loading: false })
        this.props.actions.eventUpdated(event) // updates the file list selected file
        this.props.actions.selectEvent() // clear the selected event
      })
      .catch((err) => {
        this.setStateIfMounted({ loading: false, error: err })
      })

    this.syncEventToServiceRequests(this.props.event, form.formData)
  }

  syncEventToServiceRequests(oldEvent, newEvent) {
    const eventId = oldEvent.id
    newEvent.serviceRequests.forEach((serviceRequestId) => {
      this.addOrRemoveEventFromServiceRequest(eventId, serviceRequestId)
    })
    const removedIds = this.checkForRemoved(oldEvent, newEvent)
    if (removedIds) {
      removedIds.forEach((serviceRequestId) => {
        this.addOrRemoveEventFromServiceRequest(eventId, serviceRequestId, true)
      })
    }
  }

  addOrRemoveEventFromServiceRequest(eventId, serviceRequestId, remove) {
    const serviceRequest = this.props.serviceRequests.find((sr) => sr.id === serviceRequestId)
    const eventExists = serviceRequest.events.find((evId) => evId === eventId)
    let newServiceRequest
    if (!eventExists) {
      newServiceRequest = { formData: { ...serviceRequest, events: [...serviceRequest.events, eventId] } }
    } else if (remove) {
      newServiceRequest = {
        formData: { ...serviceRequest, events: serviceRequest.events.filter((ev) => ev !== eventId) },
      }
    }

    if (newServiceRequest) {
      ServiceRequestService.updateServiceRequest(newServiceRequest.formData.id, newServiceRequest)
        .then((serviceRequest) => {
          this.props.actions.serviceRequestUpdated(serviceRequest) // updates the file list selected file
        })
        .catch((err) => {
          this.setStateIfMounted({ loading: false, error: err })
        })
    }
  }

  checkForRemoved(newEvent, oldEvent) {
    const removedIds = oldEvent.serviceRequests.filter((osr) => !newEvent.serviceRequests.find((nsr) => nsr === osr))
    return removedIds
  }

  resolveEvent(event) {
    this.setState({ loading: true })
    EventService.resolveEvent(event.id)
      .then(() => {
        this.setStateIfMounted({ loading: false })
        this.props.actions.eventUpdated(event) // updates the file list selected file
      })
      .catch((err) => {
        this.setStateIfMounted({ loading: false, error: err })
      })
  }

  /**
   * Delete a chosen event.
   * @param e - The event.
   */
  deleteEvent(e) {
    e.preventDefault()
    const confirm = window.confirm(t("event.messages.confirm_delete_event"))
    if (confirm) {
      this.setState({ loading: true })
      this.props.actions.deleteEvents.call(this, [this.props.event])
    }
  }

  isSolarNetworkEvent() {
    return this.props.event && this.props.event.type === "solarnetwork"
  }

  getUiSchema(eventProject, eventSite, eventSystem, nodes, devices) {
    let disableEventMove = this.isSolarNetworkEvent() // EP-657 prevent certain SolarNetwork properties from being edited

    const fieldOrder = ["description", "notes", "type"]

    // Maintaining order this way works but because of the time lag between the schema updating and the ui updating
    // Doing it this way produces a horrible pop in effect which is quite glaring given the short length of the form
    if (this.state.formData?.type === "energy") {
      fieldOrder.push("subType")
    }

    fieldOrder.push("*")

    const sn = this.props.event === "Solarnetwork"

    return {
      "ui:order": fieldOrder,
      type: {
        "ui:disabled": this.props.event && sn && this.props.event.id, // prevent existing item type being edited as they're stored in different systems
      },
      subType: {
        "ui:disabled": this.props.event && sn && this.props.event.id,
      },
      description: {
        "ui:widget": "textarea",
        classNames: "right-column",
      },
      notes: {
        classNames: "right-column",
        items: {
          "ui:options": {
            label: false,
            title: false,
          },
          note: {
            "ui:widget": "textarea",
          },
          noteDate: {
            "ui:widget": (props) => {
              let date = props.value ? moment(props.value) : null
              return (
                <div>
                  <Datetime
                    value={date}
                    dateFormat="ll"
                    timeFormat="HH:mm:ss"
                    onChange={(date) => {
                      if (typeof date === "object") {
                        props.onChange(date ? date.toISOString() : "")
                      }
                    }}
                    inputProps={{
                      disabled: this.isReadOnly(),
                    }}
                  />
                  {date && eventProject ? (
                    <div>
                      {t("event.labels.local_time")}:{" "}
                      {moment(date).tz(EventUtils.getTimeZoneForProject(eventProject, eventSite)).format("lll")}
                    </div>
                  ) : null}
                </div>
              )
            },
          },
        },
      },
      startDate: {
        project: eventProject, // this is needed so that when it's updated the widget is notified
        site: eventSite, // this is needed so that when it's updated the widget is notified
        "ui:widget": (props) => {
          return (
            <EventDatePickerWidget
              key={`${props.id}-${props.value}`} // The component won't rerender without this key
              defaultValue={this.props.range.start}
              eventProject={eventProject}
              eventSite={eventSite}
              disableEventMove={disableEventMove}
              isReadOnly={() => this.isReadOnly()}
              {...props}
            />
          )
        },
      },
      dueDate: {
        project: eventProject, // this is needed so that when it's updated the widget is notified
        site: eventSite, // this is needed so that when it's updated the widget is notified
        "ui:widget": (props) => {
          return (
            <EventDatePickerWidget
              key={`${props.id}-${props.value}`}
              eventProject={eventProject}
              eventSite={eventSite}
              isReadOnly={() => this.isReadOnly()}
              {...props}
            />
          )
        },
      },
      endDate: {
        project: eventProject, // this is needed so that when it's updated the widget is notified
        site: eventSite, // this is needed so that when it's updated the widget is notified
        "ui:widget": (props) => {
          return (
            <EventDatePickerWidget
              key={`${props.id}-${props.value}`}
              eventProject={eventProject}
              eventSite={eventSite}
              isReadOnly={() => this.isReadOnly()}
              {...props}
            />
          )
        },
      },
      location: {
        project: {
          "ui:widget": (props) => {
            return (
              <Input
                type="select"
                value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
                required={props.required}
                disabled={disableEventMove || this.isReadOnly()} // EP-657 prevent certain SolarNetwork properties from being edited
                onChange={(event) => {
                  props.onChange(event.target.value)
                }}
              >
                <option value="">{t("labels.select_project")}</option>
                {this.props.projects.map((project) => {
                  return (
                    <option key={project.code} value={project.code}>
                      {project.name}
                    </option>
                  )
                })}
              </Input>
            )
          },
        },
        site: {
          project: eventProject, // this is needed so that when it's updated the widget is notified
          "ui:widget": (props) => {
            const sites = eventProject && Object.values(eventProject.sites)
            return (
              <Input
                type="select"
                value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
                required={props.required}
                disabled={disableEventMove || this.isReadOnly()} // EP-657 prevent certain SolarNetwork properties from being edited
                onChange={(event) => {
                  props.onChange(event.target.value)
                }}
              >
                <option value="">{t("labels.select_site")}</option>
                {sites
                  ? sites.map((site) => {
                      return (
                        <option key={site.code} value={site.code}>
                          {site.name}
                        </option>
                      )
                    })
                  : null}
              </Input>
            )
          },
        },
        system: {
          site: eventSite, // this is needed so that when it's updated the widget is notified
          "ui:widget": (props) => {
            const systems = eventSite && Object.values(eventSite.systems)
            return (
              <Input
                type="select"
                value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
                required={props.required}
                disabled={disableEventMove || this.isReadOnly()} // EP-657 prevent certain SolarNetwork properties from being edited
                onChange={(event) => {
                  props.onChange(event.target.value)
                }}
              >
                <option value="">{t("labels.select_system")}</option>
                {systems
                  ? systems.map((system) => {
                      return (
                        <option key={system.code} value={system.code}>
                          {system.name}
                        </option>
                      )
                    })
                  : null}
              </Input>
            )
          },
        },
        node: {
          nodes: nodes, // this is needed so that when it's updated the widget is notified
          "ui:widget": (props) => {
            return (
              <Input
                type="select"
                value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
                required={props.required}
                disabled={disableEventMove || this.isReadOnly()} // EP-657 prevent certain SolarNetwork properties from being edited
                onChange={(event) => {
                  props.onChange(event.target.value)
                }}
              >
                <option value="">{t("labels.select_node")}</option>
                {nodes
                  ? nodes.map((node) => {
                      return <option key={node}>{node.id}</option>
                    })
                  : null}
              </Input>
            )
          },
        },
        device: {
          devices: devices, // this is needed so that when it's updated the widget is notified
          "ui:widget": (props) => {
            return (
              <>
                <Select
                  classNamePrefix={"react-select"}
                  value={
                    props.value
                      ? props.value.split(",").map((device) => {
                          return { value: device, label: device }
                        })
                      : ""
                  }
                  name={"devices"}
                  placeholder={"Select Device..."}
                  multiple={true}
                  isMulti={true}
                  options={devices
                    .filter((device) => !device.includes("FORECAST"))
                    .map((device) => {
                      return { value: device, label: device }
                    })}
                  defaultValue={[""]}
                  onChange={(event) => {
                    let string = ""
                    event.forEach((device) => {
                      if (string !== "") {
                        string += ","
                      }
                      string += device.value
                    })
                    Logger.info(string)
                    props.onChange(string)
                  }}
                />
              </>
            )
          },
        },
      },
      serviceRequests: {
        items: {
          "ui:widget": (props) => {
            return (
              <>
                <Input
                  type="select"
                  value={props.value ? props.value : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
                  required={props.required}
                  disabled={this.isReadOnly()} // EP-657 prevent certain SolarNetwork properties from being edited
                  onChange={(serviceRequest) => {
                    props.onChange(serviceRequest.target.value)
                  }}
                >
                  <option value=""></option>
                  {this.props.serviceRequests.map((serviceRequest) => {
                    return (
                      <option key={serviceRequest.id} value={serviceRequest.id}>
                        {serviceRequest.taskSummary}
                      </option>
                    )
                  })}
                </Input>
                {props.value?.length > 0 ? (
                  <Link to={"/events?serviceRequestId=" + props.value}>
                    <Button onClick={() => this.props.actions.changeView("serviceRequests")} color="info">
                      {t("labels.view")}
                    </Button>
                  </Link>
                ) : null}
              </>
            )
          },
        },
      },
    }
  }

  getEventProject() {
    if (this.state.formData) {
      return this.props.projects.find((project) => {
        return project.code === this.state.formData.location?.project
      })
    }
  }

  getEventSite() {
    if (this.state.formData) {
      let project = this.getEventProject()
      if (project) {
        return Object.values(project.sites).find((site) => {
          return site.code === this.state.formData.location?.site
        })
      }
    }
  }

  getEventSystem() {
    if (this.state.formData) {
      let site = this.getEventSite()
      if (site) {
        return Object.values(site.systems).find((system) => {
          return system.code === this.state.formData.location?.system
        })
      }
    }
  }

  getEventNodes() {
    if (this.state.formData) {
      let system = this.getEventSystem()
      if (system && this.props.devices) {
        let project = this.props.devices[this.getEventProject().code]
        if (project) {
          let site = project.sites[this.getEventSite().code]
          if (site && site.systems[system.code]) {
            let nodes = Object.values(site.systems[system.code].nodes)
            return nodes ? nodes : []
          }
        }
      }
    }
    return []
  }

  getEventDevices() {
    if (this.state.formData && this.state.formData.location?.node) {
      let nodes = this.getEventNodes()

      if (nodes && nodes.length > 0) {
        let node = nodes.find((node) => {
          return node.id === this.state.formData.location?.node
        })

        if (node) {
          return node.devices ? node.devices : []
        }
      }
    }
    return []
  }

  onFormChange(form) {
    // Update formData when types change to force a rerender and uiSchema update
    if (form.formData?.type !== this.state.formData?.type) {
      this.setState({ formData: form.formData })
    }

    if (this.state.formData && this.state.formData.location) {
      this.setState({ formData: form.formData })
    }
  }

  render() {
    if (!this.props.eventSchema || this.state.loading) {
      return <Loading />
    }

    let schema = jsprim.deepCopy(this.props.eventSchema)

    // Remove solar network from the list of options given to the user -- the API prevents it
    // We only remove this option if we're editing an event
    if (this.props.event.id && this.state.formData) {
      const enumIndex = schema.properties.type.enum.indexOf("solarnetwork")
      const enumNameIndex = schema.properties.type.enumNames.indexOf("Solar Network")
      schema.properties.type.enum.splice(enumIndex, 1)
      schema.properties.type.enumNames.splice(enumNameIndex, 1)
    }

    return (
      <div className="item-details">
        <div className={"item-details-title " + (this.props.event.id ? "item-edit-title" : "item-create-title")}>
          <h2>
            {this.isReadOnly()
              ? `${t("event.labels.view_event")}`
              : this.props.event.id
                ? `${t("event.labels.update_event")}`
                : `${t("event.labels.create_event")}`}
            {this.props.event.id ? <span className="header-id">{this.props.event.id}</span> : null}
            <div
              className="float-end"
              onClick={() => {
                this.props.actions.selectEvent()
              }}
            >
              <Icon icon="close" className="close-button" />
            </div>
            {this.props.event.userName ? (
              <span className="right-title">
                {t("labels.created_by")}: {this.props.event.userName}
              </span>
            ) : null}
          </h2>
        </div>

        <div className="item-details-content event-content">
          <Row>
            <Col>
              <EcosuiteForm
                className="ecogy-form"
                schema={schema}
                formData={this.state.formData}
                uiSchema={this.getUiSchema(
                  this.getEventProject(),
                  this.getEventSite(),
                  this.getEventSystem(),
                  this.getEventNodes(),
                  this.getEventDevices(),
                )}
                onSubmit={this.props.event.id ? this.updateEvent : this.createEvent}
                onChange={this.onFormChange}
                ArrayFieldTemplate={NotesArrayFieldTemplate}
                disabled={this.isReadOnly()}
              >
                <Row className="ecogy-form-buttons">
                  {this.props.event.id ? (
                    <React.Fragment>
                      <Col className="message-section" sm="12">
                        {this.renderMessages()}
                      </Col>
                      <Col className="button-section" sm="12">
                        <Button color="primary" type="submit" disabled={this.isReadOnly()}>
                          {t("buttons.update")}
                        </Button>
                        <Button color="danger" type="submit" onClick={this.deleteEvent} disabled={this.isReadOnly()}>
                          {t("buttons.delete")}
                        </Button>
                      </Col>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <Col className="message-section" sm="12">
                        {this.renderMessages()}
                      </Col>
                      <Col className="button-section" sm="12">
                        <Button color="primary" type="submit" disabled={this.isReadOnly()}>
                          {t("buttons.create")}
                        </Button>
                      </Col>
                    </React.Fragment>
                  )}
                </Row>

                {this.renderMessages()}
              </EcosuiteForm>
            </Col>
          </Row>
        </div>
      </div>
    )
  }

  renderMessages() {
    return (
      <FormError
        error={this.state.error}
        toggle={() => {
          this.setStateIfMounted({ error: null })
        }}
      />
    )
  }
}

export default EventDetails

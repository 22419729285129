import { Auth } from "aws-amplify"
import aws from "aws-sdk"
import i18n from "src/i18n"
import React, { useState } from "react"
import ReactDOM from "react-dom"
import { Modal, Progress } from "reactstrap"

var JSZip = require("jszip")
const { t } = i18n

// Download Progress Component
const DownloadProgress = ({ total, processed, downloaded, failed, compressionPercent, onClose }) => {
  const [show, setShow] = useState(true)

  const handleClose = () => {
    setShow(false)
    if (onClose) onClose()
  }

  const isCompressing = compressionPercent !== undefined
  const isComplete = processed === total && !isCompressing
  const compressingComplete = isCompressing && compressionPercent === 100

  return (
    <Modal isOpen={show} toggle={handleClose} centered size="md">
      <div className="p-4">
        <h5 className="mb-3">{t("download.progress_title")}</h5>

        <div className="mb-3">
          <div className="d-flex justify-content-between mb-1">
            <span>{t("download.downloading")}</span>
            <span>{`${processed}/${total}`}</span>
          </div>
          <Progress value={(processed / total) * 100} />
        </div>

        {isCompressing && (
          <div className="mb-3">
            <div className="d-flex justify-content-between mb-1">
              <span>{t("download.compressing")}</span>
              <span>{`${Math.round(compressionPercent)}%`}</span>
            </div>
            <Progress value={compressionPercent} />
          </div>
        )}

        <div className="mb-3">
          <div className="d-flex justify-content-between">
            <span>{t("download.status")}</span>
          </div>
          <div className="mt-2">
            <div className="d-flex justify-content-between">
              <span className="text-success">{t("download.success")}</span>
              <span>{downloaded}</span>
            </div>
            <div className="d-flex justify-content-between">
              <span className="text-danger">{t("download.failed")}</span>
              <span>{failed}</span>
            </div>
          </div>
        </div>

        {(isComplete || compressingComplete) && (
          <div className="d-flex justify-content-end">
            <button className="btn btn-primary" onClick={handleClose}>
              {t("common.close")}
            </button>
          </div>
        )}
      </div>
    </Modal>
  )
}

var DocumentService = {
  async viewFile(key) {
    return Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      var params = {
        Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
        Key: key,
      }

      return s3.getSignedUrlPromise("getObject", params)
    })
  },

  downloadFile(key) {
    Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      var params = {
        Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
        Key: key,
      }

      s3.getSignedUrl("getObject", params, (err, url) => {
        window.open(url)
      })
    })
  },

  async downloadFiles(fileName, documentKeys, recordMap, projectName, isPortfolio) {
    if (documentKeys.length === 0) {
      alert(t("alertsInfo.no_file_available"))
      return true
    }

    try {
      // Balance between throughput and avoiding S3 rate limiting
      const BATCH_SIZE = 15 // Process 15 files at a time for better throughput
      const zip = new JSZip()
      let hasContent = false

      // Create progress tracking state
      let stats = {
        total: documentKeys.length,
        processed: 0,
        downloaded: 0,
        failed: 0,
      }

      // Create and mount progress modal
      const progressContainer = document.createElement("div")
      document.body.appendChild(progressContainer)

      const updateProgress = () => {
        ReactDOM.render(
          <DownloadProgress
            total={stats.total}
            processed={stats.processed}
            downloaded={stats.downloaded}
            failed={stats.failed}
            onClose={() => {
              ReactDOM.unmountComponentAtNode(progressContainer)
              document.body.removeChild(progressContainer)
            }}
          />,
          progressContainer,
        )
      }

      // Initial render
      updateProgress()

      // Process a single file completely (get URL, fetch content, add to zip)
      const processFile = async (documentKey) => {
        let downloaded = false
        let failed = false

        try {
          // Get signed URL
          const signedUrl = await this.viewFile(documentKey).catch(() => null)

          if (!signedUrl) {
            failed = true
            return { downloaded, failed }
          }

          // Fetch the file content with optimized settings that allow caching
          const response = await fetch(signedUrl, {
            method: "GET",
            // Use keep-alive to reuse connections for better performance
            keepalive: true,
          })

          if (!response.ok) {
            failed = true
            return { downloaded, failed }
          }

          const fileContent = await response.blob()

          if (!fileContent || fileContent.size === 0) {
            failed = true
            return { downloaded, failed }
          }

          // Successfully fetched file
          hasContent = true
          downloaded = true

          // Parse the file path to create the new folder structure
          const pathParts = documentKey.split("/")
          const recordIndex = pathParts.findIndex((part) => part.startsWith("record-"))

          if (recordIndex !== -1 && recordMap && recordMap[documentKey]) {
            // Get record details from the recordMap
            const { recordName, recordId, projectName: recordProjectName } = recordMap[documentKey]

            // Create new folder format: {projectName}-{recordName}-{recordId}
            const newRecordFolder = `${isPortfolio ? recordProjectName : projectName}-${recordName}-${recordId}`

            // Replace the original record ID folder with the new format
            pathParts[recordIndex] = newRecordFolder

            // Create new file path
            const newFilePath = pathParts.join("/")
            zip.file(newFilePath, fileContent, { base64: true })
          } else {
            // If for some reason we can't parse the path correctly, use the original path
            zip.file(documentKey, fileContent, { base64: true })
          }
        } catch (error) {
          failed = true
        }

        return { downloaded, failed }
      }

      // Function to process a batch of documents in parallel
      const processBatch = async (startIdx, endIdx) => {
        const batchFiles = documentKeys.slice(startIdx, endIdx)

        // Create an array of promises, with higher concurrency for faster downloads
        const MAX_CONCURRENT = 10 // Maximum number of concurrent file downloads
        const results = []

        // Process files in smaller concurrent groups to maximize throughput
        for (let i = 0; i < batchFiles.length; i += MAX_CONCURRENT) {
          const concurrentBatch = batchFiles.slice(i, i + MAX_CONCURRENT)

          // Process this smaller batch concurrently
          const batchResults = await Promise.all(concurrentBatch.map((documentKey) => processFile(documentKey)))

          // Update stats for this concurrent batch
          batchResults.forEach((result) => {
            if (result.downloaded) stats.downloaded++
            if (result.failed) stats.failed++
            stats.processed++
            updateProgress()
          })

          results.push(...batchResults)
        }

        return results
      }

      // Process files in batches to avoid rate limiting
      for (let i = 0; i < documentKeys.length; i += BATCH_SIZE) {
        await processBatch(i, Math.min(i + BATCH_SIZE, documentKeys.length))
      }

      ReactDOM.render(
        <DownloadProgress
          total={stats.total}
          processed={stats.total}
          downloaded={stats.downloaded}
          failed={stats.failed}
          onClose={() => {
            ReactDOM.unmountComponentAtNode(progressContainer)
            document.body.removeChild(progressContainer)
          }}
        />,
        progressContainer,
      )

      const content = await zip.generateAsync({
        type: "blob",
        onUpdate: (metadata) => {
          ReactDOM.render(
            <DownloadProgress
              total={stats.total}
              processed={stats.total}
              downloaded={stats.downloaded}
              failed={stats.failed}
              compressionPercent={metadata.percent}
              onClose={() => {
                ReactDOM.unmountComponentAtNode(progressContainer)
                document.body.removeChild(progressContainer)
              }}
            />,
            progressContainer,
          )
        },
      })

      if (hasContent) {
        // Force download of the Zip file
        const blobURL = URL.createObjectURL(new Blob([content], { type: "application/zip" }))
        let a = document.createElement("a")
        a.download = fileName
        a.href = blobURL
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
        URL.revokeObjectURL(blobURL) // Clean up to avoid memory leaks
      } else {
        alert(t("alertsInfo.no_file_available"))
        // Clean up progress modal
        ReactDOM.unmountComponentAtNode(progressContainer)
        document.body.removeChild(progressContainer)
      }

      return true
    } catch (error) {
      alert(t("alertsInfo.download_error"))
      return false
    }
  },

  async deleteFile(key, groups) {
    if (groups.includes("data-write")) {
      return Auth.currentCredentials().then((credentials) => {
        const s3 = new aws.S3({
          apiVersion: "2013-04-01",
          credentials: Auth.essentialCredentials(credentials),
        })

        var params = {
          Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
          Key: key,
        }

        return s3.deleteObject(params).promise()
      })
    }
  },
}

export default DocumentService

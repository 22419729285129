import React from "react"
import { Button, ButtonGroup, ButtonToolbar, Form, Input, InputGroup, InputGroupText, Progress } from "reactstrap"
import jsprim from "jsprim"
import { deepEquals } from "@rjsf/core/lib/utils"

import Settings from "@common/Settings"
import { EcosuiteComponentError, Error, Loading } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"
import Schemas from "@common/Schemas"
import ProjectUtils from "@common/utils/ProjectUtils"

import RecordDocumentFilter from "./documents/RecordDocumentFilter"
import RecordDocuments from "./documents/RecordDocuments"
import DocumentService from "../DocumentService"
import RecordUtils from "./RecordUtils"
import RecordService from "./RecordService"
import Logger from "@common/Logger"
import i18n from "src/i18n"
import moment from "moment"
import DistilleryPopover from "./DistilleryPopover"
import { isEmpty, pick } from "lodash"
import ShareDocuments from "src/PublicView/DocumentsSharing/ShareDocuments"

const documentFolderHierarchy = require("./record-document-folders.json")
const { t } = i18n

const DistilleryWorkStatusActive = 0
const DistilleryWorkStatusInactive = 1
const DistilleryWorkStatusSucceeded = 2
const DistilleryWorkStatusFailed = 3
const DistilleryWorkStatusCancelled = 4

export default class RecordDocumentList extends EcosuiteView {
  constructor(props) {
    super(props, "record-document-list")

    if (Settings.getSetting("document-filters")) {
      this.state.selectedTypes = JSON.parse(Settings.getSetting("document-filters"))
    }

    this.loadDocuments = this.loadDocuments.bind(this)
    this.loadRecordDocuments = this.loadRecordDocuments.bind(this)
    this.loadDistilleryDocuments = this.loadDistilleryDocuments.bind(this)
    this.loadDistilleryJobs = this.loadDistilleryJobs.bind(this)
    this.downloadFiles = this.downloadFiles.bind(this)
    this.createRecord = this.createRecord.bind(this)

    this.toggleRecordType = this.toggleRecordType.bind(this)
    this.toggleRecordSubType = this.toggleRecordSubType.bind(this)
    this.toggleFolder = this.toggleFolder.bind(this)

    this.renderDistilleryProgress = this.renderDistilleryProgress.bind(this)

    this.state.distilleryDocuments = null

    this.distilleryInterval = setInterval(() => {
      this.loadDistilleryJobs()
    }, 1000)

    this.state.query = ""

    // { ...undefined } => { }
    // this.state.viewableRecords = { ...this.props.moduleViews?.recordTypes }
    // This is temporary until we update the current userTypes using the new userType + recordType definition
    this.state.viewableRecords = { financial: [], compliance: [], energy: [], ...this.props.moduleViews?.recordTypes }
  }

  componentWillUnmount() {
    clearInterval(this.distilleryInterval)
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadSchema()
    this.loadDocuments()
  }

  componentDidUpdate(prevProps) {
    if (this.props.loadTime !== prevProps.loadTime) {
      this.loadDocuments()
    }
    if (
      this.props.project &&
      (!deepEquals(prevProps.project, this.props.project) || (prevProps.projects && !this.props.projects))
    ) {
      this.loadDocuments()
    } else if (
      (this.props.projects && !deepEquals(prevProps.projects, this.props.projects)) ||
      (prevProps.project && !this.props.project)
    ) {
      this.loadDocuments()
    }
  }

  loadSchema() {
    Schemas.getRecordSchema().then((schema) => {
      let typeHierarchy = []
      schema.properties.recordType.enum.forEach((recordType, idx) => {
        let recordTypeDependency = schema.dependencies.recordType.oneOf.find(
          (type) => type.properties.recordType.enum[0] === recordType,
        )

        if (this.state.viewableRecords[recordType]?.length) {
          typeHierarchy.push({
            id: recordType,
            name: schema.properties.recordType.enumNames[idx],
            subTypes: recordTypeDependency.properties.recordSubType.enum
              .map((subTypeId, subIdx) => {
                return {
                  id: subTypeId,
                  name: recordTypeDependency.properties.recordSubType.enumNames[subIdx],
                  folders: documentFolderHierarchy[recordType][subTypeId],
                }
              })
              .filter((subType) => this.state.viewableRecords[recordType]?.includes(subType.id)),
          })
        }
      })

      this.setStateIfMounted({
        recordSchema: schema,
        typeHierarchy: typeHierarchy,
        selectedTypes: RecordUtils.convertTypeHierarchyToTypes(typeHierarchy),
      })
    })
  }

  async loadDocuments() {
    this.loadRecordDocuments()
    this.loadDistilleryDocuments()
  }

  async loadDistilleryJobs() {
    // Get the active jobs
    const jobs = (await RecordService.listDistilleryJobs()).jobs

    // Filter the jobs based on whether we actually have a matching document
    let mostRecentJobs = new Map()

    for (const document of this.state.distilleryDocuments) {
      const name = document.name
      const fileKey = document.fileKey

      const relevant = jobs.filter((job) => {
        const tags = job["tags"]
        if (!tags) {
          return false
        }

        const fileName = tags[0]
        return name == fileName
      })

      if (relevant.length == 0) {
        continue
      }

      // Find the greatest createdAt date
      relevant.sort((a, b) => {
        const da = moment(a["createdAt"])
        const db = moment(b["createdAt"])

        if (da.isBefore(db)) return -1
        if (da.isAfter(db)) return 1

        return 0
      })

      mostRecentJobs.set(fileKey, relevant[relevant.length - 1])
    }

    this.setStateIfMounted({
      distilleryJobs: Array.from(mostRecentJobs.values()),
      distilleryMap: mostRecentJobs,
    })
  }

  async loadDistilleryDocuments() {
    const projects = this.props.projects ? this.props.projects : [this.props.project]

    let results = await Promise.all(
      projects.map((project) => {
        return RecordService.getDistilleryDocuments(project.code).then((response) => {
          return response.data.documents
        })
      }),
    )

    let documents = []
    for (const result of results) {
      documents.push(...result)
    }

    this.setStateIfMounted({
      distilleryDocuments: documents,
    })

    this.loadDistilleryJobs()

    return documents
  }

  async loadRecordDocuments() {
    this.setStateIfMounted({
      recordDocuments: null,
    })

    try {
      let records = []
      if (this.props.project) {
        const response = await RecordService.getProjectDocuments(this.props.project.code)
        const data = response.data
        records = records.concat(data)
      } else {
        // If a portfolio is selected, get all by portfolio.
        if (this.props.portfolio) {
          const response = await RecordService.getPortfolioDocuments(this.props.portfolio.id)
          const data = response.data
          records = records.concat(data)
        }
        // If multiple projects are selected, get all projects.
        else if (this.props.projects) {
          const responses = await Promise.all(
            this.props.projects.map(async (project) => await RecordService.getProjectDocuments(project.code)),
          )
          for (const response of responses) {
            const data = response.data
            records = records.concat(data)
          }
        }
      }

      // We expect typeHierarchy to have already been populated by loadSchema
      const typeHierarchy = this.state.typeHierarchy
      let recordDocuments = records
      if (typeHierarchy) {
        recordDocuments.forEach((recordDocument) => {
          const type = typeHierarchy.find((type) => type.id === recordDocument.recordType)
          if (type) {
            const subType = type.subTypes.find((subType) => subType.id === recordDocument.recordSubType)
            if (subType && recordDocument.documents) {
              recordDocument.documents.forEach((document) => {
                if (document.folderName) {
                  if (!subType.folders) {
                    subType.folders = [document.folderName]
                  }
                  if (!subType.folders.includes(document.folderName)) {
                    subType.folders.push(document.folderName)
                  }
                }
              })
            }
          }
        })
      } else {
        Logger.warning("No type hierarchy loaded")
      }

      // Sort record documents by `record.recordPath`.
      recordDocuments.sort((a, b) => {
        return a.recordPath.localeCompare(b.recordPath)
      })

      this.setStateIfMounted({
        recordDocuments: recordDocuments,
        typeHierarchy: typeHierarchy,
        selectedTypes: RecordUtils.convertTypeHierarchyToTypes(typeHierarchy),
      })
    } catch (error) {
      Logger.error(error)
      this.setStateIfMounted({
        recordDocuments: new EcosuiteComponentError(error),
      })
    }
  }

  toggleRecordType(e, type) {
    e.preventDefault()
    e.stopPropagation()
    let selectedTypes = this.state.selectedTypes

    let selected = Object.keys(selectedTypes[type]).reduce((result, subType) => {
      return (
        result &&
        Object.keys(selectedTypes[type][subType]).find((folder) => !selectedTypes[type][subType][folder]) === undefined
      )
    }, true)

    Object.keys(selectedTypes[type]).forEach((subType) => {
      if (typeof selectedTypes[type][subType] === "boolean") {
        selectedTypes[type][subType] = !selected
      } else if (typeof selectedTypes[type][subType] === "object") {
        Object.keys(selectedTypes[type][subType]).forEach((folder) => {
          selectedTypes[type][subType][folder] = !selected
        })
      }
    })

    Settings.setSetting("document-filters", JSON.stringify(selectedTypes))
    this.setStateIfMounted({ selectedTypes: selectedTypes })
  }

  toggleRecordSubType(e, type, subType) {
    e.preventDefault()
    e.stopPropagation()
    let selectedTypes = this.state.selectedTypes
    if (typeof selectedTypes[type][subType] === "boolean") {
      selectedTypes[type][subType] = !selectedTypes[type][subType]
    } else if (typeof selectedTypes[type][subType] === "object") {
      const selected =
        Object.keys(selectedTypes[type][subType]).find((folder) => !selectedTypes[type][subType][folder]) === undefined
      Object.keys(selectedTypes[type][subType]).forEach((folder) => {
        selectedTypes[type][subType][folder] = !selected
      })
    }

    Settings.setSetting("document-filters", JSON.stringify(selectedTypes))
    this.setStateIfMounted({ selectedTypes: selectedTypes })
  }

  toggleFolder(e, type, subType, folder) {
    e.preventDefault()
    e.stopPropagation()
    let selectedTypes = this.state.selectedTypes
    selectedTypes[type][subType][folder] = !selectedTypes[type][subType][folder]

    Settings.setSetting("document-filters", JSON.stringify(selectedTypes))
    this.setStateIfMounted({ selectedTypes: selectedTypes })
  }

  documentDeleted(deletedDocument) {
    let recordDocuments = this.state.recordDocuments.filter((document) => {
      return document.key !== deletedDocument.key
    })

    this.setState({ recordDocuments: recordDocuments }) // remove the deleted document from the list
  }

  getDocuments() {
    const documents = []
    if (this.isContentValid(this.state.recordDocuments)) {
      this.getRecordDocuments().forEach((recordDocument) => {
        recordDocument.documents.forEach((document) => {
          documents.push(document)
        })
      })
    }
    return documents
  }

  async updateSearchRanking(query) {
    const start = new Date()

    if (query) {
      const hits = (await RecordService.queryRecords(query))["hits"]

      const sortedHits = hits.sort((lhs, rhs) => {
        const x = lhs["score"]
        const y = rhs["score"]
        return x > y ? -1 : x < y ? 1 : 0
      })

      if (this.state.rankedTime && start < this.state.rankedTime) {
        return
      }

      const documents = this.getDocuments()
      let ranked = []

      for (const hit of sortedHits) {
        const doc = documents.find((d) => d["fileKey"] === hit["path"])
        if (doc) {
          ranked.push(doc)
        }
      }

      this.setStateIfMounted({
        ranked: ranked,
        rankedTime: start,
      })
    } else {
      this.setStateIfMounted({
        ranked: this.getDocuments(),
      })
    }
  }

  getRecordMap() {
    const recordMap = {}
    if (this.isContentValid(this.state.recordDocuments)) {
      this.getRecordDocuments().forEach((recordDocument) => {
        recordDocument.documents.forEach((document) => {
          recordMap[document.fileKey] = {
            recordName: recordDocument.recordName,
            recordId: recordDocument.recordId,
            projectName: recordDocument.recordPath.split("/")[1],
          }
        })
      })
    }
    return recordMap
  }

  downloadFiles(fileName) {
    this.setStateIfMounted({ loading: `${t("loadingMsg.generating_download")}: ${fileName}` })
    DocumentService.downloadFiles(
      fileName,
      this.getDocuments().map((document) => document.fileKey),
      this.getRecordMap(),
      undefined,
      true,
    ).then(() => {
      this.setStateIfMounted({ loading: null })
    })
  }

  createRecord() {}

  renderDistilleryProgress() {
    if (this.state.distilleryJobs.length == 0) {
      return null
    }

    const progress = this.state.distilleryJobs.filter((job) => job.status == DistilleryWorkStatusActive).length
    const queued = this.state.distilleryJobs.filter((job) => job.status == DistilleryWorkStatusInactive).length
    const success = this.state.distilleryJobs.filter((job) => job.status == DistilleryWorkStatusSucceeded).length
    const failed = this.state.distilleryJobs.filter((job) => job.status == DistilleryWorkStatusFailed).length
    const cancelled = this.state.distilleryJobs.filter((job) => job.status == DistilleryWorkStatusCancelled).length

    const progressStyle = { height: "30px", fontSize: "16px" }

    if (success == this.state.distilleryJobs.length) {
      return null
    }

    return (
      <Progress style={progressStyle} multi max={this.state.distilleryJobs.length}>
        <Progress bar style={progressStyle} max={this.state.distilleryJobs.length} value={progress} color="info">
          Distilling
        </Progress>
        <Progress bar style={progressStyle} max={this.state.distilleryJobs.length} value={queued}>
          Queued
        </Progress>
        <Progress bar style={progressStyle} max={this.state.distilleryJobs.length} value={success} color="success">
          Success
        </Progress>
        <Progress bar style={progressStyle} max={this.state.distilleryJobs.length} value={cancelled} color="warning">
          Cancelled
        </Progress>
        <Progress bar style={progressStyle} max={this.state.distilleryJobs.length} value={failed} color="danger">
          Error
        </Progress>
      </Progress>
    )
  }

  getMetaData(props) {
    const { portfolio, projects, project } = props
    const selectedPortfolio = portfolio
      ? {
          id: portfolio.id,
          name: portfolio.name,
          projects: projects.filter((p) => portfolio.projects.includes(p.code)).map((p) => pick(p, ["code", "name"])),
        }
      : {}
    const selectedProject = pick(project, ["code", "name"])
    if (isEmpty(selectedPortfolio) && isEmpty(selectedProject)) {
      selectedPortfolio.projects = projects.map((p) => pick(p, ["code", "name"]))
    }
    return {
      selectedPortfolio,
      selectedProject,
      isProjectSelected: !!selectedPortfolio,
      records: this.getRecordDocuments().map((doc) => {
        const projectId = ProjectUtils.getProjectCode(doc.recordPath)
        const siteId = ProjectUtils.getSiteCode(doc.recordPath)
        const project = this.props.projects.find((project) => project.code === projectId)
        const site = project && siteId ? project.sites[siteId] : null
        return {
          ...doc,
          site: site?.name,
          project: project.name,
        }
      }),
    }
  }

  renderViewControls() {
    return (
      <div className="content-view-controls search-bar">
        <ButtonToolbar className="float-end">
          <ButtonGroup className="header-button-group">
            <DistilleryPopover
              createRecord={this.createRecord}
              loadDistilleryDocuments={this.loadDistilleryDocuments}
              projects={this.props.projects ? this.props.projects : this.props.project}
              portfolios={[]}
            />
            {this.getDocuments().length ? (
              <Button
                title={t("labels.download_all_docs")}
                onClick={() => {
                  this.downloadFiles(
                    `${this.props.project ? this.props.project.code : "ecosuite"}-record-documents.zip`,
                  )
                }}
                size="sm"
              >
                {t("labels.download_all_docs")}
              </Button>
            ) : null}
          </ButtonGroup>
          <ShareDocuments documentsData={this.getRecordDocuments()} metaData={this.getMetaData(this.props)} />
        </ButtonToolbar>

        <ButtonToolbar className="float-start">{this.renderFilters()}</ButtonToolbar>

        {this.getDocuments().length ? (
          <Form
            inline
            className="float-end"
            onSubmit={(e) => {
              e.preventDefault()
            }}
          >
            <InputGroup size="sm">
              <InputGroupText>{t("labels.search")}:</InputGroupText>
              <Input
                type="text"
                name="search"
                placeholder="e.g. 'specification'"
                value={this.state.query}
                onChange={(e) => {
                  this.setStateIfMounted({
                    query: e.target.value,
                  })
                  this.updateSearchRanking(e.target.value)
                }}
              />
              <InputGroupText
                onClick={() => {
                  this.setStateIfMounted({
                    query: undefined,
                    ranked: undefined,
                  })
                }}
              >
                x
              </InputGroupText>
            </InputGroup>
          </Form>
        ) : null}
      </div>
    )
  }

  renderFilters() {
    return (
      <RecordDocumentFilter
        typeHierarchy={this.state.typeHierarchy}
        availableTypes={RecordUtils.convertTypeHierarchyToTypes(this.state.typeHierarchy)}
        selectedTypes={this.state.selectedTypes}
        toggleType={this.toggleRecordType}
        toggleSubType={this.toggleRecordSubType}
        toggleFolder={this.toggleFolder}
      />
    )
  }

  renderMainView() {
    if (this.state.loading) {
      return <Loading message={this.state.loading} />
    } else if (this.isContentError(this.state.recordDocuments)) {
      return <Error error={this.state.recordDocuments.getError()} />
    } else if (this.isContentValid(this.state.recordDocuments)) {
      return (
        <>
          {this.state.distilleryJobs != null ? this.renderDistilleryProgress() : null}

          <RecordDocuments
            restrictions={this.props.restrictions}
            recordDocuments={this.getRecordDocuments()}
            distilleryDocuments={this.getDistilleryDocuments()}
            distilleryMap={this.state.distilleryMap}
            project={this.props.project}
            projects={this.props.projects}
            groups={this.props.groups}
            loadRecordDocuments={this.loadDocuments}
            rankedDocuments={this.state.ranked}
          />
        </>
      )
    } else {
      return <Loading />
    }
  }

  getDistilleryDocuments() {
    return this.state.distilleryDocuments
  }

  getRecordDocuments() {
    if (this.state.recordDocuments && this.props.project) {
      return this.getFilteredRecordDocuments().filter((recordDocument) => {
        let projectId = ProjectUtils.getProjectCode(recordDocument.recordPath)
        return this.props.project.code === projectId
      })
    } else if (this.state.recordDocuments && this.props.projects) {
      return this.getFilteredRecordDocuments().filter((recordDocument) => {
        let projectId = ProjectUtils.getProjectCode(recordDocument.recordPath)
        return this.props.projects.find((project) => project.code === projectId)
      })
    } else {
      return []
    }
  }

  getFilteredRecordDocuments() {
    if (this.state.selectedTypes) {
      const recordDocuments = jsprim.deepCopy(this.state.recordDocuments)
      return recordDocuments.filter((recordDocument) => {
        if (
          recordDocument.documents &&
          recordDocument.documents.length &&
          this.state.selectedTypes[recordDocument.recordType] &&
          this.state.selectedTypes[recordDocument.recordType][recordDocument.recordSubType]
        ) {
          if (typeof this.state.selectedTypes[recordDocument.recordType][recordDocument.recordSubType] === "boolean") {
            // The entire sub type is enabled
            return this.state.selectedTypes[recordDocument.recordType][recordDocument.recordSubType]
          } else if (
            typeof this.state.selectedTypes[recordDocument.recordType][recordDocument.recordSubType] === "object"
          ) {
            // If all folders are enabled the entire category is
            const enabled =
              Object.values(this.state.selectedTypes[recordDocument.recordType][recordDocument.recordSubType]).find(
                (foldersStatus) => !foldersStatus,
              ) === undefined
            // Filter using the enabled folders
            recordDocument.documents = recordDocument.documents.filter((document) => {
              // Old documents may not have a folder assigned, in these cases we need the entire category to be ebabled
              return (
                (!document.folderName && enabled) ||
                this.state.selectedTypes[recordDocument.recordType][recordDocument.recordSubType][document.folderName]
              )
            })
            return true
          } else {
            return true
          }
        } else {
          return false
        }
      })
    }
    return this.state.recordDocuments
  }
}

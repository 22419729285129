import React from "react"
import PropTypes from "prop-types"
import moment from "moment-timezone"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import EnergyUtils from "../EnergyUtils"

import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from "recharts"
import DateRangeGraph from "./DateRangeGraph"
import GraphUtils from "@common/utils/GraphUtils"

export default class TotalPowerLineGraph extends DateRangeGraph {
  renderContent() {
    if (this.props.graphDatums) {
      let data = Object.keys(this.props.graphDatums).map((dateKey) => {
        let entry = this.props.graphDatums[dateKey]
        let date = moment(dateKey)

        return {
          date: date.toDate(),
          time: date.toDate().getTime(),
          name: date.format("lll"),
          generationReading: this.round(entry.generationReading),
          consumptionReading: this.round(entry.consumptionReading),
        }
      })

      let aggregation = this.props.aggregation
      let graphType = aggregation === "day" ? "linear" : "monotone"
      return (
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }} onClick={this.zoomDateRange}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} horizontal={false} />
            <XAxis
              dataKey="time"
              type="number"
              scale="time"
              domain={this.getDomain(this.props.range, this.props.aggregation)}
              tickFormatter={(time) => {
                return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
              }}
            />
            <YAxis />
            <Tooltip
              content={
                <ReadingTooltip
                  assets={this.props.assets}
                  range={this.props.range}
                  aggregation={this.props.aggregation}
                />
              }
            />
            <Legend iconType="plainline" />
            {this.props.showGeneration ? (
              <Line
                type={graphType}
                dataKey="generationReading"
                stroke={GRAPH_COLORS[0]}
                fill={GRAPH_COLORS[0]}
                dot={aggregation === "day"}
              />
            ) : null}
            {this.props.showConsumption ? (
              <Line
                type={graphType}
                dataKey="consumptionReading"
                stroke={GRAPH_COLORS[1]}
                fill={GRAPH_COLORS[1]}
                dot={aggregation === "day"}
              />
            ) : null}
            {this.renderCurrentTime()}
          </LineChart>
        </ResponsiveContainer>
      )
    }

    return <Loading />
  }
}

class ReadingTooltip extends EcosuiteComponent {
  static propTypes = {
    type: PropTypes.string,
    payload: PropTypes.array,
    label: PropTypes.number,
  }

  renderContent() {
    const { active } = this.props
    if (active) {
      const { payload, label } = this.props
      if (payload) {
        return (
          <div className="area-graph-tooltip">
            <p className="label">{GraphUtils.formatDateTooltip(label, this.props.range, this.props.aggregation)}</p>
            {payload.map((data) => {
              return (
                <React.Fragment key={data.dataKey}>
                  <p className="label" style={{ color: data.fill }}>
                    {`${data.name} : ${EnergyUtils.formatKiloWatts(data.value ? data.value : 0)}`}
                  </p>
                </React.Fragment>
              )
            })}
          </div>
        )
      }
    }
    return null
  }
}

import React from "react"
import { Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Input, Row } from "reactstrap"
import EcosuiteForm, { EcosuiteArrayFieldTemplate } from "@common/form/EcosuiteForm"
import Icon from "@common/display/Icon"
import { sharedTariffUISchema } from "@dashboard/data/tariffs/tariffUtils"
import Logger from "@common/Logger"
import { JSONSchema7 } from "json-schema"
import { IChangeEvent, ISubmitEvent } from "@rjsf/core"

interface TariffViewerProps {
  selectedTariff: Tariff
  schema: Schema
  value?: Schema
  onChange?: (e: IChangeEvent<Schema>) => void
  onSubmit: (e: ISubmitEvent<Schema>) => void
}

interface MonthProps {
  value?: Schema;
}

interface TariffSchemaProps extends MonthProps {
  schema: Schema;
}

interface Schema extends JSONSchema7 {
  tariffs: {
    items: {
      energyWeekEndSchedule: Record<string, any>;
      energyWeekDaySchedule: Record<string, any>;
      "ui:order"?: string[];
      rates: {
        classNames: string;
        title: string;
      };
      flatDemand: {
        classNames: string;
        flatDemandMonths?: Record<string, any>;
      };
    };
    [index: number]: {
      rates: any[];
      flatDemand: {
        flatDemandRates: any[];
      };
    };
  };
  definitions: {
    monthlyTariffs: {
      properties: Record<string, any>;
    };
  };
  id: {
    "ui:widget": string;
  };
  assetType: {
    "ui:widget": string;
  };
}

interface WidgetProps {
  id: string;
  value: any;
  onChange: (value: string) => void;
}

/**
 * The tariff viewer.
 * @param props - The props.
 * @constructor
 */

const months = [
  "january",
  "february",
  "march",
  "april",
  "may",
  "june",
  "july",
  "august",
  "september",
  "october",
  "november",
  "december"
]


const getTariffUISchema = (props: TariffSchemaProps) => {

  const monthlyRateFields = (monthProps: MonthProps) => {
    const fields: Record<string, any> = {}
    for (let i = 0; i < months.length; i++) {
      fields[months[i]] = {
        "ui:widget": (props: WidgetProps) => {
          Logger.info(props)
          const parts = props.id.split("_")
          const tariffIndex = parseInt(parts[2])
          const rates = monthProps.value?.tariffs[tariffIndex]?.flatDemand.flatDemandRates
          if (rates && rates.length > 0) {
            const baseRate = rates[props.value]
            const title = baseRate ? baseRate[0].rate : ""
            return (
              <><Input
                placeholder="Rate Index"
                type={"text"}
                value={props.value}
                onChange={(event) => {
                  props.onChange(event.target.value)
                }} />
                {title !== "" ? <span>Base rate:  {title}</span> : <span>&nbsp;</span>}

              </>)
          } else {
            return (
              <Input
                type={"text"}
                value={props.value}
                onChange={(event) => {
                  props.onChange(event.target.value)
                }} />

            )
          }
        },
      }

    }
    return fields
  }

  const hourlyRateFields = (monthProps: MonthProps) => {
    const fields: Record<number, any> = {}
    for (let i = 0; i < 24; i++) {
      fields[i] = {
        "ui:widget": (props: WidgetProps) => {
          Logger.info(props)
          const parts = props.id.split("_")
          const tariffIndex = parseInt(parts[2])
          const rates = monthProps.value?.tariffs[tariffIndex]?.rates
          if (rates && rates.length > 0) {
            const baseRate = rates[props.value]
            const title = baseRate ? baseRate[0].rate : ""
            return (
              <><Input
                placeholder="Rate Index"
                type={"text"}
                value={props.value}
                onChange={(event) => {
                  props.onChange(event.target.value)
                }} />
                {title !== "" ? <span>Base rate:  {title}</span> : <span>&nbsp;</span>}

              </>)
          } else {
            return (
              <Input
                type={"text"}
                value={props.value}
                onChange={(event) => {
                  props.onChange(event.target.value)
                }} />

            )
          }
        },
      }

    }
    return fields
  }

  const schema: Schema = {
    type: "object",
    ...sharedTariffUISchema,
    definitions: {
      monthlyTariffs: {
        properties: {}
      }
    },
    id: {
      // The ID is always shown at the top.
      "ui:widget": "hidden",
    },
    assetType: {
      // The asset type isn't very useful here.
      "ui:widget": "hidden",
    },
    tariffs: {

      items: {
        "ui:order": [
          "start",
          "end",
          "energyWeekDaySchedule",
          "energyWeekEndSchedule",
          "fixedChargeFirstMeter",
          "fixedChargeUnits",
          "flatDemand",
          "rates",
          "*",
        ],

        energyWeekEndSchedule: {
          classNames: "right-column",
        },
        energyWeekDaySchedule: {
          classNames: "left-column",
        },

        rates: {
          classNames: "left-column",
          title: "enter rates here"
        },
        flatDemand: {
          classNames: "right-column",
        }
      },
    },
  }

  /* Object.entries(props.schema.definitions.monthlyTariffs.properties).forEach(([key, value]) => { */
  months.forEach((month) => {
    schema.tariffs.items.energyWeekEndSchedule[month] = hourlyRateFields(props)
    schema.tariffs.items.energyWeekDaySchedule[month] = hourlyRateFields(props)
  })
  schema.tariffs.items.flatDemand.flatDemandMonths = monthlyRateFields(props)
  return schema
}

export const TariffViewer = (props: TariffViewerProps) => {
  const { selectedTariff, schema, value, onChange, onSubmit } = props

  /**
   * Get the header text.
   */
  function getHeader(): string {
    if (selectedTariff.name) {
      return selectedTariff.name
    } else if (selectedTariff.rate) {
      return selectedTariff.rate
    } else {
      return selectedTariff.id
    }
  }

  return (
    <Card>
      <CardHeader tag={"h5"}>
        <Row>
          <Col>
            <CardTitle>{getHeader()}</CardTitle>
            {(selectedTariff.name || selectedTariff.rate) && (
              <CardSubtitle className={"mb-2 text-muted"} tag={"h6"}>
                {selectedTariff.id}
              </CardSubtitle>
            )}
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Col className={"ecogy-form"}>
          <div style={{ marginBottom: "10px", backgroundColor: "#f0f0f0", padding: "10px", borderRadius: "5px" }}>
            <a title="Tariff Guide" href="https://app.gitbook.com/o/N4Dek3zucOKmVjKHAcuf/s/YtoTiPa31rO0d5wf1iTW/~/changes/102/admin/settings/tariffs" target="_blank" rel="noreferrer noopener">
              <h4>For first time users, it is recommended to check out the Tariff Guide. <Icon icon="help"/></h4>
            </a>
          </div>
          <EcosuiteForm schema={schema} uiSchema={getTariffUISchema(props)} formData={value} ArrayFieldTemplate={EcosuiteArrayFieldTemplate} onChange={onChange} onSubmit={onSubmit} />
        </Col>
      </CardBody>
    </Card>
  )
}

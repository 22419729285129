import { Auth } from "aws-amplify"
import aws from "aws-sdk"

import API, { IRange as Range } from "@common/API"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import ProjectUtils from "@common/utils/ProjectUtils"
import { getUserOrganizationId } from "@common/OrganizationUtils"
import moment from "moment"

const EnergyService = {
  getLastMonthReadingsUrl() {
    return API.addRangeToUrl("/energy/readings", DateRangeUtils.getRange("lastMonth"))
  },

  warmCache() {
    API.cacheGet(this.getLastMonthReadingsUrl() as string)
    // @ts-ignore
    API.cacheGet("/solarnetwork/devices", API.getDefaultCacheTimeout())
  },

  getDeviceMetaData(projectId: string) {
    return API.get<{ sources: unknown }>(`/solarnetwork/metadata/projects/${projectId}`).then((response) => response.sources)
  },

  getProjectNodesDeviceInfo(projectId: string, projectStart: string) {
    //Get list of active devices and device start dates where appropriate (STO/PYR)

    const range = {start: moment(projectStart), end: moment()}
    let url = API.addRangeToUrl(`/energy/datums/nodes/projects/` + projectId, range)
    // @ts-ignore
    url = API.addParamToUrl(url, "aggregation", "day")
    url = API.addParamToUrl(url, "limit", "1")
    
    return API.get(url)
  },

  getNodesDatums(range: Range, aggregation: string, projectId: string, nodeIds: string, sourceIds: string, sourceId: string) {
    let url = API.addRangeToUrl("/energy/datums/nodes/projects/" + projectId, range)
    if (nodeIds) {
      url += "&nodeIds=" + encodeURI(nodeIds)
    }
    if (sourceIds) {
      url += "&sourceIds=" + encodeURI(sourceIds)
    } else if (sourceId) {
      url += "&sourceId=" + encodeURI(sourceId)
    }
    if (aggregation) {
      url += "&aggregation=" + aggregation
    }
    return API.get(url as string)
  },

  getMostRecentEnergyDatums() {
    const url = "/energy/datums/most-recent"
    return API.get(url)
  },

  getProjectCumulativeDatums(projectId: string, cumulation: string, cumulationDate: string) {
    let url = `/energy/datums/cumulative/projects/${projectId}?cumulation=${cumulation}`
    if (cumulationDate) {
      url += "&cumulationDate=" + cumulationDate
    }
    return API.get(url)
  },

  getEnergyDatums(range: Range, projectIds: Array<string>, aggregation: string) {
    let url = API.addRangeToUrl("/energy/datums", range)
    if (aggregation) {
      url += "&aggregation=" + aggregation
    }
    if (projectIds) {
      url += "&projectIds=" + projectIds.join(",")
    }
    return API.get(url as string)
  },

  getProjectEnergyDatums(range: Range, aggregation: string, project: any) {
    let url = API.addRangeToUrl(`/energy/datums/projects/${project.code}`, range)
    if (aggregation) {
      url += "&aggregation=" + aggregation
    }
    return API.get(url as string)
  },

  getProjectConsumptionDatums(range: Range, aggregation: string, projectId: string) {
    let url = API.addRangeToUrl(`/energy/datums/consumption/projects/${projectId}`, range)
    if (aggregation) {
      url += "&aggregation=" + aggregation
    }
    return API.get(url as string)
  },

  getPredictedConsumption(range: Range, aggregation: string) {
    let url = API.addRangeToUrl("/energy/datums/consumption/predicted", range)
    if (aggregation) {
      url += "&aggregation=" + aggregation
    }
    return API.get(url as string)
  },

  getProjectPredictedConsumption(range: Range, aggregation: string, projectId: string, useForecast: boolean) {
    let url = API.addRangeToUrl(`/energy/datums/consumption/predicted/projects/${projectId}`, range)
    if (aggregation) {
      url += "&aggregation=" + aggregation
    }
    if (useForecast) {
      url += "&useForecast=" + useForecast
    }
    return API.get(url as string)
  },

  getConsumptionCost(range: Range, aggregation: string) {
    let url = API.addRangeToUrl("/energy/datums/consumption/cost", range)
    if (aggregation) {
      url += "&aggregation=" + aggregation
    }
    return API.get(url as string)
  },

  getProjectConsumptionCost(range: Range, aggregation: string, projectId: string) {
    let url = API.addRangeToUrl(`/energy/datums/consumption/cost/projects/${projectId}`, range)
    if (aggregation) {
      url += "&aggregation=" + aggregation
    }
    return API.get(url as string)
  },

  getExpectedGeneration(range: Range, aggregation: string, projectIds: string[]) {
    let url = API.addRangeToUrl("/energy/datums/generation/expected", range)
    if (aggregation) {
      url += "&aggregation=" + aggregation
    }
    if (projectIds) {
      url += "&projectIds=" + projectIds.join(",")
    }
    return API.get(url as string)
  },

  /**
   * @param {*} range
   * @param {string[] | undefined} projectIds - the projectIds to filter
   * @param {string | undefined} aggregation
   * @returns
   */
  getPredictedGeneration(range: Range, projectIds: Array<number>, aggregation: string) {
    let url = API.addRangeToUrl("/energy/datums/generation/predicted", range)
    if (aggregation) {
      url += "&aggregation=" + aggregation
    }

    if (projectIds) {
      url += "&projectIds=" + projectIds.join(",")
    }

    return API.get(url as string)
  },

  getProjectPredictedGeneration(range: Range, aggregation: string, projectId: string) {
    let url = API.addRangeToUrl(`/energy/datums/generation/predicted/projects/${projectId}`, range)
    if (aggregation) {
      url += "&aggregation=" + aggregation
    }
    return API.get(url as string)
  },

  getEnergyIrradiance(range: Range) {
    const url = API.addRangeToUrl("/energy/irradiance", range)
    return API.get(url as string)
  },

  getEnergyReadings(range: Range, projectIds: Array<string>) {
    let url = API.addRangeToUrl("/energy/readings", range)
    if (projectIds) {
      url += "&projectIds=" + projectIds.join(",")
    }
    return API.get(url as string)
  },

  getInstantaneous(projectIds: Array<string>) {
    return API.get("/energy/instantaneous" + (projectIds ? "?projectIds=" + projectIds.join(",") : ""))
  },

  getStatus(projectIds: Array<string>) {
    return API.get("/energy/status" + (projectIds ? "?projectIds=" + projectIds.join(",") : ""))
  },

  getDevices() {
    // @ts-ignore
    return API.getFromCache("/solarnetwork/devices", API.getDefaultCacheTimeout()).then((response: { projects: any }) => {
      return response.projects
    })
  },

  getSolarfluxCredentials() {
    return API.get("/solarnetwork/credentials/solarflux").then((response) => {
      return response
    })
  },

  export(projectIds: string, range: Range, aggregate: string, format: string, sourceIds: string, consolidate: boolean) {
    let url = API.addRangeToUrl("/energy/export", range)
    url += "&projectIds=" + projectIds
    url += "&format=" + format
    url += "&aggregate=" + aggregate
    if (sourceIds) {
      url += "&sourceIds=" + sourceIds
    }
    if (consolidate) {
      url += "&consolidate=" + consolidate
    }
    return API.get(url as string)
  },

  enableSolarFluxHyperMode(project: unknown, site: unknown, timeout: number) {
    const body = {
      path: ProjectUtils.getPath(project, site),
      params: {
        "parameters[0].name": "OpMode",
        "parameters[0].value": "hyper",
        "parameters[1].name": "Expiration",
        "parameters[1].value": timeout,
        topic: "EnableOperationalModes",
      },
    }
    API.post("/solarnetwork/instruction", body)
  },

  disableSolarFluxHyperMode(project: unknown, site: unknown) {
    const body = {
      path: ProjectUtils.getPath(project, site),
      params: {
        "parameters[0].name": "OpMode",
        "parameters[0].value": "hyper",
        topic: "DisableOperationalModes",
      },
    }
    API.post("/solarnetwork/instruction", body)
  },

  async downloadConsumption(project: any) {
    const organizationId = await getUserOrganizationId()

    return Auth.currentCredentials().then((credentials) => {
      const s3 = new aws.S3({
        apiVersion: "2013-04-01",
        credentials: Auth.essentialCredentials(credentials),
      })

      const params = {
        Bucket: process.env.REACT_APP_CACHE_BUCKET,
        Key: `${organizationId}/consumption-cost/datums/project/${project.code}.json`,
      }

      // @ts-ignore
      return s3.getSignedUrlPromise("getObject", params, (_, url: string) => {
        window.open(url)
      })
    })
  },
}

export default EnergyService

import { create, all } from "mathjs"
import moment from "moment"
import Aggregations from "@common/Aggregations"
import DeviceUtils from "@common/utils/DeviceUtils"
import Utils from "@common/utils/Utils"
import React from "react"
import { orgUXSettingsContext } from "src/App"

const math = create(all)

const SEPARATOR = "/"

var round = function (value) {
  if (!value) {
    return 0
  } else if (value < 1) {
    return math.round(value, 3)
  } else if (value < 10) {
    return math.round(value, 2)
  } else if (value < 100) {
    return math.round(value, 1)
  } else {
    return math.round(value, 0)
  }
}

var EnergyUtils = {
  /**
   * Converts a number to the kilo representation by dividing it by 1000.
   * @param {value} value
   * @param {returnPrecise} boolean - Asks the function to return precise value (dont round off)
   * @returns {number}
   */
  convertToKilo(value, opts = {}) {
    if (!opts.returnPreciseValue) {
      return round(value / 1000)
    }
    return value / 1000
  },

  convertToMega(value, opts = {}) {
    if (!opts.returnPreciseValue) {
      return round(value / 1000000)
    }
    return value / 1000000
  },

  formatNumber(value, opts) {
    return Utils.formatNumber(value, opts)
  },

  formatNumberAsKilo(value) {
    return this.formatNumber(this.convertToKilo(value))
  },

  formatNumberAsMega(value) {
    return this.formatNumber(this.convertToMega(value))
  },

  // WattHours

  displayWattHours(wattHours, opts) {
    let abs = Math.abs(wattHours)
    if (abs < 1000) {
      return this.formatWattHours(wattHours, opts)
    } else if (abs < 1000000) {
      return this.formatKiloWattHours(this.convertToKilo(wattHours, opts), opts)
    } else {
      return this.formatMegaWattHours(this.convertToMega(wattHours, opts), opts)
    }
  },

  formatWattHoursAsKiloWattHours(value) {
    return this.formatKiloWattHours(this.convertToKilo(value))
  },

  formatWattHoursAsMegaWattHours(value) {
    return this.formatMegaWattHours(this.convertToMega(value))
  },

  formatWattHours(wattHours, opts) {
    return (this.formatNumber(wattHours, opts) || 0).toLocaleString("en") + " Wh"
  },

  formatWattHoursToPreferrence(wattHours, opts, children) {
    return (
      <orgUXSettingsContext.Consumer>
        {(orgUXSettings) => {
          const preferredUnits = orgUXSettings.preferredEnergyUnits ? orgUXSettings.preferredEnergyUnits : "kW"
          return (
            <>
              {this.formatWattHoursToUnits(wattHours, preferredUnits, opts)}
              {children}
            </>
          )
        }}
      </orgUXSettingsContext.Consumer>
    )
  },

  formatWattHoursToUnits(wattHours, units, opts) {
    switch (units) {
      case "kW":
        return this.formatKiloWattHours(this.convertToKilo(wattHours, opts), opts)
      case "MW":
        return this.formatMegaWattHours(this.convertToMega(wattHours, opts), opts)
      case "auto":
        return this.displayWattHours(wattHours, opts)
    }
  },

  formatKiloWattHours(kiloWattHours, opts) {
    return (this.formatNumber(kiloWattHours, opts) || 0).toLocaleString("en") + " kWh"
  },

  formatMegaWattHours(megaWattHours, opts) {
    return (this.formatNumber(megaWattHours, opts) || 0).toLocaleString("en") + " MWh"
  },

  // Watts

  displayWatts(watts) {
    let abs = Math.abs(watts)
    if (abs < 1000) {
      return this.formatWatts(watts)
    } else if (abs < 1000000) {
      return this.formatKiloWatts(this.convertToKilo(watts))
    } else {
      return this.formatMegaWatts(this.convertToMega(watts))
    }
  },

  formatWatts(watts) {
    return round(watts).toLocaleString("en") + " W"
  },

  formatKiloWatts(kiloWatts) {
    return round(kiloWatts).toLocaleString("en") + " kW"
  },

  formatKiloWattsToPreferrence(kiloWatts) {
    return this.formatWattsToPreferrence(kiloWatts * 1000)
  },

  formatKva(kva) {
    return (this.formatNumber(kva) || 0).toLocaleString("en") + " kVA"
  },

  formatMegaWatts(megaWatts) {
    return round(megaWatts).toLocaleString("en") + " MW"
  },

  formatWattsAsKiloWatts(value) {
    return this.formatKiloWatts(this.convertToKilo(value))
  },

  formatWattsToPreferrence(watts) {
    return (
      <orgUXSettingsContext.Consumer>
        {(orgUXSettings) => {
          const preferredUnits = orgUXSettings.preferredEnergyUnits ? orgUXSettings.preferredEnergyUnits : "kW"
          return this.formatWattsToUnits(watts, preferredUnits)
        }}
      </orgUXSettingsContext.Consumer>
    )
  },

  formatWattsToUnits(watts, units) {
    switch (units) {
      case "kW":
        return this.formatKiloWatts(this.convertToKilo(watts))
      case "MW":
        return this.formatMegaWatts(this.convertToMega(watts))
      case "auto":
        return this.displayWatts(watts)
    }
  },

  // Utility methods

  filterOutChildNodes(asset, children) {
    let childSourceIds = []
    children.forEach((child) => {
      childSourceIds = childSourceIds.concat(
        child.nodesStatus.map((nodeStatus) => {
          return nodeStatus.sourceId
        }),
      )
    })
    return asset.nodesStatus.filter((nodeStatus) => {
      return !childSourceIds.includes(nodeStatus.sourceId)
    })
  },

  getSites(project) {
    return Object.values(project.sites).sort((a, b) => {
      return a.code.localeCompare(b.code)
    })
  },

  getSystems(site) {
    return Object.values(site.systems).sort((a, b) => {
      return a.code.localeCompare(b.code)
    })
  },

  getAsset(assetArray, code) {
    return assetArray.find((asset) => {
      return asset.code === code
    })
  },

  isGenerationDatum(source) {
    let deviceType = this.getDeviceType(source)
    // return deviceType === 'INV'
    return deviceType === "GEN"
  },

  isConsumptionDatum(source) {
    let deviceType = this.getDeviceType(source)
    // return deviceType === 'MET'
    return deviceType === "CON"
  },

  isExportDatum(source) {
    let deviceType = this.getDeviceType(source)
    // return deviceType === 'MET'
    return deviceType === "EXP"
  },

  isStorageDatum(source) {
    let deviceType = this.getDeviceType(source)
    return deviceType === "STO"
  },

  isIrradianceDatum(source) {
    let deviceType = this.getDeviceType(source)
    return deviceType === "PYR"
  },

  isLoadDatum(source) {
    let deviceType = this.getDeviceType(source)
    return deviceType === "LOAD"
  },

  isInverterDatum(source) {
    let deviceType = this.getDeviceType(source)
    return deviceType === "INV"
  },

  isConsumingSource(source) {
    let deviceType = this.getDeviceType(source)
    let subDeviceType = this.getSubDeviceType(source)
    return (
      (deviceType === "CON" || deviceType === "LOAD" || deviceType === "MET" || deviceType === "EXP") && !subDeviceType
    )
  },

  isGeneratingSource(source) {
    let deviceType = this.getDeviceType(source)
    let subDeviceType = this.getSubDeviceType(source)
    return (deviceType === "GEN" || deviceType === "INV") && !subDeviceType
  },

  getDeviceType(source) {
    let sourceId = source.sourceId ? source.sourceId : source
    let parts = sourceId.split(SEPARATOR)
    if (parts.length > 4) {
      return parts[4]
    }
    return null
  },

  getSubDeviceType(source) {
    let sourceId = source.sourceId ? source.sourceId : source
    let parts = sourceId.split(SEPARATOR)
    if (parts.length > 6) {
      return parts[6]
    }
    return null
  },

  getGenerationLast24Hours(asset, assetStatus) {
    if (assetStatus) {
      let gen = assetStatus.nodesStatus.reduce((total, nodeStatus) => {
        if (nodeStatus.wattHours && this.isGenerationDatum(nodeStatus)) {
          total = math.add(total, this.convertToKilo(nodeStatus.wattHours)) // Get the value i kWh
        }
        return total
      }, 0)

      return math.round(gen, 2)
    }
    return 0
  },

  getPercent(actual, expected) {
    if (actual && expected) {
      return math.round(math.multiply(math.divide(actual, expected), 100))
    }
  },

  getCurrentForecast(forecast, range) {
    if (moment(range.start).isAfter(moment())) {
      return 0
    } else if (moment(range.end).isBefore(moment())) {
      return forecast
    } else {
      let rangeLength = moment(range.end).diff(range.start, "seconds")
      let length = moment().diff(range.start, "seconds")
      let ratio = math.divide(length, rangeLength)

      return math.multiply(forecast, ratio)
    }
  },

  /**
   * Checks whether the pyranomoeters for the supplied hierarchy will return complete readings for the supplied range.
   * If it's not then we can't rely on any irradiance based readings as there won't be readings for the entire range.
   * @param {*} project The project to check
   * @param {*} site (optional) the Site to check
   * @param {*} system optional) the System to check
   * @param {*} range The range to verify against.
   */
  isIrradianceCompleteForRange(project, site, system, range) {
    if (system) {
      // We look for Systems that have pyranomoeter start dates explicitly defined,
      if (system.devices && system.devices.length > 0) {
        let complete = true
        system.devices.forEach((device) => {
          if (DeviceUtils.isPyranometer(device) && device.startDate) {
            // Make sure each configured PYR source is current
            complete = complete && range.start.isSameOrAfter(moment(device.startDate))
          }
        })
        return complete
      } else {
        // if they don't we assume it's the same as the System start date
        return true
      }
    } else if (site) {
      let complete = true
      Object.values(site.systems).forEach((siteSystem) => {
        // Recursively check the systems
        complete = complete && this.isIrradianceCompleteForRange(project, site, siteSystem, range)
      })
      return complete
    } else if (project) {
      let complete = true
      Object.values(project.sites).forEach((projectSite) => {
        // Recursively check the sites
        complete = complete && this.isIrradianceCompleteForRange(project, projectSite, null, range)
      })
      return complete
    } else {
      throw new Error("At least the project must be specified")
    }
  },

  getNormalisedGeneration(sourceSize, sourceReading, aggregation, daylightHours) {
    switch (aggregation) {
      case Aggregations.FiveMinute:
        return math
          .chain(sourceReading)
          .divide(1000)
          .divide(sourceSize)
          .divide(12) // 5 minutes
          .round(3)
          .done()
      case Aggregations.FifteenMinute:
        return math
          .chain(sourceReading)
          .divide(1000)
          .divide(sourceSize)
          .divide(4) // 15 minutes
          .round(3)
          .done()
      case Aggregations.Hour:
        return math.chain(sourceReading).divide(1000).divide(sourceSize).round(3).done()
      case Aggregations.Day:
      case Aggregations.Month:
      case Aggregations.Year:
        return math.chain(sourceReading).divide(1000).divide(daylightHours).divide(sourceSize).round(3).done()
      default:
        throw new Error("Unsupported aggregation: " + aggregation)
    }
  },
}

export default EnergyUtils

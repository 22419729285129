import React from "react"
import moment from "moment"
import prettyBytes from "src/pretty-bytes"
import Settings from "@common/Settings"
import { SideBarSection, LAYOUT_CONTENT_RIGHT } from "@common/module/EcosuiteView"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import ConnectivityMapView from "./ConnectivityMapView"
import i18n from "src/i18n"
import { PieChart, Pie, Cell, Tooltip, Legend } from "recharts" // Import Recharts components
import { BarChart, Bar, XAxis, YAxis } from "recharts" // Import Recharts components for BarChart
import { Loading } from "@common/EcosuiteComponent"

const { t } = i18n

const getStatusValue = (node) => {
  // Check for any critical values (red)
  if (node.cpuUsage >= 90 || node.memoryUsage >= 90 || node.filesystemUsage >= 90 || node.cpuTemp >= 80) {
    return 0
  }
  // Check for any warning values (yellow)
  if (node.cpuUsage >= 60 || node.memoryUsage >= 60 || node.filesystemUsage >= 60) {
    return 0.5
  }
  // All values are good (green)
  return 1
}

const getStatusColor = (node) => {
  const statusValue = getStatusValue(node)

  if (statusValue === 0) {
    return "#e22006"
  } else if (statusValue === 0.5) {
    return "orange"
  }
  return "#4caf50"
}

const getBackgroundColor = (percentage) => {
  if (percentage >= 90) return "#e2200633"
  if (percentage >= 50) return "#FFA50033"
  return "#4caf5033"
}

const formatUptime = (seconds) => {
  const days = Math.floor(seconds / (24 * 3600))
  const hours = Math.floor((seconds % (24 * 3600)) / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const secs = seconds % 60

  if (days > 0) return `${days} day${days > 1 ? "s" : ""}`
  if (hours > 0) return `${hours} hour${hours > 1 ? "s" : ""}`
  if (minutes > 0) return `${minutes} minute${minutes > 1 ? "s" : ""}`
  return `${secs} second${secs > 1 ? "s" : ""}`
}

const NodeStatusTable = ({ data }) => {
  return (
    <table className="table" style={{ borderCollapse: "separate", borderSpacing: 0 }}>
      <thead>
        <tr>
          <th>{t("connectivity.labels.node_id")}</th>
          <th>{t("connectivity.labels.most_recent_status")}</th>
          <th>{t("connectivity.labels.uptime")}</th>
          <th>{t("connectivity.labels.cpu_usage")}</th>
          <th>{t("connectivity.labels.memory_usage")}</th>
          <th>{t("connectivity.labels.cpu_temperature")}</th>
          <th>{t("connectivity.labels.total_upload")}</th>
          <th>{t("connectivity.labels.total_download")}</th>
          <th>{t("connectivity.labels.filesystem_usage")}</th>
        </tr>
      </thead>
      <tbody>
        {data.map((node, index) => (
          <tr
            key={index}
            style={{
              position: "relative",
              transform: "translateX(8px)",
              width: "calc(100% - 8px)",
            }}
          >
            <td style={{ paddingLeft: "1em" }}>{node.nodeId}</td>
            <td>{moment(node.mostRecentStatus).fromNow()}</td>
            <td>{formatUptime(node.uptime)}</td>
            <td
              style={{
                paddingLeft: "1em",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: `${node.cpuUsage}%`,
                  backgroundColor: getBackgroundColor(node.cpuUsage),
                  zIndex: 0,
                }}
              />
              {node.cpuUsage}%
            </td>
            <td
              style={{
                paddingLeft: "1em",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: `${node.memoryUsage}%`,
                  backgroundColor: getBackgroundColor(node.memoryUsage),
                  zIndex: 0,
                }}
              />
              {node.memoryUsage}%
            </td>
            <td>{node.cpuTemp}°C</td>
            <td>{prettyBytes(node.totalUpload)}</td>
            <td>{prettyBytes(node.totalDownload)}</td>
            <td
              style={{
                paddingLeft: "1em",
                position: "relative",
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: 0,
                  left: 0,
                  width: "100%",
                  height: `${node.filesystemUsage}%`,
                  backgroundColor: getBackgroundColor(node.filesystemUsage),
                  zIndex: 0,
                }}
              />
              <span style={{ position: "relative", zIndex: 1 }}>{node.filesystemUsage}%</span>
            </td>
            <div
              style={{
                position: "absolute",
                left: "-8px",
                top: 0,
                bottom: 0,
                width: "4px",
                backgroundColor: getStatusColor(node),
              }}
            />
          </tr>
        ))}
      </tbody>
    </table>
  )
}

export default class ConnectivityOverView extends ConnectivityMapView {
  constructor(props, name) {
    super(props, name ? name : "connectivity-overview")

    this.state = {
      connectivityGraphView: Settings.getSetting("connectivityGraphView", "total"),
    }
    this.selectGraph = this.selectGraph.bind(this)
  }

  selectGraph(graph) {
    Settings.setSetting("connectivityGraphView", graph)
    this.setState({ connectivityGraphView: graph })
  }

  renderMainView() {
    if (this.props.healthStatusDatums) {
      // Here's a datum from the API:
      // {
      //   {
      //       "created": "2024-11-06 23:30:38Z",
      //       "nodeId": 676,
      //       "sourceId": "OS_Stats",
      //       "localDate": "2024-11-06",
      //       "localTime": "18:30",
      //       "cpu_idle": 99.5,
      //       "cpu_temp": 53.692,
      //       "cpu_user": 0.5,
      //       "fs_size_/": 7524159488,
      //       "fs_used_/": 1546760192,
      //       "ram_avail": 196026368,
      //       "ram_total": 953786368,
      //       "cpu_system": 0,
      //       "fs_size_/run": 476893184,
      //       "fs_used_/run": 13058048,
      //       "sys_load_1min": 0.04,
      //       "sys_load_5min": 0.01,
      //       "sys_load_15min": 0,
      //       "ram_used_percent": 79.4,
      //       "fs_used_percent_/": 21,
      //       "fs_used_percent_/run": 3,
      //       "sys_up": 10969556.74,
      //       "net_bytes_in_eth0": 1174376494,
      //       "net_bytes_out_eth0": 84092435,
      //       "net_packets_in_eth0": 2001475,
      //       "net_packets_out_eth0": 1258882
      //   },
      // }

      const transformedData = this.props.healthStatusDatums.map((datum) => ({
        nodeId: datum.nodeId,
        uptime: datum.sys_up,
        mostRecentStatus: datum.created,
        cpuUsage: Math.round(datum.cpu_user),
        memoryUsage: Math.round(((datum.ram_total - datum.ram_avail) / datum.ram_total) * 100),
        cpuTemp: Math.round(datum.cpu_temp),
        totalUpload: datum.net_bytes_out_eth0,
        totalDownload: datum.net_bytes_in_eth0,
        filesystemUsage: Math.round(datum["fs_used_percent_/"]),
      }))

      return <NodeStatusTable data={transformedData} />
    } else {
      return <Loading />
    }
  }

  getLayout() {
    return LAYOUT_CONTENT_RIGHT
  }

  //renderFooter() {
  //  const generateSampleData = () => {
  //    const days = 7;
  //    const hours = 4;

  //    return Array(days).fill().map(() =>
  //      Array(hours).fill().map(() => Math.random())
  //    );
  //  };

  //  const daysOfWeek = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];
  //  const hours = Array.from({ length: 4 }, (_, i) => i.toString().padStart(2, '0'));
  //
  //  return (
  //    <div style={{
  //      padding: '20px',
  //      height: '100%',
  //      display: 'flex',
  //      flexDirection: 'column'
  //    }}>
  //      <HeatMapGraph
  //        title={"Node Health"}
  //        data={generateSampleData()}
  //        rowLabels={daysOfWeek}
  //        columnLabels={hours}
  //        style={{ flex: 1 }}
  //      />
  //    </div>
  //  );
  //}

  renderSiderBar() {
    if (!this.props.nodeMetadata) {
      return <Loading />
    }

    // Count OS versions and architectures
    const osVersionCounts = {}
    const archCounts = {}
    this.props.nodeMetadata.forEach((node) => {
      const osVersion = node.pm?.os?.version || "Unknown"
      const arch = node.pm?.os?.arch || "Unknown"
      osVersionCounts[osVersion] = (osVersionCounts[osVersion] || 0) + 1
      archCounts[arch] = (archCounts[arch] || 0) + 1
    })

    // Transform for pie charts
    const osData = Object.entries(osVersionCounts).map(([name, value]) => ({
      name,
      value,
    }))

    const archData = Object.entries(archCounts).map(([name, value]) => ({
      name,
      value,
    }))

    // Generate colors
    const OS_COLORS = osData.map((entry, index) =>
      entry.name === "Unknown" ? "#6b6b6b80" : GRAPH_COLORS[index % GRAPH_COLORS.length],
    )

    const ARCH_COLORS = archData.map((entry, index) =>
      entry.name === "Unknown" ? "#6b6b6b80" : GRAPH_COLORS[index % GRAPH_COLORS.length] + "f2",
    )

    const pieChartContent = (
      <div style={{ width: "100%", height: "300px" }}>
        <PieChart width={300} height={300}>
          <Pie data={osData} cx="50%" cy="50%" labelLine={false} outerRadius={100} fill="#8884d8" dataKey="value">
            {osData.map((entry, index) => (
              <Cell key={`os-cell-${index}`} fill={OS_COLORS[index]} />
            ))}
          </Pie>
          <Pie
            data={archData}
            cx="50%"
            cy="50%"
            labelLine={false}
            innerRadius={60}
            outerRadius={80}
            fill="#82ca9d"
            dataKey="value"
          >
            {archData.map((entry, index) => (
              <Cell key={`arch-cell-${index}`} fill={ARCH_COLORS[index]} />
            ))}
          </Pie>
          <Tooltip />
          <Legend />
        </PieChart>
      </div>
    )

    // Modified data processing
    const ageOsData = {}
    const currentTime = moment()

    this.props.nodeMetadata.forEach((node) => {
      const osVersion = node.pm?.os?.version || "Unknown"
      const createdDate = moment(node.created)
      const ageInYears = currentTime.diff(createdDate, "years")
      const ageCategory = ageInYears > 5 ? "5+ years" : `${ageInYears} years`

      if (!ageOsData[ageCategory]) {
        ageOsData[ageCategory] = {}
      }
      ageOsData[ageCategory][osVersion] = (ageOsData[ageCategory][osVersion] || 0) + 1
    })

    // Transform data for stacked bar chart
    const allOsVersions = [...new Set(this.props.nodeMetadata.map((node) => node.pm?.os?.version || "Unknown"))]
    const stackedData = Object.entries(ageOsData)
      .sort((a, b) => {
        // Convert '5+ years' to 5 and 'X years' to X for comparison
        const getYears = (str) => (str === "5+ years" ? 5 : parseInt(str))
        return getYears(a[0]) - getYears(b[0])
      })
      .map(([age, osCount]) => ({
        name: age,
        ...allOsVersions.reduce(
          (acc, os) => ({
            ...acc,
            [os]: osCount[os] || 0,
          }),
          {},
        ),
      }))

    const ageHistogramContent = (
      <div style={{ width: "100%", height: "400px" }}>
        <BarChart width={300} height={300} data={stackedData}>
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          {allOsVersions.map((os, index) => (
            <Bar key={os} dataKey={os} stackId="a" fill={OS_COLORS[index]} />
          ))}
        </BarChart>
      </div>
    )

    return (
      <div>
        <SideBarSection
          title={t("connectivity.labels.os_versions")}
          subtitle={t("connectivity.labels.by_version")}
          content={pieChartContent}
        />
        <SideBarSection
          title={t("connectivity.labels.node_age_distribution")}
          subtitle={t("connectivity.labels.by_age_and_os_version")}
          content={ageHistogramContent}
        />
      </div>
    )
  }
}

import React from "react"
import { Input } from "reactstrap"
import { create, all } from "mathjs"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

const math = create(all)

export function irradianceWidget(props) {
  const readonly = props.formContext.readonly
  return (
    <>
      <UncontrolledTooltip key={props.id} placement="right" target={props.id + "entry"}>
        Ratio is automatically maintained bewtween left and right side
      </UncontrolledTooltip>
      <div id={props.id + "entry"}>
        <div className="form-group field field-object irradiance-input">
          <Input
            type="number"
            className="form-group field field-string"
            value={props.value ? math.round(props.value, 3) : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
            required={props.required}
            onChange={(event) => {
              props.onChange(Number(event.target.value))
            }}
            readOnly={readonly || props.readonly}
            disabled={props.disabled}
          />
          <span className="units">(kWh/m2/day)</span>
        </div>
        <div className="form-group field field-object irradiance-input">
          <Input
            type="number"
            className="form-group field field-string"
            value={!isNaN(props.value) ? math.round(props.value * props.options.days, 3) : ""} // We ensure a value is passed in as otherwise the input becomes an uncontrolled component
            required={props.required}
            onChange={(event) => {
              if (!isNaN(event.target.value)) {
                props.onChange(math.round(event.target.value / props.options.days, 5)) //Return 5dp otherwise the increment will be too big to let the right side column change
              }
            }}
            readOnly={readonly || props.readonly}
            disabled={props.disabled}
          />
          <span className="units">(kWh/m2/month)</span>
        </div>
      </div>
    </>
  )
}

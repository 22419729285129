import React, { Component } from "react"
import { DropdownMenu, DropdownItem, DropdownToggle, ButtonDropdown, Badge, Input, Button } from "reactstrap"
import { Link } from "react-router-dom"

import * as Tracker from "@common/utils/TrackingUtils"

import SearchUtils from "@common/utils/SearchUtils"
import Icon from "@common/display/Icon"
import Settings from "@common/Settings"
import i18n from "src/i18n"

const { t } = i18n
/**
 * The project filter allows the available projects to be filtered to a custom selection.
 */
export class ProjectFilter extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isFilterOpen: false,
      isSortOpen: false,
      isSubmenuOpen: false,
      submenuRefinementValue: "",
      selectedSortMethod: Settings.getSetting("project-sort-method", "alpha/desc"), // Default to alpha/desc
      sortMethods: {
        "alpha/desc": "Alphabetically from A to Z",
        "alpha/asc": "Alphabetically from Z to A",
        "dcSize/desc": "DC Size from Max to Min",
        "dcSize/asc": "DC Size from Min to Max",
        "retainedEarnings/desc": "Retained Earnings from Max to Min",
        "retainedEarnings/asc": "Retained Earnings from Min to Max",
      },
    }

    this.selectFilter = this.selectFilter.bind(this)
    this.toggleViews = this.toggleViews.bind(this)
    this.showMenu = this.showMenu.bind(this)
    this.hideMenu = this.hideMenu.bind(this)
    this.toggleRefinement = this.toggleRefinement.bind(this)
    this.showRefinement = this.showRefinement.bind(this)
    this.hideRefinement = this.hideRefinement.bind(this)
  }

  toggleViews() {
    this.setState({
      isFilterOpen: !this.state.isFilterOpen,
    })
  }

  showMenu() {
    this.setState({ isFilterOpen: true })
  }

  hideMenu() {
    this.setState({ isFilterOpen: false })
  }

  toggleRefinement() {
    this.setState({
      refinement: !this.state.refinement,
    })
  }

  showRefinement(refinement) {
    this.setState({ refinement: refinement })
  }

  hideRefinement() {
    this.setState({ refinement: null })
  }

  selectFilter(filter) {
    this.props.actions.selectFilter(filter)
  }

  toggleSubmenu = (mainTag) => {
    this.setState((prevState) => ({
      isSubmenuOpen: {
        ...prevState.isSubmenuOpen,
        [mainTag]: !prevState.isSubmenuOpen[mainTag],
      },
    }))
  }

  onMouseOver = (mainTag) => {
    this.setState({
      isSubmenuOpen: {
        ...this.state.isSubmenuOpen,
        [mainTag]: true,
      },
      submenuRefinementValue: mainTag,
    })
  }

  onMouseLeave = () => {
    this.setState({
      isSubmenuOpen: {},
      submenuRefinementValue: "",
    })
  }

  render() {
    const refinementsConfig = this.props.refinements
    const selectedRefinements = this.props.selectedRefinements
    const restrictProjects = this.props.restrictions.restrictProjects === "yes"

    if (restrictProjects) {
      return null // filtering isn't available to users who are restricted to projects
    }

    if (this.props.selectedProject && this.props.portfoliosEnabled) {
      return (
        <Button className="menu-back" onClick={() => this.props.actions.selectProject()}>
          <Icon icon="chevron_left" /> {t("labels.view_portfolio")}
        </Button>
      )
    }

    const portfolioViewSelected = this.props.portfoliosEnabled && !this.props.selectedProject
    const refinementKeysToTranslate = [
      "Product Type",
      "Product Sub Type",
      "Project Status",
      "Project Flag",
      "System Status",
    ]
    const sortedTags = refinementsConfig["Tag"]?.sort((tagA, tagB) => tagA.localeCompare(tagB))

    const groupTags = (tags) => {
      const groupedTags = []
      tags?.forEach((tag) => {
        const [mainTag, subTag] = tag.split(":").map((str) => str.trim())

        if (!groupedTags[mainTag]) {
          groupedTags[mainTag] = []
        }

        if (subTag) {
          groupedTags[mainTag].push(subTag)
        } else {
          groupedTags[mainTag].push(tag)
        }
      })
      return groupedTags
    }

    const formatTags = (groupedTags) => {
      const formattedTags = []
      Object.keys(groupedTags).forEach((mainTag) => {
        const subTags = groupedTags[mainTag]
        if (subTags.length > 1) {
          formattedTags.push({ [mainTag]: subTags })
        } else {
          formattedTags.push(mainTag)
        }
      })
      return formattedTags
    }

    const groupedTags = groupTags(sortedTags)
    const formattedTags = formatTags(groupedTags)
    const groupedStateTags = groupTags(refinementsConfig.State)
    const formattedStateTags = formatTags(groupedStateTags)

    const sortedRefinementsConfig = {
      ...refinementsConfig,
      Tag: formattedTags,
      State: formattedStateTags,
    }

    return (
      <div className="project-filter">
        <div
          className={`project-filter-title${portfolioViewSelected ? " project-filter-selected" : ""}`}
        >{`${this.props.projects.length} / ${
          this.props.portfolio ? this.props.portfolio.projects.length : this.props.allProjects.length
        } ${t("labels.projects")}`}</div>
        {
          // Show the save button if:
          // - portfolios are enabled
          // - a portfolio is selected OR there are disabled projects (meaning not all projects are selected)
          // - the user has data-write permissions
          this.props.portfoliosEnabled &&
          (this.props.portfolio || this.props.disabledProjects.length > 0) &&
          this.props.groups.includes("data-write") ? (
            <Link
              to={`/admin/settings?new-portfolio&projectIds=${this.props.projects
                .filter((project) => {
                  // Filter out any disabled projects.
                  return !this.props.disabledProjects.includes(project.code)
                })
                .map((project) => project.code)
                .join(",")}`}
              target="_blank"
              className={`float-end${portfolioViewSelected ? " project-filter-selected" : ""}`}
              color="ecogy"
              title={`${t("notes.create_portfolio")}`}
              onClick={() => {
                Tracker.emit({ name: "App: SolarNetwork" })
              }}
            >
              <Icon className="save-icon" icon="save" />
            </Link>
          ) : null
        }

        <ButtonDropdown
          direction="end"
          isOpen={this.state.isSortOpen}
          onMouseOver={() => this.setState({ isSortOpen: true })}
          onMouseLeave={() => this.setState({ isSortOpen: false })}
          toggle={(e) => e.preventDefault()} // Prevents error when the button is clicked
          className="dropdown-menu-dark"
        >
          <DropdownToggle className={`project-filter-toggle${portfolioViewSelected ? " project-filter-selected" : ""}`}>
            <Icon icon="swap_vert" />
          </DropdownToggle>

          <DropdownMenu className="header-menu">
            {Object.entries(this.state.sortMethods).map(([key, title]) => {
              return (
                <DropdownItem
                  key={key}
                  toggle={false}
                  onClick={(e) => {
                    e.preventDefault()
                    this.setState({ selectedSortMethod: key })
                    Settings.setSetting("project-sort-method", key)
                    this.props.actions.selectProjectSortMethod(key.split("/"))
                  }}
                  className="sort-method"
                >
                  <Input
                    type="checkbox"
                    checked={this.state.selectedSortMethod === key}
                    style={{ pointerEvents: "none" }}
                    onChange={(e) => {
                      e.preventDefault()
                    }}
                  />
                  {t(`sortMethods.${title}`)}
                </DropdownItem>
              )
            })}
          </DropdownMenu>
        </ButtonDropdown>

        {/* Filters */}
        {this.props.showFilters ? (
          <ButtonDropdown
            direction="end"
            isOpen={this.state.isFilterOpen}
            onMouseOver={this.showMenu}
            onMouseLeave={this.hideMenu}
            toggle={this.toggleViews}
            className="project-filter-menu dropdown-menu-dark"
          >
            {/* Toggle */}
            <DropdownToggle
              className={`project-filter-toggle${portfolioViewSelected ? " project-filter-selected" : ""}`}
            >
              <Icon icon="filter_list" />
            </DropdownToggle>

            <DropdownMenu className="header-menu">
              {/* Refinements */}
              {Object.keys(sortedRefinementsConfig).map((refinementKey) => {
                return (
                  <ButtonDropdown
                    isOpen={this.state.refinement === refinementKey}
                    onMouseOver={() => {
                      this.showRefinement(refinementKey)
                    }}
                    onMouseLeave={this.hideRefinement}
                    toggle={this.toggleRefinement}
                    key={refinementKey}
                    direction="end"
                    className="refinement"
                  >
                    {/* Refinement Toggle / Hover */}
                    <DropdownToggle caret>
                      {t(`refinementKeys.${refinementKey}`)}{" "}
                      {SearchUtils.isRefinementActive(selectedRefinements, refinementKey) ? (
                        <span className="menu-info">
                          <Badge color="ecogy">
                            {selectedRefinements[refinementKey] ? selectedRefinements[refinementKey].length : 0}/
                            {sortedRefinementsConfig[refinementKey].length}
                          </Badge>
                          <Icon
                            icon="close"
                            size="sm"
                            onClick={() => {
                              this.props.actions.toggleRefinement(refinementKey)
                            }}
                          />
                        </span>
                      ) : null}
                    </DropdownToggle>

                    {/* Refinement Toggled Menu */}
                    <DropdownMenu positionfixed="true" className={refinementKey}>
                      {sortedRefinementsConfig[refinementKey].map((refinementValue, index) => {
                        if (typeof refinementValue === "object") {
                          const mainTag = Object.keys(refinementValue)[0]
                          const subTags = refinementValue[mainTag]

                          return (
                            <DropdownItem toggle={false} key={index}>
                              <ButtonDropdown
                                isOpen={this.state.isSubmenuOpen[mainTag]}
                                toggle={() => this.toggleSubmenu(mainTag)}
                                onMouseLeave={this.onMouseLeave}
                                onMouseOver={() => this.onMouseOver(mainTag)}
                                direction="end"
                              >
                                <DropdownToggle caret>{mainTag}</DropdownToggle>
                                <DropdownMenu positionfixed="true">
                                  {subTags.map((subTag) => {
                                    const subTagValue = `${mainTag}: ${subTag}`

                                    return (
                                      <DropdownItem
                                        toggle={false}
                                        key={subTag}
                                        onClick={(e) => {
                                          e.preventDefault()
                                          this.props.actions.toggleRefinement(refinementKey, subTagValue)
                                        }}
                                      >
                                        <Input
                                          type="checkbox"
                                          checked={SearchUtils.isRefinementSelected(
                                            selectedRefinements,
                                            refinementKey,
                                            subTagValue,
                                          )}
                                          onChange={(e) => {
                                            e.preventDefault()
                                          }}
                                          style={{ pointerEvents: "none" }}
                                        ></Input>
                                        {subTag}
                                      </DropdownItem>
                                    )
                                  })}
                                </DropdownMenu>
                              </ButtonDropdown>
                            </DropdownItem>
                          )
                        } else {
                          return (
                            <DropdownItem
                              toggle={false}
                              key={index}
                              onClick={(e) => {
                                e.preventDefault()
                                this.props.actions.toggleRefinement(refinementKey, refinementValue)
                              }}
                            >
                              <Input
                                type="checkbox"
                                checked={SearchUtils.isRefinementSelected(
                                  selectedRefinements,
                                  refinementKey,
                                  refinementValue,
                                )}
                                onChange={(e) => {
                                  e.preventDefault()
                                }}
                                style={{ pointerEvents: "none" }}
                              ></Input>
                              {refinementKeysToTranslate.includes(refinementKey)
                                ? `${t(`refinementValues.${refinementValue}`)}`
                                : `${refinementValue}`}
                            </DropdownItem>
                          )
                        }
                      })}
                    </DropdownMenu>
                  </ButtonDropdown>
                )
              })}
            </DropdownMenu>
          </ButtonDropdown>
        ) : null}
      </div>
    )
  }
}

import * as React from "react"
import { ControllerRenderProps } from "react-hook-form"
import { Input, Label } from "reactstrap"

interface Props extends ControllerRenderProps {
  label?: string
}

export default function ISODateField(props: Props) {
  const datetimeLocalToISO = (datetime: string) => {
    const date = new Date(datetime)
    const iso = date.toISOString()
    return iso
  }

  const ISOToDatetimeLocal = (ISO: string) => {
    const date = new Date(ISO);
    const returnDate = (new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString()).slice(0, -8);
    return returnDate
  }

  return (
    <>
      <Label style={{ marginTop: "5px" }} for={props.label}>
        {props.label}
      </Label>
      <Input
        {...props}
        value={props.value ? ISOToDatetimeLocal(props.value) : ""}
        type={"datetime-local"}
        id={props.label}
        onChange={(event) => props.onChange(datetimeLocalToISO(event.target.value))}
      />
    </>
  )
}


import * as React from "react"
import { Control, UseFormWatch, Controller } from "react-hook-form"
import { Input, Label } from "reactstrap"

interface Project {
  code: string
  name: string
  sites: Record<string, Site>
}

interface System {
  code: string
  name: string
}

interface Node {
  id: string
}

type Level = 'project' | 'site' | 'system' | 'node' | 'device'

const levelMap: Record<Level, number> = {
  project: 0,
  site: 1,
  system: 2,
  node: 3,
  device: 4
}

interface DeviceLocationFormFieldsProps {
  control: Control<any>
  watch: UseFormWatch<any>
  projects: Project[]
  project: Project
  devices: Record<string, any>
  level: Level
}

export default function DeviceLocationFormFields(props: DeviceLocationFormFieldsProps) {
  const level = levelMap[props.level]
  const { control, watch } = props

  const serviceProject = watch("location.project")
  const serviceSite = watch("location.site")
  const serviceSystem = watch("location.system")
  const serviceNode = watch("node")

  const projectFromProjectCode = (projectCode: string) => {
    return props.projects.find((project: Project) => project.code === projectCode)
  }

  const siteFromSiteCode = (siteCode: string) => {
    const project = projectFromProjectCode(serviceProject)
    return project ? project.sites[siteCode] : false
  }

  const systemFromSystemCode = (systemCode: string) => {
    const site = siteFromSiteCode(serviceSite)
    if (!props.devices[serviceProject]?.sites[serviceSite]?.systems[systemCode]) return false
    return site.systems[systemCode]
  }

  const nodeFromNodeId = (nodeId: string) => {
    const system = systemFromSystemCode(serviceSystem)
    if (!system) return false
    if (!props.devices[serviceProject].sites[serviceSite].systems[serviceSystem].nodes[nodeId]) return false
    return props.devices[serviceProject].sites[serviceSite].systems[serviceSystem].nodes[nodeId]
  }

  return (
    <>
      {level >= levelMap.project && (
        <Controller
          name="location.project"
          control={control}
          render={({ field }) => (
            <>
              <Label for={"project"}>Project *</Label>
              <Input required={true} type={"select"} id={"project"} label={"Project"} {...field}>
                {props.projects.map((project, idx) => {
                  return (
                    <option value={project.code} key={idx}>
                      {project.name}
                    </option>
                  )
                })}
              </Input>
            </>
          )}
        />)}
      {level >= levelMap.site && (
        <Controller
          name="location.site"
          control={control}
          render={({ field }) => (
            <>
              <Label style={{ marginTop: "5px" }} for={"site"}>Site *</Label>
              <Input required={true} type={"select"} id={"site"} label={"Site"} {...field}>
                {serviceProject !== "" ? (
                  <>
                    <option value={""} key={"0"}>
                      Select Site...
                    </option>
                    {(() => {
                      const project = projectFromProjectCode(serviceProject)
                      return project?.sites && Object.values(project.sites).map((site, idx) => {
                        return (
                          <option value={site.code} key={idx + 1}>
                            {site.name}
                          </option>
                        )
                      })
                    })()}
                  </>
                ) : null}
              </Input>
            </>
          )}
        />
      )}
      {level >= levelMap.system && (
        <Controller
          name="location.system"
          control={control}
          render={({ field }) => (
            <>
              <Label style={{ marginTop: "5px" }} for={"system"}>System</Label>
              <Input type={"select"} id={"system"} label={"System"} {...field}>
                {serviceSite !== "" && siteFromSiteCode(serviceSite) ? (
                  <>
                    <option value={""} key={"0"}>
                      Select System...
                    </option>
                    {(Object.values(siteFromSiteCode(serviceSite).systems) as System[]).map((system: System, idx) => {
                      return (
                        <option value={system.code} key={idx}>
                          {system.name}
                        </option>
                      )
                    })}
                  </>
                ) : null}
              </Input>
            </>
          )}
        />
      )}
      {level >= levelMap.node && (
        <Controller
          name="node"
          control={control}
          render={({ field }) => (
            <>
              <Label style={{ marginTop: "5px" }} for={"node"}>Node</Label>
              <Input type={"select"} id={"node"} label={"Node"} {...field}>
                {serviceSystem !== "" && systemFromSystemCode(serviceSystem) ? (
                  <>
                    <option value={""} key={"0"}>
                      Select Node...
                    </option>
                    {(Object.values(props.devices[serviceProject].sites[serviceSite].systems[serviceSystem].nodes) as Node[]).map((node: Node, idx) => {
                      return (
                        <option value={node.id} key={idx}>
                          {node.id}
                        </option>
                      )
                    })}
                  </>
                ) : null}
              </Input>
            </>
          )}
        />
      )}
      {level >= levelMap.device && (
        <Controller
          name="device"
          control={control}
          render={({ field }) => (
            <>
              <Label style={{ marginTop: "5px" }} for={"device"}>Device</Label>
              <Input type={"select"} id={"device"} label={"Device"} {...field}>
                {serviceNode !== "" && nodeFromNodeId(serviceNode) ? (
                  <>
                    <option value={""} key={"0"}>
                      Select Device...
                    </option>
                    {props.devices[serviceProject].sites[serviceSite].systems[serviceSystem].nodes[
                      serviceNode
                    ].devices.map((device: string, idx: number) => {
                      return (
                        <option value={device} key={idx}>
                          {device}
                        </option>
                      )
                    })}
                  </>
                ) : null}
              </Input>
            </>
          )}
        />
      )}
    </>
  )
}

import React from "react"
import { addAutoComplete } from "@common/form/UISchema"
import { DateTime } from "luxon"
import { Button } from "reactstrap"
import Icon from "@common/display/Icon"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"
import i18n from "src/i18n"

const { t } = i18n

function isDeviceActiveOnSN(devices, formDevice) {
  return devices.find((device) => shortSourceId(device.sourceId) === formDeviceToString(formDevice))
}

function isDeviceOnSN(nodes, formDevice) {
  const deviceList = nodes.flatMap((node) => node.devices)
  return deviceList.find((device) => device === formDeviceToString(formDevice))
}

function isDevicePresentOnForm(formDevices, device) {
  return formDevices.find((formDevice) => shortSourceId(device.sourceId) === formDeviceToString(formDevice))
}

function formDeviceToString(formDevice) {
  return formDevice.type + "/" + formDevice.id
}

function isDeviceComplete(device) {
  if (device.type === "PYR") {
    return Object.values(device).length > 2
  }
  if (device.type === "STO") {
    return Object.values(device).length > 4
  }
  return Object.values(device).length > 3
}

function shortSourceId(sourceId) {
  const parts = sourceId.split("/")
  return parts[4] + "/" + parts[5]
}

export default function getUiSchema(props) {
  const order = ["*", "verified"]
  const uiSchema = {
    "ui:order": order,
    code: {
      "ui:disabled": props.system && props.system.code !== undefined,
    },
    solarArrays: {
      "ui:item-label": "Array",
      "ui:collapse": {
        collapsed: true,
        renderTitle: (props, idx) => {
          const entry = props.items[idx].children.props.formData
          if (entry.name) {
            return <>{entry.name}</>
          } else {
            return null
          }
        },
      },
      "ui:tooltips": {
        add: "Add a solar array",
        remove: "Remove solar array",
      },
      items: {
        "ui:options": {
          label: false,
        },
        ghiData: {
          "ui:description":
            "Indicate that this array uses a Global Horizontal Irradiance (GHI) sensor, rather than a Plane of Array (POA) sensor",
        },
        arrayTiltFactor: {
          "ui:description": "The array tilt factor (not used if transposing GHI data)",
        },
      },
    },
    devices: {
      "ui:item-label": "Device",
      "ui:additionalFields": (props) => {
        if (props.formContext.systemSNDevices && props.formContext.systemSNDevices.length >= props.formData.length) {
          return (
            <>
              {props.formContext.systemSNDevices.length > props.formData.length ? <h5>Suggested devices:</h5> : null}
              {props.formContext.systemSNDevices.map((device, i) => {
                //filter out forecast virtual devices
                //eg: INV/1/forecast/24
                //Filter may need to be more specific in the future if source ids get longer or more complex
                const parts = device.sourceId.split("/")
                if (parts.length > 6) {
                  return null
                }

                //Add suggestion if SN device is not present on from
                return !isDevicePresentOnForm(props.formData, device) ? (
                  <div className={"field-array-entry"} key={i}>
                    <div className={"field-array-entry-collapse"}>{device.sourceId}</div>
                    <div className={"field-array-buttons"}>
                      <Button
                        onClick={() => {
                          props.formContext.addSuggestedDevice(device)
                        }}
                        className="icon-button"
                        outline
                        color="secondary"
                      >
                        <Icon icon={"auto_fix_high"} /> Add Suggested
                      </Button>
                    </div>
                  </div>
                ) : null
              })}
            </>
          )
        }
      },
      "ui:collapse": {
        collapsed: true,
        renderTitle: (props, idx) => {
          const entry = props.items[idx].children.props.formData
          if (entry.type) {
            if (props.formContext.systemSNDevices && props.formContext.SNDevices) {
              //Show warning if device is not found on SN
              if (!isDeviceOnSN(Object.values(props.formContext.SNDevices.nodes), entry)) {
                return (
                  <>
                    /{entry.type}/{entry.id}
                    <span style={{ color: "yellow" }} id={entry.type + entry.id}>
                      {" "}
                      {t("errors.sn_device_missing_warning")}
                    </span>
                    <UncontrolledTooltip key={entry.type + entry.id} placement="top" target={entry.type + entry.id}>
                      {t("errors.sn_device_missing_warning_tt")}
                    </UncontrolledTooltip>
                  </>
                )
              }

              //Show warning if device is found on SN but no recent Data was found
              if (!isDeviceActiveOnSN(Object.values(props.formContext.systemSNDevices), entry)) {
                return (
                  <>
                    /{entry.type}/{entry.id}
                    <span style={{ color: "yellow" }} id={entry.type + entry.id}>
                      {" "}
                      {t("errors.no_recent_data")}
                    </span>
                    <UncontrolledTooltip key={entry.type + entry.id} placement="top" target={entry.type + entry.id}>
                      {t("errors.no_recent_data_tt")}
                    </UncontrolledTooltip>
                  </>
                )
              }
            }
            //Show entries without warnings untill device data is fetched from SN

            return (
              <>
                /{entry.type}/{entry.id}{" "}
                {!isDeviceComplete(entry) ? <span style={{ color: "yellow" }}>Device incomplete</span> : null}
              </>
            )
          } else {
            return null
          }
        },
      },
      "ui:tooltips": {
        add: "Add a device",
        remove: "Remove device",
      },
      items: {
        "ui:options": {
          label: false,
        },
        type: {
          "ui:autofocus": true,
        },
      },
    },
    productionPerYear: {
      "ui:readonly": true,
      "ui:description": "Calculated automatically based on the kWh/kWp and DC size fields.",
    },
    forecast: {
      "ui:options": {
        label: false,
        description: false,
        objectLabel: false,
        objectDescription: false,
      },
      useRealData: {
        "ui:disabled": DateTime.now().diff(DateTime.fromISO(props.system?.startDate), "year").years < 1,
        "ui:description": "Using real data for forecast is only enabled after 1 year since the project start date",
      },
      weightMonths: {
        "ui:options": {
          label: false,
          labelPlaceholder: true,
        },
        "ui:description":
          "By default the monthly consumption will be calculated based on the number of days in the month",
      },
      weightDays: {
        "ui:options": {
          label: false,
          labelPlaceholder: true,
        },
        "ui:description": "By default the daily consumption will be split evenly across all days in the week",
      },
      weightHours: {
        "ui:options": {
          label: false,
          labelPlaceholder: true,
        },
        "ui:description":
          "By default the hourly consumption will be split evenly across all hours in the day: 4.17% of daily consumption per hour",
      },
      monthlyWeights: {
        "ui:options": {
          label: false,
          labelPlaceholder: true,
        },
      },
      dailyWeights: {
        "ui:options": {
          label: false,
          labelPlaceholder: true,
        },
      },
      hourlyWeights: {
        "ui:options": {
          label: false,
          labelPlaceholder: true,
        },
      },
      irradiance: {
        "ui:options": {
          labelPlaceholder: true,
          objectLabel: false,
        },
        enabled: {
          "ui:options": {
            label: false,
            description: false,
          },
        },
        monthlyIrradiance: {
          january: {
            "ui:widget": "irradianceWidget",
            "ui:options": {
              days: 31,
            },
          },
          february: {
            "ui:widget": "irradianceWidget",
            "ui:options": {
              days: 28,
            },
          },
          march: {
            "ui:widget": "irradianceWidget",
            "ui:options": {
              days: 31,
            },
          },
          april: {
            "ui:widget": "irradianceWidget",
            "ui:options": {
              days: 30,
            },
          },
          may: {
            "ui:widget": "irradianceWidget",
            "ui:options": {
              days: 31,
            },
          },
          june: {
            "ui:widget": "irradianceWidget",
            "ui:options": {
              days: 30,
            },
          },
          july: {
            "ui:widget": "irradianceWidget",
            "ui:options": {
              days: 31,
            },
          },
          august: {
            "ui:widget": "irradianceWidget",
            "ui:options": {
              days: 31,
            },
          },
          september: {
            "ui:widget": "irradianceWidget",
            "ui:options": {
              days: 30,
            },
          },
          october: {
            "ui:widget": "irradianceWidget",
            "ui:options": {
              days: 31,
            },
          },
          november: {
            "ui:widget": "irradianceWidget",
            "ui:options": {
              days: 30,
            },
          },
          december: {
            "ui:widget": "irradianceWidget",
            "ui:options": {
              days: 31,
            },
          },
        },
      },
      acEnergy: {
        "ui:options": {
          labelPlaceholder: true,
          objectLabel: false,
        },
        enabled: {
          "ui:options": {
            label: false,
            description: false,
          },
        },
      },
    },
  }

  addAutoComplete(uiSchema.devices.items, props.autoCompletes, props.readonly, "manufacturer")
  addAutoComplete(uiSchema.devices.items, props.autoCompletes, props.readonly, "model")

  return uiSchema
}

import React from "react"
import _ from "lodash"

import { Error } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"

import EventService from "../EventService"
import EventUtils from "../EventUtils"
import EventDetails from "./event/EventDetailsView"
import EventListView from "./event/EventListView"
import i18n from "src/i18n"

const { t } = i18n
const EVENT_PRIORITIES = [
  { id: 1, name: t("event.labels.low") },
  { id: 5, name: t("event.labels.medium") },
  { id: 10, name: t("event.labels.high") },
]

class EventView extends EcosuiteView {
  constructor(props) {
    super(props)

    this.state = {
      searchTerm: "",
      priorities: EVENT_PRIORITIES,
      selectedPriorities: EVENT_PRIORITIES.map((priority) => priority.id),
      dateRangeIncludesTime: true,
      showSystemGeneratedOnly: false,
      showDueOnly: false,
      showUnresolvedOnly: false,
    }

    this.selectEvent = this.selectEvent.bind(this)
    this.search = this.search.bind(this)
    this.toggleTypes = this.toggleTypes.bind(this)
    this.toggleType = this.toggleType.bind(this)
    this.toggleSubTypes = this.toggleSubTypes.bind(this)
    this.togglePriority = this.togglePriority.bind(this)
    this.toggleUnresolved = this.toggleUnresolved.bind(this)
    this.toggleDue = this.toggleDue.bind(this)
    this.toggleSystemGenerated = this.toggleSystemGenerated.bind(this)
    this.eventUpdated = this.eventUpdated.bind(this)
    this.deleteEvents = this.deleteEvents.bind(this)
    this.eventDeleted = this.eventDeleted.bind(this)
  }

  isReadOnly() {
    return !this.props.groups.includes("event-write")
  }

  componentDidMount() {
    let params = new URLSearchParams(window.location.search)

    super.componentDidMount()
    if (params.has("eventId")) {
      this.loadEvent(params.get("eventId"))
    }
    if (params.has("new")) {
      this.selectEvent({})
    }

    this.resetEventTypes()
    this.loadEventSchema()
  }

  componentDidUpdate(prevProps) {
    if (
      !_.isEqual(this.props.events, prevProps.events) ||
      !_.isEqual(
        this.props.projects.map((project) => project.code).sort(),
        prevProps.projects.map((project) => project.code).sort(),
      )
    ) {
      this.resetEventTypes()
    }

    // Deselect the viewed event if the selected project changes
    if (!_.isEqual(this.props.project, prevProps.project)) {
      this.resetEventTypes()
      this.selectEvent()
    }
  }

  loadEventSchema() {
    EventService.getEventSchema().then((schema) => {
      this.setStateIfMounted({
        eventSchema: schema,
      })
    })
  }

  loadEvent(eventId) {
    EventService.getEvent(eventId).then((event) => {
      this.selectEvent(event)
    })
  }

  resetEventTypes() {
    const eventTypes = this.props.events
      ?.reduce((types, event) => {
        return event?.type && !types.includes(event.type) ? [...types, event.type] : types
      }, [])
      .sort()

    const eventSubTypes = new Set(this.props.events?.map((event) => event.subType ?? "None"))

    this.setStateIfMounted({
      types: eventTypes,
      subTypes: eventSubTypes,
      selectedTypes: eventTypes,
      selectedSubTypes: eventSubTypes,
    })
  }

  selectEvent(event) {
    this.setStateIfMounted({ event: event })
  }

  eventUpdated(event) {
    this.props.actions.eventUpdated(event)
  }

  /**
   * Delete events.
   * @param events - The events to delete.
   * @returns {Promise<void>}
   */
  async deleteEvents(events) {
    this.setState({ loading: true })

    // Delete all the events.
    await Promise.all(
      events.map(async (event) => {
        try {
          await EventService.deleteEvent(event.id)
        } catch (e) {
          this.setStateIfMounted({ loading: false, error: e })
        }
      }),
    )

    this.eventDeleted() // updates the file list selected file
    this.setStateIfMounted({ loading: false })
  }

  eventDeleted(event) {
    this.props.actions.eventDeleted(event)

    this.selectEvent()
  }

  search(searchTerm) {
    this.setStateIfMounted({ searchTerm: searchTerm })
  }

  toggleTypes() {
    this.setStateIfMounted({
      selectedTypes: this.state.selectedTypes.length ? [] : this.state.types,
    })
  }

  toggleType(type) {
    let selectedTypes = this.state.selectedTypes
    if (selectedTypes.indexOf(type) >= 0) {
      selectedTypes = selectedTypes.filter((eventType) => {
        return eventType !== type
      })
    } else {
      selectedTypes.push(type)
    }
    this.setStateIfMounted({ selectedTypes: selectedTypes })
  }

  /**
   * Update viewable subtypes
   * @param {string[]} subTypes - subtypes to add / remove (empty array removes all values)
   */
  toggleSubTypes(subTypes) {
    if (subTypes.length) {
      const selectedSubTypes = new Set(this.state.selectedSubTypes)

      subTypes.map((type) => {
        if (selectedSubTypes.has(type)) {
          selectedSubTypes.delete(type)
        } else {
          selectedSubTypes.add(type)
        }
      })

      this.setStateIfMounted({ selectedSubTypes: selectedSubTypes })
    } else {
      this.setStateIfMounted({ selectedSubTypes: new Set() })
    }
  }

  togglePriority(priority) {
    let selectedPriorities = this.state.selectedPriorities
    if (selectedPriorities.indexOf(priority) >= 0) {
      selectedPriorities = selectedPriorities.filter((eventPriority) => {
        return eventPriority !== priority
      })
    } else {
      selectedPriorities.push(priority)
    }
    this.setStateIfMounted({ selectedPriorities: selectedPriorities })
  }

  /**
   * Toggle only seeing system generated events.
   */
  toggleSystemGenerated() {
    this.setStateIfMounted({ showSystemGeneratedOnly: !this.state.showSystemGeneratedOnly })
  }

  toggleDue() {
    this.setStateIfMounted({ showDueOnly: !this.state.showDueOnly })
  }

  toggleUnresolved() {
    this.setStateIfMounted({ showUnresolvedOnly: !this.state.showUnresolvedOnly })
  }

  getEvents() {
    var events = this.props.events
    if (events) {
      if (this.props.projects) {
        // Filter by multiple projects.
        const projectCodes = this.props.projects.map((project) => project.code)
        events = events.filter((event) => projectCodes.includes(event.location.project))
      } else {
        // Filter by a single project.
        events = events.filter((event) => this.props.project.code === event.location.project)
      }

      // Filter using the search term if there is one
      if (this.state.searchTerm) {
        events = events.filter((event) => {
          return (
            event.cause.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
            event.description.toLowerCase().includes(this.state.searchTerm.toLowerCase()) ||
            event.path.toLowerCase().startsWith(this.state.searchTerm.toLowerCase()) ||
            (event.notes &&
              event.notes.find((note) => note.note.toLowerCase().includes(this.state.searchTerm.toLowerCase())))
          )
        })
      }

      // Filter down to the selected document types
      if (this.state.selectedTypes) {
        events = events.filter((event) => {
          return this.state.selectedTypes.indexOf(event.type) >= 0
        })
      }

      // Filter down by the event sub types
      if (this.state.selectedSubTypes) {
        events = events.filter((event) => {
          return this.state.selectedSubTypes.has(event.subType ?? "None")
        })
      }

      // Filter down using priority
      events = events.filter((event) => {
        return (
          this.state.selectedPriorities.indexOf(event.priority) >= 0 ||
          (!event.priority && this.state.selectedPriorities.indexOf(1) >= 0)
        )
      })

      // Filter down to system generated events.
      if (this.state.showSystemGeneratedOnly) {
        events = events.filter((event) => {
          return event.userName === "System Generated"
        })
      }

      // Filter down using due
      if (this.state.showDueOnly) {
        events = events.filter((event) => {
          return EventUtils.isEventDue(event)
        })
      }

      // Filter down using unresolved
      if (this.state.showUnresolvedOnly) {
        events = events.filter((event) => {
          return !event.endDate
        })
      }

      return events
    }
    return undefined
  }

  renderMainView() {
    if (this.state.event) {
      return this.renderEvent()
    } else {
      return this.renderEvents()
    }
  }

  renderEvent() {
    return (
      <EventDetails
        groups={this.props.groups}
        event={this.state.event}
        eventSchema={this.state.eventSchema}
        serviceRequests={this.props.serviceRequests}
        projects={this.props.projects}
        project={this.props.project}
        devices={this.props.devices}
        range={this.props.range}
        actions={{
          changeView: this.props.actions.changeView,
          serviceRequestUpdated: this.props.actions.serviceRequestUpdated,
          eventUpdated: this.eventUpdated,
          deleteEvents: this.deleteEvents,
          eventDeleted: this.eventDeleted,
          selectView: this.selectView,
          selectEvent: this.selectEvent,
        }}
      />
    )
  }

  renderEvents() {
    if (this.isContentError(this.props.events)) {
      return <Error error={this.props.events.getError()} />
    }
    return (
      <div className="content-view">
        <EventListView
          groups={this.props.groups}
          project={this.props.project}
          projects={this.props.projects}
          events={this.getEvents()}
          eventSchema={this.state.eventSchema}
          event={this.state.event}
          searchTerm={this.state.searchTerm}
          types={this.state.types}
          selectedTypes={this.state.selectedTypes}
          eventSubTypes={Array.from(this.state.subTypes ?? "")}
          selectedEventSubTypes={Array.from(this.state.selectedSubTypes ?? "")}
          priorities={this.state.priorities}
          selectedPriorities={this.state.selectedPriorities}
          filters={{
            showSystemGeneratedOnly: this.state.showSystemGeneratedOnly,
            showDueOnly: this.state.showDueOnly,
            showUnresolvedOnly: this.state.showUnresolvedOnly,
          }}
          actions={{
            deleteEvents: this.deleteEvents,
            eventUpdated: this.eventUpdated,
            selectView: this.selectView,
            selectRange: this.selectRange,
            selectProject: this.selectProject,
            selectEvent: this.selectEvent,
            search: this.search,
            toggleTypes: this.toggleTypes,
            toggleType: this.toggleType,
            toggleSubTypes: this.toggleSubTypes,
            togglePriority: this.togglePriority,
            toggleUnresolved: this.toggleUnresolved,
            toggleSystemGenerated: this.toggleSystemGenerated,
            toggleDue: this.toggleDue,
          }}
        />
      </div>
    )
  }
}

export default EventView

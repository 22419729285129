import React from "react"

import Moment from "moment"
import { extendMoment } from "moment-range"

import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import PowerUserHeader from "./PowerUserHeader"
import PowerUserContent from "./PowerUserContent"

import DateRangeUtils from "@common/utils/DateRangeUtils"
import Settings from "@common/Settings"
import PowerUserAggregations from "./PowerUserAggregations"

import "./PowerUserUI.css"

const moment = extendMoment(Moment)

class PowerUserUI extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state.aggregation = this.props.aggregation ? this.props.aggregation : PowerUserAggregations.Day
    this.state.range = this.getDefaultRange()

    this.selectRange = this.selectRange.bind(this)
    this.selectProjects = this.selectProjects.bind(this)
    this.selectSourceIds = this.selectSourceIds.bind(this)
    this.toggleFilterByProperties = this.toggleFilterByProperties.bind(this)
    this.state.filterByProperties = false
  }

  toggleFilterByProperties() {
    this.setStateIfMounted({ filterByProperties: !this.state.filterByProperties })
  }

  getDefaultRange() {
    // We load the last range that has been selected in AMS by default
    const rangeName = Settings.getSetting("rangeName", "last7Days")
    const start = moment(Settings.getSetting("start", moment().startOf("day")))
    const end = moment(Settings.getSetting("end", moment().endOf("day")))
    const customRange =
      start && end
        ? moment.range(start, end)
        : moment.range(
            moment(Settings.getSetting("start", moment().startOf("day"))),
            moment(Settings.getSetting("end", moment().endOf("day"))),
          )

    return DateRangeUtils.getRange(rangeName, customRange, this.props.projects)
  }

  selectRange(range, aggregation) {
    this.setStateIfMounted({ range: range, aggregation: aggregation })
  }

  selectProjects(selectedProjectIds) {
    this.setStateIfMounted({ selectedProjectIds: selectedProjectIds })
  }

  /**
   * Save the sourceIds that have been selected in the tree. Used to filter the download.
   * @param {Array.<string>} sourceIds The sourceIds that have been selected in the tree
   * @returns {void}
   */
  selectSourceIds(sourceIds) {
    this.setStateIfMounted({ selectedSourceIds: sourceIds })
  }

  getSelectedProjects() {
    if (this.state.selectedProjectIds) {
      return this.props.projects.filter((project) => this.state.selectedProjectIds.indexOf(project.code) >= 0)
    }
    return []
  }

  renderContent() {
    let projects = this.props.projects
    if (!projects) {
      return <Loading />
    }

    return (
      <div className="PowerUserDashboard">
        <PowerUserHeader
          restrictions={this.props.restrictions}
          projects={projects}
          selectedProjects={this.getSelectedProjects()}
          sourceIds={this.state.selectedSourceIds}
          range={this.state.range}
          aggregation={this.state.aggregation}
          filterByProperties={this.state.filterByProperties}
          actions={{ selectRange: this.selectRange, toggleFilterByProperties: this.toggleFilterByProperties }}
        />
        <PowerUserContent
          projects={projects}
          filterByProperties={this.state.filterByProperties}
          range={this.state.range}
          aggregation={this.state.aggregation}
          actions={{ selectProjects: this.selectProjects, selectSourceIds: this.selectSourceIds }}
        />
      </div>
    )
  }
}

export default PowerUserUI

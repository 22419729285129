import React from "react"
import { Input, Label, Button } from "reactstrap"
import Datetime from "react-datetime"
import Moment from "moment"
// eslint-disable-next-line no-unused-vars
import { extendMoment, DateRange } from "moment-range"

import EcosuiteComponent from "@common/EcosuiteComponent"

import DownloadModal from "@dashboard/energy/DownloadModal"
import PowerUserAggregations from "./PowerUserAggregations"

import "react-datetime/css/react-datetime.css"
import "./PowerUserUI.css"
import i18n from "src/i18n"

const { t } = i18n
const moment = extendMoment(Moment)

class PowerUserHeader extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.handleChangeStart = this.handleChangeStart.bind(this)
    this.handleChangeEnd = this.handleChangeEnd.bind(this)
    this.handleChangeAggregation = this.handleChangeAggregation.bind(this)
    this.selectRange = this.selectRange.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()

    if (this.props.range) {
      this.setStateIfMounted({ start: this.props.range.start, end: this.props.range.end })
    }
    this.setState({ aggregation: this.props.aggregation })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.range || !this.props.range.isSame(prevProps.range)) {
      this.setState({ start: this.props.range.start, end: this.props.range.end })
    }
    if (this.props.aggregation !== prevProps.aggregation) {
      this.setState({ aggregation: this.props.aggregation })
    }
    return super.componentDidUpdate()
  }

  selectRange() {
    let range = this.getRange()
    if (range) {
      this.props.actions.selectRange(range, this.state.aggregation)
    }
  }

  /**
   * Gets the currently selected range if it is valid. If not then the reason for the invalid range is stored in the "error" state.
   *
   * @returns {DateRange} a string describing all of the errors in the date, or an empty string if there are no errors
   */
  getRange() {
    // if the user enters an invalid date, the date control gives us the string the user entered, instead of a date
    if (typeof this.state.start === "string") {
      this.setState({ error: `${t("errors.invalid_start_date")}` })
    } else if (typeof this.state.end === "string") {
      this.setState({ error: `${t("errors.invalid_end_date")}` })
    } else {
      let range = moment.range(this.state.start, this.state.end)
      if (range.end.isBefore(range.start)) {
        this.setState({ error: `${t("errors.wrong_end_date")}` })
      } else {
        return range
      }
    }
  }

  /** @param {Moment.Moment} startDate */
  handleChangeStart(startDate) {
    this.setState({ start: startDate })
  }

  /** @param {Moment.Moment} endDate */
  handleChangeEnd(endDate) {
    this.setState({ end: endDate })
  }

  handleChangeAggregation(aggregation) {
    this.setState({ aggregation: aggregation })
  }

  render() {
    return (
      <div className="power-user-header">
        <div className="power-user-header-controls-left">
          <Button active={this.props.filterByProperties} onClick={() => this.props.actions.toggleFilterByProperties()}>
            Filter by Property
          </Button>
        </div>
        <div className="power-user-header-controls">
          <Label className="error-label">{this.state.error}</Label>
          <Datetime value={this.state.start} dateFormat="ll" timeFormat="HH:mm:ss" onChange={this.handleChangeStart} />
          <Datetime value={this.state.end} dateFormat="ll" timeFormat="HH:mm:ss" onChange={this.handleChangeEnd} />

          <Input
            className="aggregate"
            type="select"
            value={this.state.aggregation}
            onChange={(e) => {
              this.handleChangeAggregation(e.target.value)
            }}
          >
            {Object.keys(PowerUserAggregations).map((value) => (
              <option value={PowerUserAggregations[value]} key={value}>
                {value}
              </option>
            ))}
          </Input>

          <Button onClick={this.selectRange}>{t("buttons.update")}</Button>

          {this.props.restrictions.preventDownload === "yes" ? null : (
            <DownloadModal
              {...this.props}
              aggregate={this.state.aggregation}
              hideRange
              hideAggregate
              rangeName="custom"
              customRange={this.props.range}
              projects={this.props.selectedProjects}
              className="poweruser-download-modal"
            />
          )}
        </div>
      </div>
    )
  }
}

export default PowerUserHeader

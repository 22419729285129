import React from "react"
import { Alert, FormGroup, Input, Label } from "reactstrap"
import EcosuiteComponent, { Loading } from "@common/EcosuiteComponent"
import UserAdminService from "./UserAdminService"
import Logger from "@common/Logger"
import Icon from "@common/display/Icon"
import { SECURE_GROUPS } from "src/App"
import { isEqual } from "lodash"
import i18n from "src/i18n"

const { t } = i18n

// Define the static permissionsHashmap
const permissionsHashmap = {
  connectivity: "Data: Connectivity",
  consumption: "Product: Consumption",
  contacts: "Data: Contacts",
  "data-analysis": "Provides access to Data Analysis tools such as Jupyter",
  "data-write": "Enables write permissions in the Data module",
  demandresponse: "Data: Demand Response",
  energy: "Data: Energy",
  event: "Data: Event",
  "event-write": "Enables writes permissions for Events",
  finance: "Data: Finance",
  generation: "Product: Generation",
  legal: "Data: Data",
  notes: "Data: Notes",
  "power-user": "Enables access to the power user functionality including the Power User Dashboard",
  processes: "Data: Processes",
  reseller: "Enables access to Ecogy Reseller features including switching to Econode mode",
  solarnetwork: "Enables direct access to SolarNetwork including SSH and Web UI",
  storage: "Product: storage",
  "user-admin": "Enables access to User Admin",
  "user-limited-admin": "Enables permission to invite a new user to ecosuite software.",
}

class UserGroups extends EcosuiteComponent {
  constructor(props) {
    super(props)
    this.state = {}
    this.toggleGroup = this.toggleGroup.bind(this)
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadUserGroups()
    this.formatUserGroups()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (this.props.user !== prevProps.user || this.props.forceUpdate !== prevProps.forceUpdate) {
      this.loadUserGroups()
      this.formatUserGroups()
    }
  }

  loadUserGroups() {
    this.setState({ error: undefined, userGroups: undefined, message: false, success: false })
    UserAdminService.getUserGroups(this.props.user)
      .then((response) => {
        this.setConflicting(response.groups)
        this.setStateIfMounted({ userGroups: response.groups })
      })
      .catch((err) => {
        Logger.error(err)
        this.setStateIfMounted({ error: err, success: false })
      })
  }

  formatUserGroups() {
    // Use the static permissionsHashmap instead of fetching schema
    const categoryOrder = ["data", "product", "enables access to"] // Define the desired category order
    const groupEntries = Object.entries(permissionsHashmap)

    let groups = {}
    groupEntries.forEach(([groupId, description]) => {
      const [groupType, groupDescription] = description.split(": ")
      if (groupDescription) {
        groups[groupType] = groups[groupType] ?? []
        groups[groupType].push({ description: groupDescription, id: groupId })
      } else {
        groups["user-admin"] = groups["user-admin"] ?? []
        groups["user-admin"].push({ description: groupType, id: groupId })
      }
    })

    // Sort the groups based on categoryOrder
    const sortedGroups = categoryOrder
      .filter((category) => groups[category])
      .map((category) => [category, groups[category]])

    // Append any remaining categories not specified in categoryOrder
    const remainingCategories = Object.keys(groups)
      .filter((category) => !categoryOrder.includes(category))
      .sort()
      .map((category) => [category, groups[category]])

    this.setState({ formattedUserGroups: [...sortedGroups, ...remainingCategories] })
  }

  toggleGroup(event) {
    this.setState({ error: undefined })

    let groupId = event.target.value
    if (event.target.checked) {
      let userGroups = this.state.userGroups || []
      userGroups.push(
        this.props.groups.find((userGroup) => {
          return userGroup.id === groupId
        }),
      )
      this.setState({
        message: `${t("settings.messages.adding_user_to_group")}: ${groupId}`,
        success: undefined,
        userGroups: userGroups,
      })

      this.setConflicting(userGroups)
      this.addGroupToCognitoUser(groupId)
    } else {
      let userGroups = this.state.userGroups.filter((userGroup) => {
        return userGroup.id !== groupId
      })
      this.setState({
        message: `${t("settings.messages.removing_user_to_group")}: ${groupId}`,
        success: undefined,
        userGroups: userGroups,
      })

      this.setConflicting(userGroups)
      this.removeGroupFromCognitoUser(groupId)
    }

    // event.preventDefault();
  }

  isGroupSelected(group) {
    let userGroup = this.state.userGroups.find((userGroup) => {
      return userGroup.id === group.id
    })
    return userGroup !== undefined
  }

  /**
   * Checks if the userGroups for the selected User are the same as their user type's defaults
   */
  setConflicting(responseGroups) {
    // Ensure that this prop function exists before trying to execute it
    if (typeof this.props.setConflicting !== "function") {
      return
    }

    if (!isEqual(responseGroups.map((group) => group.id).sort(), [...(this.props.defaultGroups || [])].sort())) {
      this.props.setConflicting(true)
    } else {
      this.props.setConflicting(false)
    }
  }

  renderUserGroupAlerts() {
    const error = this.getError()

    if (this.state.message) {
      return <Alert color="info">{this.state.message}</Alert>
    } else if (this.state.success) {
      return <Alert color="success">{this.state.success}</Alert>
    } else if (error) {
      return <Alert color="danger">{error}</Alert>
    } else if (this.props.typeofNewUser) {
      return (
        <Alert color="info" className="info">
          <Icon icon="info" />
          {this.props.typeofNewUser
            ? `${t("settings.messages.new_user_default_permissions")}`
            : `${t("settings.messages.user_default_permissions")}`}
        </Alert>
      )
    }
  }

  removeGroupFromCognitoUser(groupId) {
    UserAdminService.removeUserFromGroup(this.props.user, groupId)
      .then(() => {
        this.setState({
          success: `${t("settings.messages.user_removed_from_group")}: ${groupId}`,
          message: undefined,
        })
      })
      .catch((err) => {
        Logger.error(err)
        this.setState({ error: err, success: false })
        this.loadUserGroups()
      })
  }

  addGroupToCognitoUser(groupId) {
    UserAdminService.addUserToGroup(this.props.user, groupId)
      .then(() => {
        this.setState({
          success: `${t("settings.messages.user_added_to_group")}: ${groupId}`,
          message: undefined,
        })
      })
      .catch((err) => {
        Logger.error(err)
        this.setState({ error: err, success: false })
        this.loadUserGroups()
      })
  }

  disableCategory(category) {
    const groupsToRemove = category.map((group) => {
      return this.state.userGroups.find((value) => value.id === group.id)
    })
    groupsToRemove.forEach((group) => {
      this.removeGroupFromCognitoUser(group.id)
    })
    const filteredActiveGroups = this.state.userGroups.filter((el) => !groupsToRemove.includes(el))
    this.setState({ userGroups: filteredActiveGroups })
  }

  enableCategory(category) {
    const groupsToAdd = category.filter((group) => {
      return !this.state.userGroups.find((value) => value.id === group.id)
    })
    groupsToAdd.forEach((group) => {
      this.addGroupToCognitoUser(group.id)
    })
    this.setState({ userGroups: [...this.state.userGroups, ...groupsToAdd] })
  }

  renderGroups(category, groups) {
    function isCategoryComplete(category, selectedGroups) {
      const groupIds = selectedGroups.map((group) => {
        return group.id
      })
      return category.every((group) => {
        return groupIds.includes(group.id)
      })
    }

    return (
      <div key={category}>
        <div>
          <h4 style={{ display: "inline", textTransform: "capitalize" }}>{category}</h4>
          <input
            style={{ display: "inline", marginLeft: "8px" }}
            type={"checkbox"}
            checked={isCategoryComplete(groups, this.state.userGroups)}
            onChange={() => {
              if (isCategoryComplete(groups, this.state.userGroups)) {
                this.disableCategory(groups)
              } else {
                this.enableCategory(groups)
              }
            }}
          />
        </div>
        <table className="user-groups">
          <tbody>
            {groups.map((group) => {
              return (
                <tr key={group.id}>
                  <td className="user-group-name">
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          value={group.id}
                          onChange={this.toggleGroup}
                          checked={this.isGroupSelected(group)}
                        />{" "}
                        {group.id}
                      </Label>
                    </FormGroup>
                  </td>
                  <td>{`${group.description}${
                    SECURE_GROUPS.includes(group.id) ? `${t("settings.messages.will_require_MFA")}` : ""
                  }`}</td>
                </tr>
              )
            })}
          </tbody>
        </table>
      </div>
    )
  }

  renderContent() {
    if (this.props.groups && this.state.userGroups) {
      return (
        <div className="ecogy-form admin-tab-content-area">
          {!this.props.typeofNewUser && (
            <Alert color="info" className="info">
              <Icon icon="info" />
              {t("settings.messages.user_permissions_changes_not_reflected")}
            </Alert>
          )}
          {this.renderUserGroupAlerts()}
          {this.state.formattedUserGroups?.map(([category, groups]) => this.renderGroups(category, groups))}
        </div>
      )
    } else {
      return <Loading />
    }
  }

  getError() {
    if (this.state && this.state.error) {
      return this.state.error.message ? this.state.error.message : this.state.error
    }
    return null
  }
}

export default UserGroups

import React, { Component } from "react"
import { Alert } from "reactstrap"
import Form from "@rjsf/core"

import {
  EcosuiteArrayFieldTemplate,
  FixedArrayFieldTemplate,
  NotesArrayFieldTemplate,
  TitledArrayFieldTemplate,
} from "./ArrayFieldTemplates"
import EcosuiteFieldTemplate from "./EcosuiteFieldTemplate"

import "./EcosuiteForm.css"
import i18n from "src/i18n"

const { t } = i18n
var jsprim = require("jsprim")

var getDisplayName = function (propertyName) {
  return propertyName.replace(/([A-Z])/g, " $1").trim()
}

const ERROR_MESSAGE_CAP = 10

class EcosuiteForm extends Form {
  constructor(props) {
    super(props)
    this.transformErrors = this.transformErrors.bind(this)
  }

  componentDidMount() {
    if (this.props.formMounted) {
      this.props.formMounted(this)
    }
  }

  /** returns the first occurence of the named property in the schema, either in the main properties or in the properties of the dependencies */
  getSchemaProperty(schema, propertyName, formData) {
    const schemaProperty = schema.properties ? schema.properties[propertyName] : schema.items.properties
    if (schemaProperty) {
      return schemaProperty
    }
    if (schema.dependencies) {
      for (var dependencyKey of Object.keys(schema.dependencies)) {
        let dependency = schema.dependencies[dependencyKey]
        for (var oneOf of dependency.oneOf) {
          if (oneOf.properties[dependencyKey].enum.includes(formData[dependencyKey])) {
            if (oneOf.properties[propertyName]) {
              return oneOf.properties[propertyName]
            }
          }
        }
      }
    }
  }

  transformErrors(errors, formData) {
    return errors.map((error) => {
      if (error.property) {
        const propertyIdx = error.property.indexOf(".") + 1
        const property = error.property.substring(propertyIdx)
        const schemaProperty = this.getSchemaProperty(this.state.schema, property, formData)
        const title = schemaProperty ? schemaProperty.title : property
        error.stack = title + " " + error.message
      }
      return error
    })
  }

  validate(formData, schema = this.props.schema) {
    let validationResults = super.validate(formData, schema)

    // Note: attempting to modify the original validation results breaks the dependent property validation
    if (!this.props.transformErrors) {
      validationResults = jsprim.deepCopy(validationResults)
      validationResults.errors = this.transformErrors(validationResults.errors, formData)
    }
    return validationResults
  }

  getPropertyValueFromPath(obj, path) {
    path = path.replace("[", ".").replace("]", "") // handle the value being in an array
    for (var i = 0, properties = path.split("."), len = properties.length; i < len; i++) {
      if (properties[i]) {
        obj = obj[properties[i]]
      }
    }
    return obj
  }

  setPropertyValueFromPath(obj, path, value) {
    path = path.replace("[", ".").replace("]", "") // handle the value being in an array
    for (var i = 0, properties = path.split("."), len = properties.length; i < len; i++) {
      if (i === len - 1) {
        obj[properties[i]] = value // update the item
      } else if (properties[i] && i < len - 1) {
        obj = obj[properties[i]] // drill down to the next property
      }
    }
  }
}

function EcosuiteObjectFieldTemplate(props) {
  const uiOptions = props.uiSchema["ui:options"] ? props.uiSchema["ui:options"] : null
  const enableTitle = uiOptions ? uiOptions.objectLabel !== false : false
  const enableDescription = uiOptions ? uiOptions.objectDescription !== false : false

  return (
    <div>
      {props.title && enableTitle ? props.TitleField(props) : null}
      {props.description && (!props.uiSchema || !props.uiSchema["ui:link"]) && enableDescription
        ? props.DescriptionField(props)
        : null}
      {props.uiSchema && props.uiSchema["ui:link"] ? (
        <p>
          {props.description}
          <a href={props.uiSchema["ui:link"]} target="_blank" rel="noreferrer noopener">
            {props.uiSchema["ui:link"]}
          </a>
        </p>
      ) : null}
      {props.properties.map((element, idx) => (
        <div key={idx} className="property-wrapper">
          {element.content}
        </div>
      ))}
    </div>
  )
}

class FormError extends Component {
  getFormErrors(error) {
    if (error) {
      return (
        <div className="error-messages">
          <Alert color="danger" toggle={this.props.toggle}>
            {error.message ? error.message : typeof error === "object" ? JSON.stringify(error) : error}
            {this.getNestedValidationErrors(error.errors)}
          </Alert>
        </div>
      )
    }
    return null
  }

  getNestedValidationErrors(errors) {
    if (errors) {
      return (
        <ul>
          {errors.map((error, idx) => {
            if (idx < ERROR_MESSAGE_CAP) {
              return <li key={idx}>{error.instancePath ? `${error.instancePath}: ${error.message}` : error.message}</li>
            } else if (idx === ERROR_MESSAGE_CAP && errors.length > ERROR_MESSAGE_CAP) {
              return (
                <li
                  key={idx}
                >{`${t("messages.error_quantity", { errorsLength: errors.length - ERROR_MESSAGE_CAP })}`}</li>
              )
            } else {
              return null
            }
          })}
        </ul>
      )
    }
    return null
  }

  render() {
    return this.getFormErrors(this.props.error)
  }
}

export {
  FormError,
  EcosuiteObjectFieldTemplate,
  EcosuiteFieldTemplate,
  EcosuiteArrayFieldTemplate,
  FixedArrayFieldTemplate,
  NotesArrayFieldTemplate,
  TitledArrayFieldTemplate,
  getDisplayName,
}
export default EcosuiteForm

// @ts-nocheck
import CreateContact from "@admin/Contacts/CreateContact"
import ConfirmDialog from "@common/display/ConfirmModal/ConfirmModal"
import Icon from "@common/display/Icon"
import Table from "@common/display/Table"
import WithToolTip from "@common/display/ToolTip"
import NiceModal from "@ebay/nice-modal-react"
import { t } from "i18next"
import { omit, result, update } from "lodash"
import * as React from "react"
import CreatableSelect from "react-select/creatable"
import { Button } from "reactstrap"
import { Contact, useContacts } from "src/services/contacts"

const CreatableSelectContacts = ({ handleUpdate }: { handleUpdate: (v: Contact) => void }) => {
  const [isLoading, setIsLoading] = React.useState(false)
  const [options, setOptions] = React.useState<any>([])
  const [value, setValue] = React.useState<any | null>()
  const { data: contactOptions, isLoading: isLoadingContacts, isError } = useContacts()

  React.useEffect(() => {
    if (contactOptions) {
      setOptions(contactOptions.map((el) => ({ ...el, value: el.id, label: el.name })))
    }
  }, [contactOptions])

  const handleCreate = (inputValue: string) => {
    NiceModal.show(ConfirmDialog, {
      size: "md",
      title: "Create new contact",
      message: (
        <CreateContact
          id={undefined}
          noNavigate
          afterSave={(value) => {
            const rselectValue = { ...value, value: value.id, label: value.name }
            setOptions([...options, rselectValue])
            setValue(rselectValue)
            handleUpdate(omit(rselectValue, ["value, label"]) as Contact)
            NiceModal.hide(ConfirmDialog)
          }}
        />
      ),
    })
  }

  return (
    <CreatableSelect
      styles={{
        // @ts-ignore
        option: (provided, state) => ({
          ...provided,
          backgroundColor: state.isSelected ? "lightblue" : "white",
          color: "black",
          cursor: "pointer",
        }),
        menu: (base) => ({ ...base, zIndex: 9999 }),
      }}
      formatCreateLabel={() => <div style={{ color: "black", fontWeight: 600 }}>Create new contact</div>}
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={(newValue) => {
        handleUpdate(omit(newValue, ["value, label"]) as Contact)
        setValue(newValue)
      }}
      onCreateOption={handleCreate}
      options={options}
      value={value}
    />
  )
}

function SiteDetailsContacts({
  handleContactsUpdate,
  form,
}: {
  handleContactsUpdate: (updatedContactIds: Array<string>) => void
  form: any
}) {
  const [contact, setContact] = React.useState<Contact | null>(null)
  const [contactIds, setContactIds] = React.useState<Array<string>>(form.contactIds || [])

  const { data: contactOptions, isLoading: isLoadingContacts, isError } = useContacts()

  const handleAdd = (newContact: Contact) => {
    if (!contactIds.find((id) => id === newContact.id)) {
      setContactIds([newContact.id, ...contactIds])
    }
  }

  const createTableData = (allContacts: Array<Contact>, contactIds: Array<string>) => {
    const allContactsById = allContacts.reduce(
      (acc, cur) => {
        acc[cur.id as keyof typeof acc] = cur
        return acc
      },
      {} as Record<string, Contact>,
    )

    return contactIds.map((id) => allContactsById[id]).filter(Boolean)
  }

  return (
    <div
      onBlur={() => {
        handleContactsUpdate(contactIds)
      }}
      tabIndex={0}
    >
      Add new contact:
      <div style={{ display: "flex" }}>
        <div style={{ width: "90%", marginBottom: "8px" }}>
          <CreatableSelectContacts handleUpdate={(v) => setContact(v)} />
        </div>
        <div style={{ width: "10%", marginLeft: "16px" }}>
          <Button
            color="primary"
            onClick={() => {
              if (contact) {
                handleAdd(contact)
              }
            }}
          >
            Add
          </Button>
        </div>
      </div>
      {contactIds.length > 0 ? (
        <div>
          Existing Contacts:
          <Table
            defaultColumn={{ accessorKey: "id" }}
            data={createTableData(contactOptions || [], contactIds)}
            initialState={{
              sorting: [{ id: "name", desc: false }],
            }}
            columns={[
              {
                id: "name",
                accessorKey: "name",
                header: () => <span>{t("table_headings.name")}</span>,
                cell: ({ row, getValue }) => <Table.DefaultRowExpander row={row} getValue={getValue} />,
                size: 150,
              },
              {
                accessorFn: (row) => row.type,
                id: "type",
                header: "type",
                size: 80,
              },
              {
                id: "email",
                accessorKey: "email",
                header: "email",
                size: 150,
              },
              { id: "phone", accessorKey: "phone", header: "phone", size: 150 },
              {
                id: "voip",
                accessorKey: "voip",
                header: "voip",
                size: 150,
              },
              {
                id: "notes",
                accessorKey: "notes",
                header: "notes",
                size: 300,
              },
              {
                id: "actions",
                header: "Actions",
                size: 50,
                cell: ({ getValue }) => (
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ cursor: "pointer", marginLeft: "16px" }}>
                      <WithToolTip tooltip="Delete">
                        <Icon
                          icon="delete"
                          className="date-warning"
                          onClick={() => {
                            const id = getValue()
                            const updatedContactIds = contactIds.filter((c) => c !== id)
                            setContactIds(updatedContactIds)
                          }}
                        />
                      </WithToolTip>
                    </div>
                  </div>
                ),
              },
            ]}
          />
        </div>
      ) : null}
    </div>
  )
}

export default SiteDetailsContacts

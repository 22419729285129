import { create, all } from "mathjs"
import SourceUtils from "src/poweruser/SourceUtils"
import moment from "moment"
import { produce } from "immer"
const math = create(all)

var DatumUtils = {
  /**
   * Groups data based on the supplied assets
   * @param {*} assets An array of assets that the data in the assetMap is to be grouped by using the each assets code.
   * @param {*} assetMap The map of data to be grouped, keyed by asset code.
   */
  groupDatums(assets, assetMap) {
    if (assets) {
      let datums = assets.reduce((datums, asset) => {
        let assetCode = asset.code
        let assetEntry = assetMap[assetCode]
        if (assetEntry && assetEntry.aggregatedTotals) {
          Object.keys(assetEntry.aggregatedTotals).forEach((dateKey) => {
            let datum = assetEntry.aggregatedTotals[dateKey]

            // Create new date entry if needed
            if (!datums[dateKey]) {
              // Create an object to store the datums for all assets, this will be added to for the date for each asset
              let assetDatums = {}
              assetDatums[assetCode] = {
                generation: datum.generation,
                forecastGeneration: datum.forecastGeneration,
                forecast12Generation: datum.forecast12Generation,
                forecast24Generation: datum.forecast24Generation,
                forecast48Generation: datum.forecast48Generation,
                normalisedGeneration: datum.normalisedGeneration,
                consumption: datum.consumption,
                forecastConsumption: datum.forecastConsumption,
                export: datum.export,
                generationReading: datum.generationReading,
                consumptionReading: datum.consumptionReading,
                storage: datum.storage,
                storageReading: datum.storageReading,
              }

              // Set up the datum for the date
              datums[dateKey] = {
                generation: datum.generation,
                forecastGeneration: datum.forecastGeneration,
                forecast12Generation: datum.forecast12Generation,
                forecast24Generation: datum.forecast24Generation,
                forecast48Generation: datum.forecast48Generation,
                consumption: datum.consumption,
                forecastConsumption: datum.forecastConsumption,
                export: datum.export,
                generationReading: datum.generationReading,
                consumptionReading: datum.consumptionReading,
                storage: datum.storage,
                storageReading: datum.storageReading,
                max: {
                  generation: {
                    asset: assetDatums.code,
                    watts: datum.generation,
                  },
                  consumption: {
                    asset: assetDatums.code,
                    watts: datum.consumption,
                  },
                },
                assetDatums: assetDatums,
              }
            } else {
              datums[dateKey].generation += datum.generation
              datums[dateKey].forecastGeneration += datum.forecastGeneration
              datums[dateKey].forecast12Generation += datum.forecast12Generation
              datums[dateKey].forecast24Generation += datum.forecast24Generation
              datums[dateKey].forecast48Generation += datum.forecast48Generation
              datums[dateKey].consumption += datum.consumption
              datums[dateKey].forecastConsumption += datum.forecastConsumption
              datums[dateKey].export += datum.export
              datums[dateKey].generationReading += datum.generationReading
              datums[dateKey].consumptionReading += datum.consumptionReading
              datums[dateKey].storage += datum.storage
              datums[dateKey].storageReading += datum.storageReading

              if (datum.generation > datums[dateKey].max.generation.watts) {
                datums[dateKey].max.generation = {
                  asset: assetEntry.code,
                  watts: datum.generation,
                }
              }
              if (datum.consumption > datums[dateKey].max.consumption.watts) {
                datums[dateKey].max.consumption = {
                  asset: assetEntry.code,
                  watts: datum.consumption,
                }
              }

              // Add a record for this asset to the datums
              datums[dateKey].assetDatums[assetCode] = {
                generation: datum.generation,
                forecastGeneration: datum.forecastGeneration,
                forecast12Generation: datum.forecast12Generation,
                forecast24Generation: datum.forecast24Generation,
                forecast48Generation: datum.forecast48Generation,
                normalisedGeneration: datum.normalisedGeneration,
                consumption: datum.consumption,
                forecastConsumption: datum.forecastConsumption,
                export: datum.export,
                generationReading: datum.generationReading,
                consumptionReading: datum.consumptionReading,
                storage: datum.storage,
              }
            }
          })
        }

        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function (key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  },

  groupConsumptionCostDatums(assets, assetMap) {
    if (assets) {
      let datums = assets.reduce((datums, project) => {
        let code = project.code
        let asset = assetMap[code]
        if (asset && asset.consumptionCost.aggregatedTotals) {
          Object.keys(asset.consumptionCost.aggregatedTotals).forEach((dateKey) => {
            let datum = asset.consumptionCost.aggregatedTotals[dateKey]

            // Create new date entry if needed
            if (!datums[dateKey]) {
              // Create an object to store the datums for all assets, this will be added to for the date for each asset
              let assetDatums = {}
              assetDatums[code] = {
                meteredCost: datum.meteredCost,
                demandCharge: datum.demandCharge,
                peakDemandCharge: datum.peakDemandCharge,
                fixedCost: datum.fixedCost,
                demandCost: datum.demandCost,
              }

              // Set up the datum for the date
              datums[dateKey] = {
                meteredCost: datum.meteredCost,
                demandCharge: datum.demandCharge,
                peakDemandCharge: datum.peakDemandCharge,
                fixedCost: datum.fixedCost,
                demandCost: datum.demandCost,
                assetDatums: assetDatums,
              }
            } else {
              datums[dateKey].meteredCost = math.add(datums[dateKey].meteredCost, datum.meteredCost)
              datums[dateKey].demandCharge = math.add(datums[dateKey].demandCharge, datum.demandCharge)
              datums[dateKey].peakDemandCharge =
                datums[dateKey].peakDemandCharge || datum.peakDemandCharge
                  ? math.add(
                      datums[dateKey].peakDemandCharge ? datums[dateKey].peakDemandCharge : 0,
                      datum.peakDemandCharge ? datum.peakDemandCharge : 0,
                    )
                  : null
              datums[dateKey].fixedCost = math.add(datums[dateKey].fixedCost, datum.fixedCost)

              // Add a record for this asset to the datums
              datums[dateKey].assetDatums[code] = {
                meteredCost: datum.meteredCost,
                demandCharge: datum.demandCharge,
                peakDemandCharge: datum.peakDemandCharge,
                demandCost: datum.demandCost,
                fixedCost: datum.fixedCost,
              }
            }
          })
        }

        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function (key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  },

  groupPredictedConsumptionDatums(assets, assetMap) {
    if (assets) {
      let datums = assets.reduce((datums, project) => {
        let code = project.code
        let asset = assetMap[code]
        if (asset && asset.aggregatedTotals) {
          Object.keys(asset.aggregatedTotals).forEach((dateKey) => {
            let datum = asset.aggregatedTotals[dateKey]

            // Create new date entry if needed
            if (!datums[dateKey]) {
              // Create an object to store the datums for all assets, this will be added to for the date for each asset
              let assetDatums = {}
              assetDatums[code] = {
                predictedConsumption: datum.predictedConsumption,
                targetConsumption: datum.targetForecast,
                historicConsumption: datum.historicForecast,
              }

              // Set up the datum for the date
              datums[dateKey] = {
                predictedConsumption: datum.predictedConsumption,
                targetConsumption: datum.targetForecast,
                historicConsumption: datum.historicForecast,
                assetDatums: assetDatums,
              }
            } else {
              datums[dateKey].predictedConsumption += datum.predictedConsumption
              datums[dateKey].targetConsumption += datum.targetForecast
              datums[dateKey].historicConsumption += datum.historicForecast

              // Add a record for this asset to the datums
              datums[dateKey].assetDatums[code] = {
                predictedConsumption: datum.predictedConsumption,
                targetConsumption: datum.targetForecast,
                historicConsumption: datum.historicForecast,
              }
            }
          })
        }
        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function (key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  },

  groupExpectedGenerationDatums(assets, assetMap) {
    if (assets) {
      let datums = assets.reduce((datums, project) => {
        let code = project.code
        let asset = assetMap[code]
        if (asset && asset.aggregatedTotals) {
          Object.keys(asset.aggregatedTotals).forEach((dateKey) => {
            let datum = asset.aggregatedTotals[dateKey]

            // Create new date entry if needed
            if (!datums[dateKey]) {
              // Create an object to store the datums for all assets, this will be added to for the date for each asset
              let assetDatums = {}
              assetDatums[code] = {
                expectedGeneration: datum.expectedGeneration,
              }

              // Set up the datum for the date
              datums[dateKey] = {
                expectedGeneration: datum.expectedGeneration,
                assetDatums: assetDatums,
              }
            } else {
              datums[dateKey].expectedGeneration += datum.expectedGeneration

              // Add a record for this asset to the datums
              datums[dateKey].assetDatums[code] = {
                expectedGeneration: datum.expectedGeneration,
              }
            }
          })
        }

        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function (key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  },

  groupPredictedGenerationDatums(assets, assetMap) {
    if (assets) {
      let datums = assets.reduce((datums, project) => {
        let code = project.code
        let asset = assetMap[code]
        if (asset && asset.aggregatedTotals) {
          Object.keys(asset.aggregatedTotals).forEach((dateKey) => {
            let datum = asset.aggregatedTotals[dateKey]

            // Create new date entry if needed
            if (!datums[dateKey]) {
              // Create an object to store the datums for all assets, this will be added to for the date for each asset
              let assetDatums = {}
              assetDatums[code] = {
                predictedGeneration: datum.predictedGeneration,
                simpleForecastGeneration: datum.simpleForecastGeneration,
                irradianceForecastGeneration: datum.irradianceForecastGeneration,
                acEnergyForecastGeneration: datum.acEnergyForecastGeneration,
                Rise: asset.Rise,
                Set: asset.Set,
              }

              // Set up the datum for the date
              datums[dateKey] = {
                predictedGeneration: datum.predictedGeneration,
                simpleForecastGeneration: datum.simpleForecastGeneration,
                irradianceForecastGeneration: datum.irradianceForecastGeneration,
                acEnergyForecastGeneration: datum.acEnergyForecastGeneration,
                assetDatums: assetDatums,
              }
            } else {
              datums[dateKey].predictedGeneration += datum.predictedGeneration
              datums[dateKey].simpleForecastGeneration += datum.simpleForecastGeneration
              datums[dateKey].irradianceForecastGeneration += datum.irradianceForecastGeneration
              datums[dateKey].acEnergyForecastGeneration += datum.acEnergyForecastGeneration

              // Add a record for this asset to the datums
              datums[dateKey].assetDatums[code] = {
                predictedGeneration: datum.predictedGeneration,
                simpleForecastGeneration: datum.simpleForecastGeneration,
                irradianceForecastGeneration: datum.irradianceForecastGeneration,
                acEnergyForecastGeneration: datum.acEnergyForecastGeneration,
                Rise: asset.Rise,
                Set: asset.Set,
              }
            }
          })
        }

        return datums
      }, {})

      // Readings can be missing for certain times so we need to ensure that order is kept across all assets
      let orderedDatums = {}
      Object.keys(datums)
        .sort()
        .forEach(function (key) {
          orderedDatums[key] = datums[key]
        })

      // Note that when assets are filtered out it means that scale can change as there may be additional or missing dates in the range
      // We could insert data to counter if this becomes an issue, i.e. entries with the date but with 0 readings

      return orderedDatums
    }
    return null
  },

  groupDatumsBySiteSource(datums) {
    const addDatumToGroup = (groups, datum) =>
      produce(groups, (draft) => {
        const dateTimeKey = this.createDateTimeKey(datum)
        const siteCode = SourceUtils.getSiteCode(datum.sourceId)

        if (!draft[siteCode]) draft[siteCode] = {}
        if (!draft[siteCode][dateTimeKey]) draft[siteCode][dateTimeKey] = {}
        draft[siteCode][dateTimeKey][datum.sourceId] = datum
      })

    return this.sortDatumsByDate(datums).reduce(addDatumToGroup, {})
  },

  groupStoragePricing(sourceDatums, priceDatums) {
    if (!sourceDatums) {
      return []
    }

    const newDatums = produce(sourceDatums, (draft) => {
      Object.keys(sourceDatums).forEach((dateKey) => {
        let newDatum = draft[dateKey]
        const sources = Object.values(draft[dateKey])
        let soc = 0
        let soh = 0
        let numberOfBats = 0
        let availWattHours = 0
        let capacityWattHours = 0
        sources.forEach((source) => {
          const type = SourceUtils.getDeviceType(source.sourceId)
          if (type === "BESS") {
            soc += source.soc
            source.soh ? (soh += source.soh) : null
            numberOfBats += 1
            availWattHours += source.availWattHours
            capacityWattHours += Number(source.capacityWattHours)
          } else if (type === "STO") {
            newDatum.storage = source.wattHours
          }
        })

        if (priceDatums[dateKey]) {
          newDatum.energyPrice = priceDatums[dateKey]
        }
        newDatum.capacityWattHours = capacityWattHours
        newDatum.availWattHours = availWattHours
        newDatum.soc = soc / numberOfBats
        soh === 0 ? (newDatum.soh = 99) : (newDatum.soh = soh / numberOfBats)
      })
    })

    return newDatums
  },

  groupPriceDatums(priceDatums) {
    let groupedDatums = {}

    priceDatums.forEach((datum) => {
      if (!datum.conditions) {
        return
      }
      let newDate
      if (datum.localDate.length < 12) {
        newDate = datum.localDate + "T00:00"
      } else {
        newDate = datum.localDate
      }
      groupedDatums[newDate] = datum.conditions.energyPricing
    })

    return groupedDatums
  },

  createDateTimeKey(datum) {
    return `${datum.localDate}T${datum.localTime}`
  },

  sortDatumsByDate(datums) {
    const compareDates = (a, b) => {
      const timeA = moment(this.createDateTimeKey(a)).unix()
      const timeB = moment(this.createDateTimeKey(b)).unix()
      return timeA - timeB
    }

    return [...datums].sort(compareDates)
  },

  groupDatumsBySource(datums) {
    const addDatumToGroup = (groups, datum) =>
      produce(groups, (draft) => {
        const dateTimeKey = this.createDateTimeKey(datum)
        const sourceId = datum.sourceId

        if (!draft[dateTimeKey]) draft[dateTimeKey] = {}
        if (!draft[dateTimeKey][sourceId]) draft[dateTimeKey][sourceId] = datum
      })

    return this.sortDatumsByDate(datums).reduce(addDatumToGroup, {})
  },
}

export default DatumUtils

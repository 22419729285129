import React, { useState, useEffect } from "react"
import { getOrganizationStatusApi } from "../../services/organization"
import { Alert, Button, Card, CardBody, CardHeader, Spinner } from "reactstrap"

/**
 * Organization Status Component
 * A special testing page that fetches and displays organization status from the infrastructure API
 * This component is hidden from the UI and only accessible via URL
 */
export const OrganizationStatus: React.FC = () => {
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const [data, setData] = useState<any>(null)

  const fetchOrganizationStatus = async () => {
    setLoading(true)
    setError(null)
    
    try {
      const response = await getOrganizationStatusApi()
      setData(response)
    } catch (err: any) {
      setError(err.message || "Failed to fetch organization status")
      console.error("Error fetching organization status:", err)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    fetchOrganizationStatus()
  }, [])

  return (
    <div className="p-4">
      <Card className="mb-4">
        <CardHeader className="d-flex justify-content-between align-items-center">
          <h2>Organization Status</h2>
          <Button 
            color="primary"
            onClick={fetchOrganizationStatus}
            disabled={loading}
          >
            Refresh
          </Button>
        </CardHeader>
        <CardBody>
          {loading && (
            <div className="text-center p-5">
              <Spinner color="primary" />
              <p className="mt-2">Loading organization status...</p>
            </div>
          )}
          
          {error && !loading && (
            <Alert color="danger">
              <h4 className="alert-heading">Error!</h4>
              <p>{error}</p>
            </Alert>
          )}
          
          {data && !loading && !error && (
            <div>
              <h4 className="mb-3">Organization Status Data:</h4>
              <pre className="bg-light p-3 border rounded text-dark" style={{ maxHeight: '500px', overflow: 'auto' }}>
                {JSON.stringify(data, null, 2)}
              </pre>
            </div>
          )}
        </CardBody>
      </Card>
    </div>
  )
}

export default OrganizationStatus

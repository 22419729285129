import React from "react"
import { Container } from "reactstrap"
import { isEqual } from "lodash"
import Moment from "moment"
// eslint-disable-next-line no-unused-vars
import { extendMoment, DateRange } from "moment-range"

import Logger from "@common/Logger"
import EcosuiteComponent, { EcosuiteComponentError } from "@common/EcosuiteComponent"
import Aggregations from "@common/Aggregations"

import EnergyService from "@dashboard/energy/EnergyService"

import PowerUserGraph from "./PowerUserGraph"
import PowerUserSourcesTree from "./PowerUserSourcesTree"
import PropertyFilter from "./PropertyFilter"
import SourceUtils from "./SourceUtils"
import PowerUserAggregations, { getAggregationForRange } from "./PowerUserAggregations"

import "react-datetime/css/react-datetime.css"
import "./PowerUserContent.css"
const jsprim = require("jsprim")

const moment = extendMoment(Moment)

const ignoredProperties = ["created", "nodeId", "sourceId", "localDate", "localTime"]

export default class PowerUserContent extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.maintainProperties = this.maintainProperties.bind(this)
    this.getSelectedProjectIdsFromSelectedSources = this.getSelectedProjectIdsFromSelectedSources.bind(this)
    this.selectProperties = this.selectProperties.bind(this)
    this.selectPropertiesFilter = this.selectPropertiesFilter.bind(this)
    this.loadDatums = this.loadDatums.bind(this)
    this.loadSelectedDatums = this.loadSelectedDatums.bind(this)
    this.toggleProperty = this.toggleProperty.bind(this)
    this.onExpand = this.onExpand.bind(this)
    this.state.properties = new Set()
    this.state.selectedProperties = new Set()
    this.state.selectedSourceIds = []
    this.state.availableProperties = {}
    this.state.datums = undefined
  }

  componentDidMount() {
    super.componentDidMount()

    this.loadDatums()
  }

  toggleProperty(property) {
    var selectedProperties = new Set().union(this.state.selectedProperties)
    if (selectedProperties.has(property)) {
      selectedProperties.delete(property)
    } else {
      selectedProperties.add(property)
    }
    this.setState({
      selectedProperties: selectedProperties,
    })
  }

  /** given an array of projects, return an array of projects containing only the data of interest to us, i.e.
   * the names of sites, systems, nodes and devices */
  getSimplifiedProjects(projects) {
    return projects.map((project) => this.getSimplifiedProject(project))
  }

  /** given a project, return a project containing only the data of interest to us, i.e.
   * the names of sites, systems, nodes and devices */
  getSimplifiedProject(project) {
    let sites = {}
    for (const [code, site] of Object.entries(project.sites)) {
      sites[code] = this.getSimplifiedSite(site)
    }
    return { code: project.code, name: project.name, sites: sites }
  }

  /** given a site, return a site containing only the data of interest to us, i.e.
   * the names of sites, systems, nodes and devices */
  getSimplifiedSite(site) {
    let systems = {}
    for (const [code, system] of Object.entries(site.systems)) {
      systems[code] = { code: system.code, name: system.name }
    }
    return { code: site.code, name: site.name, systems: systems }
  }

  componentDidUpdate(prevProps) {
    if (!this.props.range.isSame(prevProps.range)) {
      Logger.info("range changed from " + JSON.stringify(prevProps.range) + " to " + JSON.stringify(this.props.range))
    }
    const simplifiedProjects = this.getSimplifiedProjects(this.props.projects)
    const projectsChanged = !isEqual(simplifiedProjects, this.state.simplifiedProjects)
    if (projectsChanged) {
      Logger.info("projects changed")
    }
    if (
      projectsChanged ||
      !this.props.range.isSame(prevProps.range) ||
      this.getAggregation() !== prevProps.aggregation
    ) {
      if (
        this.props.projects &&
        this.props.projects.length === 1 &&
        (!prevProps.projects ||
          prevProps.projects.length < 1 ||
          this.props.projects[0].code !== prevProps.projects[0].code)
      ) {
        // Clear the existing selections then load the datums
        this.setStateIfMounted(
          {
            selectedSourceIds: [],
            simplifiedProjects: simplifiedProjects,
            availableProperties: {},
            checkedKeys: [],
            datums: undefined,
            datumsRange: null,
          },
          this.loadDatums,
        )
      } else {
        this.setStateIfMounted({ simplifiedProjects: simplifiedProjects }, this.loadDatums)
      }
    } else {
      Logger.info("componentDidUpdate(): nothing changed")
    }
  }

  /** @param {DateRange} range */
  isLoadRequired(range, selectedSourceIds) {
    if (!this.state.datums) {
      return true
    }

    /** @type Array.<object> */
    const projects = this.props.projects
    if (!projects || !projects.length) {
      Logger.debug("not loading range because no projects available")
      return false
    }

    if (!range) {
      Logger.debug("not loading range because no range provided")
      return false
    }

    if (!this.state.selectedSourceIds || !this.state.selectedSourceIds.length) {
      Logger.debug("not loading range as no source IDs are selected")
      return false
    }

    /** @type DateRange */
    const exclusiveRange = this.getExclusiveRange(range)
    if (this.areSearchParamsCurrent(exclusiveRange, selectedSourceIds)) {
      Logger.debug("not loading range because range and sources unchanged")
      return false
    }

    return true
  }

  /** @param {DateRange} exclusiveRange
   *  @param {Array<String>} selectedSourceIds
   */
  areSearchParamsCurrent(exclusiveRange, selectedSourceIds) {
    const isLoadingSourceIds =
      !this.state.loadingSourceIds || selectedSourceIds.every((v) => this.state.loadingSourceIds.includes(v))
    if (this.state.loadingSourceIds && !isLoadingSourceIds) {
      Logger.info("selectedSourceIds = " + JSON.stringify(selectedSourceIds))
      Logger.info("state.loadingSourceIds=" + JSON.stringify(this.state.loadingSourceIds))
    }
    Logger.info(
      "isLoadingSourceIds = " +
        isLoadingSourceIds +
        ", exclusiveRange = " +
        JSON.stringify(exclusiveRange) +
        ", this.state.exclusiveRange = " +
        JSON.stringify(this.state.exclusiveRange),
    )
    return (
      this.state.exclusiveRange && this.isSameRange(exclusiveRange, this.state.exclusiveRange) && isLoadingSourceIds
    )
  }

  // we use this because exclusiveRange sometimes mysteriously varies by a millisecond (between end of day and start of next day), causing isSame() to return false
  isSameRange(range1, range2) {
    // return range1.isSame(range2)
    return (
      range1 &&
      range2 &&
      range1.start &&
      range1.end &&
      range1.start.isSame(range2.start, "second") &&
      range1.end.add(1, "millisecond").isSame(range2.end.add(1, "millisecond"), "second")
    )
  }

  getAggregation() {
    return getAggregationForRange(
      this.props.range,
      this.props.aggregation,
      window.screen.width ? Math.round(window.screen.width * 0.75) : 2000,
    )
  }

  /**
   * Loads the latest data for the requested range.
   */
  loadDatums() {
    /** @type DateRange */
    const exclusiveRange = this.getExclusiveRange(this.props.range)
    Logger.info("loadDatums: exclusiveRange = " + JSON.stringify(exclusiveRange))

    if (this.props.projects && exclusiveRange && this.state.selectedSourceIds && this.state.selectedSourceIds.length) {
      const selectedProjectIds = this.getSelectedProjectIdsFromSelectedSources()
      const loadingSourceIds = this.state.selectedSourceIds
      this.setStateIfMounted(
        {
          // Keep track of the the search params that we are loading
          exclusiveRange: exclusiveRange,
          aggregation: this.getAggregation(),
          selectedProjectIds: selectedProjectIds,
          loadingSourceIds: loadingSourceIds,

          // Clear the existing state to make it clear an update is occuring
          datums: undefined,
          datumsRange: null,
        },
        () => {
          this.loadSelectedDatums(selectedProjectIds, loadingSourceIds, exclusiveRange)
        },
      )
    } else {
      this.setState({ exclusiveRange: exclusiveRange })
    }
  }

  /** @param {Array.<string>} selectedProjectIds
   *  @param {Array.<string>} loadingSourceIds
   *  @param {DateRange} exclusiveRange
   */
  async loadSelectedDatums(selectedProjectIds, loadingSourceIds, exclusiveRange) {
    Logger.debug(
      "load source IDs " +
        loadingSourceIds.toString() +
        ", range " +
        JSON.stringify(exclusiveRange) +
        ", aggregation " +
        this.getAggregation(),
    )
    /** @type Array.<Promise> */
    let projectPromises = selectedProjectIds.map((projectId) => {
      // Logger.debug("requesting datums for project " + projectId)
      return EnergyService.getNodesDatums(
        exclusiveRange,
        this.getAggregation(),
        projectId,
        null,
        this.state.selectedSourceIds,
      )
        .then((response) => {
          if (response.data.error) {
            Logger.error("Error response for nodes datums for " + projectId + ": " + response.data.error)
            this.setStateIfMounted({
              datums: new EcosuiteComponentError(response.data.error),
            })
          } else {
            return response
          }
        })
        .catch((err) => {
          Logger.error("failed to get nodes datums for " + projectId + ": " + err)
          this.setStateIfMounted({
            datums: new EcosuiteComponentError(err),
          })
        })
    })
    let responses = await Promise.all(projectPromises)
    this.processDatumResponses(exclusiveRange, loadingSourceIds, responses)
  }

  /** @param {DateRange} exclusiveRange
      @param {Array.<string>} loadingSourceIds 
      @param {Array.<{data: Array<{created: any}>, range: DateRange}>}  responses */
  processDatumResponses(exclusiveRange, loadingSourceIds, responses) {
    Logger.info("processDatumResponses: processing " + responses.length + " project responses")
    if (!this.isContentError(this.state.datums) && this.areSearchParamsCurrent(exclusiveRange, loadingSourceIds)) {
      // maps each source ID to the Set of all possible properties for that source
      /** @type Object.<String, Set<String>> */
      let availableProperties = jsprim.deepCopy(this.state.availableProperties)

      /** @type Array.<Object> */
      var datums = []
      /** @type String */
      let datumsRange

      responses.forEach((response) => {
        if (datums && datums.length) {
          datums = datums.concat(response.data)
        } else {
          datums = response.data
        }

        this.maintainProperties(availableProperties, response.data)

        if (response.range) {
          datumsRange = response.range
        }
      })
      datums.sort((datum1, datum2) => datum1.created - datum2.created)

      /** @type Set<String> */
      var properties = new Set().union(this.state.properties)
      Object.values(availableProperties).forEach((sourceProperties) => {
        properties = properties.union(sourceProperties)
      })
      Logger.info("availableProperties =" + JSON.stringify(availableProperties))
      const sortedProperties = Array.from(properties).sort()
      Logger.info("properties =" + JSON.stringify(sortedProperties))

      this.setStateIfMounted({
        datums: datums,
        datumsRange: datumsRange,
        availableProperties: availableProperties,
        properties: new Set(sortedProperties),
        checkedKeys: this.filterCheckedKeys(availableProperties),
      })
    } else {
      Logger.info("content error or search params not current")
    }
  }

  /**
   * Maintains the available properties map using the supplied data
   * @param {Object.<String, Set<String>>} availableProperties maps each source ID to the Set of all possible properties for that source
   * @param {Array.<{nodeId: string, sourceId: string}>} data
   */
  maintainProperties(availableProperties, data) {
    Logger.info("adding " + data.length + " data")
    Logger.info(JSON.stringify(data))
    data.forEach((datum) => {
      /** @type String */
      const sourceId = datum.nodeId + datum.sourceId
      /** @type Set<String> */
      var sourceAvailableProperties = availableProperties[sourceId]
      if (!sourceAvailableProperties) {
        sourceAvailableProperties = new Set()
        availableProperties[sourceId] = sourceAvailableProperties
      }

      Object.keys(datum).forEach((property) => {
        if (!ignoredProperties.includes(property)) {
          sourceAvailableProperties.add(property)
        }
      })
    })
  }

  /**
   * We filter down the checked keys to only contain those that are available
   * @param {*} availableProperties The properties that are available to use
   */
  filterCheckedKeys(availableProperties) {
    if (availableProperties && Object.keys(availableProperties).length && this.state.checkedKeys) {
      const checkedKeys = this.state.checkedKeys.filter((key) => {
        if (SourceUtils.isProperty(key)) {
          // We filter down to only those properties that are available
          let nodeAndSourceId = SourceUtils.getNodeAndSource(key)
          let propertyName = SourceUtils.getProperty(key)
          return availableProperties[nodeAndSourceId] && availableProperties[nodeAndSourceId].has(propertyName)
        } else {
          return true
        }
      })
      return checkedKeys
    } else {
      // Logger.debug("No available properties configured so returning all checked keys")
      return this.state.checkedKeys
    }
  }

  /** @param Array.<String> selectedSourceIds
   *  @return Array.<String> the list of project IDs of all the projects that contain selected sources */
  getSelectedProjectIdsFromSelectedSources(selectedSourceIds) {
    /** @type Array.<String> */
    var selectedProjectIds = []
    if (!selectedSourceIds) {
      selectedSourceIds = this.state.selectedSourceIds
    }
    selectedSourceIds.forEach((sourceId) => {
      /** @type String */
      const projectId = SourceUtils.getProjectCode(sourceId)
      if (!selectedProjectIds.includes(projectId)) {
        selectedProjectIds.push(projectId)
      }
    })
    return selectedProjectIds
  }

  /** @return {boolean} true if two arrays are both null or undefined or both contain the same elements, false otherwise
   *  @param {Array} array1
   *  @param {Array} array2
   * side effects: none
   */
  equalsIgnoringOrder(array1, array2) {
    if (array1 === array2) {
      return true
    }
    if (!array1 || !array2) {
      return false
    }
    if (array1.length !== array2.length) {
      return false
    }
    array1.forEach((element) => {
      if (!array2.includes(element)) {
        return false
      }
    })
    return true
  }

  /** SolarNetwork expects an exclusive end date so we add a day to convert an inclusive date to an exclusive one
   * @param {DateRange} range
   * @return {DateRange}
   */
  getExclusiveRange(range) {
    return moment.range(
      moment(range.start).startOf(this.getAggregation()),
      moment(range.end).add(1, this.getAggregation()).startOf(this.getAggregation()),
    )
  }

  /** @param {Set.<String>} properties the properties to filter on */
  selectPropertiesFilter(properties) {
    this.setStateIfMounted({
      selectedProperties: properties,
    })
  }

  /**
   * Save the properties that have been selected in the tree. Used to filter the graph.
   * @param {Array.<string>} sourceIds The sourceIds that have been selected in the tree
   * @param {Array.<string>} checkedKeys The keys that have been selected in the tree
   * @returns {void}
   */
  selectProperties(sourceIds, checkedKeys) {
    if (checkedKeys !== this.state.checkedKeys || sourceIds !== this.state.selectedSourceIds) {
      if (checkedKeys !== this.state.checkedKeys) {
        Logger.info("checkedKeys = " + JSON.stringify(checkedKeys))
        Logger.info("state.checkedKeys = " + JSON.stringify(this.state.checkedKeys))
      }
      if (sourceIds !== this.state.selectedSourceIds) {
        Logger.info("sourceIds = " + JSON.stringify(sourceIds))
        Logger.info("state.sourceIds = " + JSON.stringify(this.state.sourceIds))
      }
      this.setStateIfMounted(
        {
          selectedSourceIds: sourceIds,
          checkedKeys: checkedKeys,
        },
        () => {
          const loadingSourceIds = this.state.selectedSourceIds

          if (this.isLoadRequired(this.props.range, loadingSourceIds)) {
            this.loadDatums()
          } else {
            Logger.debug(
              `Skipping load for range ${JSON.stringify(
                this.props.range,
              )}, aggregation: ${this.getAggregation()} selectedProjectIds: ${this.state.selectedProjectIds}`,
            )
          }

          // We also let the parent container know about which projects are selected
          if (this.props.actions && this.props.actions.selectProjects) {
            this.props.actions.selectProjects(this.getSelectedProjectIdsFromSelectedSources())
          }
          if (this.props.actions && this.props.actions.selectSourceIds) {
            this.props.actions.selectSourceIds(sourceIds, checkedKeys)
          }
        },
      )
    } else {
      Logger.info("selectProperties(): nothing changed")
    }
  }

  onExpand(expandedKeys, params) {
    Logger.info("expandedKeys=" + JSON.stringify(expandedKeys))
    Logger.info("params = " + JSON.stringify(params))
    if (!params.node.children) {
      this.loadSelectedDatums([SourceUtils.getProjectCode(params.node.key)], [params.node.key], this.props.range)
      params.node.key + "/" + params.node.title
    }
  }

  renderContent() {
    return (
      <Container className="power-user-content" fluid={true}>
        {this.renderSourcesInfo()}
        <div className="power-user-row">
          {this.props.filterByProperties ? (
            <div className="power-user-sources-tree">
              <PropertyFilter
                properties={this.state.properties}
                toggleProperty={this.toggleProperty}
                selectProperties={this.selectPropertiesFilter}
                selectedProperties={this.state.selectedProperties}
              />
            </div>
          ) : null}
          <div className="power-user-sources-tree">
            <PowerUserSourcesTree
              projects={this.props.projects}
              availableProperties={this.state.availableProperties}
              checkedKeys={this.state.checkedKeys}
              onExpand={this.onExpand}
              propertiesFilter={this.state.selectedProperties}
              selectProperties={this.selectProperties}
              filterByProperties={this.props.filterByProperties}
            />
          </div>
          <div className="power-user-graph">
            <PowerUserGraph
              datums={this.state.datums}
              aggregation={this.getAggregation()}
              range={this.state.datumsRange}
              checkedKeys={this.state.checkedKeys}
            />
          </div>
        </div>
      </Container>
    )
  }

  renderSourcesInfo() {
    if (
      this.state.selectedSourceIds &&
      this.state.selectedSourceIds.length &&
      this.state.checkedKeys &&
      this.state.checkedKeys.length
    ) {
      const projectCount = this.getSelectedProjectIdsFromSelectedSources().length
      const sourceCount = this.state.selectedSourceIds.length
      const propertyCount = this.state.checkedKeys.length
      const aggregation = this.getAggregation()
      const diff =
        Math.round(this.props.range.diff(Aggregations.getUnits(aggregation)) / Aggregations.getSize(aggregation)) + 1
      const dataPoints = diff * propertyCount

      return (
        <p className="graph-info">
          {`Graphing ${pluralise("property", propertyCount)} across ${pluralise("source", sourceCount)} and ${pluralise(
            "project",
            projectCount,
          )};`}
          {` applying aggregate `}
          <b>{aggregation}</b>
          {aggregation !== PowerUserAggregations.Raw ? ` for ${diff} intervals;` : ""}
          {` plotting approximately `}
          <span
            className={
              dataPoints > 20000 ? (this.isContentError(this.state.datums) ? "graph-error" : "graph-warning") : ""
            }
          >
            {dataPoints.toLocaleString()}
          </span>
          {` data points`}
        </p>
      )
    }
  }
}

/**
 *
 * @param {string} word
 * @param {number} count
 */
const pluralise = (word, count) => {
  if (count !== 1) {
    if (word.endsWith("y")) {
      return count + " " + word.substring(0, word.length - 1) + "ies"
    } else {
      return count + " " + word + "s"
    }
  } else {
    return count + " " + word
  }
}

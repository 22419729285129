import * as React from "react"
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import { useCallback, useState } from "react"
import { Dialog, DialogContent, DialogHeader, DialogTitle } from "src/components/ui/dialog"
import { Auth } from "aws-amplify"
import aws from "aws-sdk"
import JSZip from "jszip"

export interface PreviewFile {
  fileName?: string
  fileUrl?: string
  fileKey?: string
}

export const viewFile = (key: string) => {
  return Auth.currentCredentials().then((credentials) => {
    const s3 = new aws.S3({
      apiVersion: "2013-04-01",
      credentials: Auth.essentialCredentials(credentials),
    })
    const params = {
      Bucket: process.env.REACT_APP_DOCUMENTS_BUCKET,
      Key: key,
    }
    return s3.getSignedUrlPromise("getObject", params)
  })
}

export const useFilePreview = () => {
  const [previewFile, setPreviewFile] = useState<PreviewFile | null>(null)
  const [pdfLoadError, setPdfLoadError] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const handlePreview = useCallback(async (file: PreviewFile) => {
    setIsLoading(true)
    setPdfLoadError(false)

    if (file.fileKey && !file.fileUrl) {
      try {
        const url = await viewFile(file.fileKey)
        setPreviewFile({ ...file, fileUrl: url })
      } catch (error) {
        console.error("Error fetching file URL:", error)
        setPdfLoadError(true)
      }
    } else {
      setPreviewFile(file)
    }

    setIsLoading(false)
  }, [])

  return {
    previewFile,
    setPreviewFile,
    pdfLoadError,
    setPdfLoadError,
    handlePreview,
    isLoading,
  }
}


export const downloadFile = async (fileUrl: string, fileName: string) => {
  try {
    const response = await fetch(fileUrl)
    const blob = await response.blob()
    const url = window.URL.createObjectURL(blob)
    const link = document.createElement("a")
    link.href = url
    link.download = fileName
    link.click()
    window.URL.revokeObjectURL(url)
  } catch (error) {
    console.error("Download failed:", error)
  }
}

export const downloadFilesAsZip = async (files: { fileUrl: string; fileName: string }[], zipFileName: string) => {
  try {
    const zip = new JSZip()
    let hasContent = false

    await Promise.all(
      files.map(async ({ fileUrl, fileName }) => {
        try {
          const response = await fetch(fileUrl)
          if (response.ok) {
            const blob = await response.blob()
            zip.file(fileName, blob)
            hasContent = true
          }
        } catch (error) {
          console.error(`Failed to download ${fileName}:`, error)
        }
      })
    )

    if (hasContent) {
      const content = await zip.generateAsync({ type: "blob" })
      
      const blobURL = window.URL.createObjectURL(content)
      const link = document.createElement("a")
      link.href = blobURL
      link.download = zipFileName
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      window.URL.revokeObjectURL(blobURL)
    } else {
      console.error("No files were added to the zip")
    }
  } catch (error) {
    console.error("Failed to create zip file:", error)
  }
}

const getFileExtension = (fileName: string): string => fileName.split(".").pop()?.toLowerCase() || ""

export const PreviewDialog: React.FC<{
  previewFile: PreviewFile | null
  setPreviewFile: (file: PreviewFile | null) => void
  pdfLoadError: boolean
  isLoading?: boolean
}> = ({ previewFile, setPreviewFile, pdfLoadError, isLoading = true }) => (
  <Dialog open={!!previewFile} onOpenChange={() => setPreviewFile(null)}>
    <DialogContent fullScreen>
      <DialogHeader className="tw-p-4">
        <DialogTitle>{previewFile?.fileName}</DialogTitle>
      </DialogHeader>
      <div className="tw-w-screen tw-h-[calc(100vh-64px)] tw-overflow-auto">
        {isLoading && (
          <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
            <p>Loading file...</p>
          </div>
        )}
        {!isLoading && previewFile && previewFile.fileUrl && (
          <DocViewer
            documents={[{ uri: previewFile.fileUrl, fileType: getFileExtension(previewFile.fileKey!) }]}
            pluginRenderers={DocViewerRenderers}
            config={{
              header: {
                disableFileName: true,
                disableHeader: true,
              },
              pdfZoom: {
                defaultZoom: 1,
                zoomJump: 0.2,
              },
              pdfVerticalScrollByDefault: true,
            }}
            style={{ height: "100%" }}
          />
        )}
        {pdfLoadError && (
          <div className="tw-flex tw-items-center tw-justify-center tw-h-full">
            <p>Failed to load PDF. Please try downloading the file instead.</p>
          </div>
        )}
      </div>
    </DialogContent>
  </Dialog>
)

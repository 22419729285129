import React from "react"
import EcosuiteModule from "@common/module/EcosuiteModule"
import DateRangeUtils from "@common/utils/DateRangeUtils"
import EcosuiteComponent, { Error } from "@common/EcosuiteComponent"
import ConnectivityOverView from "./views/ConnectivityDashboardView"
import ConnectivityMapView from "./views/ConnectivityMapView"
import ConnectivityListView from "./views/ConnectivityListView"
import ConnectivityDashboardProjectView from "./views/ConnectivityDashboardProjectView"
import EnergyService from "@dashboard/energy/EnergyService"
import ConnectivityService from "./ConnectivityService"
import i18n from "src/i18n"

const { t } = i18n
export default class ConnectivityModule extends EcosuiteModule {
  constructor(props) {
    super(props, "connectivity")
  }

  componentDidMount() {
    super.componentDidMount()
    this.loadDevices()
    this.fetchHealthStatusDatums()
    this.fetchNodeMetadata()
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)

    // We recalculate the date range for "All Time" so that the graphs can be updated if necessary
    this.recalculateAllTimeRange(prevProps)

    if (
      JSON.stringify(this.getProjects(this.props).map((project) => project.code)) !==
        JSON.stringify(this.getProjects(prevProps).map((project) => project.code)) ||
      (prevProps.loadTime && this.props.loadTime !== prevProps.loadTime)
    ) {
      //this.loadConnectivity(this.getExclusiveRange())
    }
  }

  getNodeIdsForProject(project, devices) {
    const projectDevices = devices[project.code]
    let nodeIds = []
    if (!projectDevices) {
      return nodeIds
    }
    Object.values(projectDevices.sites).forEach((site) => {
      Object.values(site.systems).forEach((system) => {
        Object.values(system.nodes).forEach((node) => {
          nodeIds.push(Number(node.id))
        })
      })
    })
    return nodeIds
  }

  async loadDevices() {
    EnergyService.getDevices()
      .then((response) => {
        this.setState({ SNDevices: response })
      })
      .catch((error) => {
        this.setStateIfMounted({
          hasError: true,
          error: error,
        })
      })
  }

  async fetchHealthStatusDatums() {
    ConnectivityService.getMostRecentHealthDatums().then((data) => {
      this.setState({ healthStatusDatums: data })
    })
  }

  async fetchNodeMetadata() {
    ConnectivityService.getNodeMetadata().then((data) => {
      this.setState({ nodeMetadata: data })
    })
  }

  filterDatumsByPortfolio(datums) {
    if (!datums) return
    let portfolioNodeIds = []
    this.props.projects.forEach(
      (project) =>
        (portfolioNodeIds = portfolioNodeIds.concat(this.getNodeIdsForProject(project, this.state.SNDevices))),
    )
    const filteredNodeIds = [...new Set(portfolioNodeIds)]
    const filteredDatums = datums.filter((datum) => {
      return filteredNodeIds.includes(datum.nodeId)
    })
    return filteredDatums
  }

  filterDatumsByProject(datums) {
    if (!datums) return
    const projectNodeIds = this.getNodeIdsForProject(this.props.project, this.state.SNDevices)
    const filteredDatums = datums.filter((datum) => {
      return projectNodeIds.includes(datum.nodeId)
    })
    return filteredDatums
  }

  getProjects(props) {
    return props.project ? [props.project] : props.projects
  }

  renderProjectView() {
    if (this.isContentError(this.state.connectivity)) {
      return <Error error={this.state.connectivity.getError()} />
    }
    if (this.isContentError(this.state.devices)) {
      return <Error error={this.state.devices.getError()} />
    }
    return (
      <ConnectivityProjectViews
        view={this.state.projectView}
        groups={this.props.groups}
        projects={this.state.projects}
        project={this.props.project}
        connectivity={this.state.connectivity}
        healthStatusDatums={
          this.state.SNDevices
            ? this.filterDatumsByProject(this.state.healthStatusDatums)
            : this.state.healthStatusDatums
        }
        nodeMetadata={
          this.state.SNDevices ? this.filterDatumsByProject(this.state.nodeMetadata) : this.state.nodeMetadata
        }
        range={this.getExclusiveRange()}
        aggregation={DateRangeUtils.getAggregateForRange(this.getExclusiveRange())}
        selectRange={this.selectRange}
      />
    )
  }

  renderModuleView() {
    if (this.isContentError(this.state.connectivity)) {
      return <Error error={this.state.connectivity.getError()} />
    }
    if (this.isContentError(this.state.devices)) {
      return <Error error={this.state.devices.getError()} />
    }

    return (
      <ConnectivityPortfolioViews
        view={this.state.moduleView}
        healthStatusDatums={
          this.state.SNDevices
            ? this.filterDatumsByPortfolio(this.state.healthStatusDatums)
            : this.state.healthStatusDatums
        }
        nodeMetadata={
          this.state.SNDevices ? this.filterDatumsByPortfolio(this.state.nodeMetadata) : this.state.nodeMetadata
        }
        groups={this.props.groups}
        connectivity={this.state.connectivity}
        range={this.getExclusiveRange()}
        aggregation={DateRangeUtils.getAggregateForRange(this.getExclusiveRange())}
        selectProject={this.selectProject}
        selectRange={this.selectRange}
      />
    )
  }
}
class ConnectivityProjectViews extends EcosuiteComponent {
  renderContent() {
    switch (this.props.view) {
      case "overview":
        return <ConnectivityDashboardProjectView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}` + this.props.view }} />
    }
  }
}

class ConnectivityPortfolioViews extends EcosuiteComponent {
  renderContent() {
    switch (this.props.view) {
      case "overview":
        return <ConnectivityOverView {...this.props} />
      case "map":
        return <ConnectivityMapView {...this.props} />
      case "list":
        return <ConnectivityListView {...this.props} />
      default:
        return <Error error={{ message: `${t("errors.unsupported_view")}` + this.props.view }} />
    }
  }
}

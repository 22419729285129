import React from "react"
import { Button, Col, Row } from "reactstrap"
import { Loading } from "@common/EcosuiteComponent"
import EcosuiteView from "@common/module/EcosuiteView"
import ServiceRequestService from "@dashboard/event/ServiceRequestService"
import "react-datetime/css/react-datetime.css"
import SparkMD5 from "spark-md5"
import Icon from "@common/display/Icon"
import i18n from "src/i18n"
import { getUserOrganizationId } from "@common/OrganizationUtils"
import ServiceRequestForm from "./ServiceRequestForm"
import { FormError } from "@common/form/EcosuiteForm"
import EventService from "@dashboard/event/EventService"

const { t } = i18n
class ServiceRequestDetails extends EcosuiteView {
  constructor(props) {
    super(props)
    this.submitRef = React.createRef()
    this.state = {
      loading: true,

      // Generate a placeholder ID for new service request editor (if you are
      // creating a new service request).
      newServiceRequestId: SparkMD5.hash(new Date().getTime().toString()),
      selectedSite: undefined,
    }

    this.createServiceRequest = this.createServiceRequest.bind(this)
    this.updateServiceRequest = this.updateServiceRequest.bind(this)
    this.resolveServiceRequest = this.resolveServiceRequest.bind(this)
    this.deleteServiceRequest = this.deleteServiceRequest.bind(this)
    this.downloadServiceRequest = this.downloadServiceRequest.bind(this)
    this.exportServiceRequest = this.exportServiceRequest.bind(this)
    this.onFormChange = this.onFormChange.bind(this)
  }

  // This is a hack to get around the fact that the form is updating while parent state changes
  // Ideal fix would be to store the form data in a state, but slate editor is not compatible with that currently
  // https://ecogyenergy.atlassian.net/browse/EP-3003
  shouldComponentUpdate(nextProps, nextState) {
    if (this.state != nextState) {
      return true
    }
    return false
  }

  isReadOnly() {
    return !this.props.groups.includes("event-write")
  }

  componentDidMount() {
    super.componentDidMount()

    let formData = this.props.serviceRequest
    if (formData && !formData.id) {
      // If it's a new serviceRequest load
      formData = {
        location: {},
        type: formData.type, // we can pass in the type on new serviceRequests
      }
      let params = new URLSearchParams(window.location.search)

      if (params.has("project")) {
        formData.location.project = params.get("project")
      }
      if (params.has("site")) {
        formData.location.site = params.get("site")
      }
      if (params.has("system")) {
        formData.location.system = params.get("system")
      }
      if (this.props.project) {
        formData.location.project = this.props.project.code
      }
    }

    getUserOrganizationId().then((organizationId) => {
      this.setState({ formData: formData, organizationId, loading: false })
    })
  }

  componentDidUpdate(prevProps) {
    super.componentDidUpdate(prevProps)
    if (this.props.serviceRequest !== prevProps.serviceRequest) {
      let formData = this.props.serviceRequest
      if (formData && !formData.id) {
        // If it's a new serviceRequest load
        if (!formData.location) {
          formData.location = {}
        }
        if (this.props.project) {
          formData.location.project = this.props.project.code
        }
      }

      this.setState({ formData: this.props.serviceRequest })
    }
  }

  createServiceRequest(form) {
    this.setState({ loading: true })

    ServiceRequestService.createServiceRequest(form)
      .then((serviceRequest) => {
        this.setStateIfMounted({ loading: false })
        this.props.actions.serviceRequestUpdated(serviceRequest) // updates the file list selected file
        this.props.actions.selectServiceRequest() // clear the selected serviceRequest
      })
      .catch((err) => {
        this.setStateIfMounted({ loading: false, error: err })
      })
  }

  updateServiceRequest(form) {
    this.setState({ loading: true })

    ServiceRequestService.updateServiceRequest(this.props.serviceRequest.id, form)
      .then((serviceRequest) => {
        this.setStateIfMounted({ loading: false })
        this.props.actions.serviceRequestUpdated(serviceRequest) // updates the file list selected file
        this.props.actions.selectServiceRequest() // clear the selected serviceRequest
      })
      .catch((err) => {
        this.setStateIfMounted({ loading: false, error: err })
      })

    this.syncServiceRequestToEvent(this.props.serviceRequest, form.formData)
  }

  syncServiceRequestToEvent(oldSR, newSR) {
    const SRId = oldSR.id
    newSR.events.forEach((eventId) => {
      this.addOrRemoveEventFromEvent(SRId, eventId)
    })
    const removedIds = this.checkForRemoved(oldSR, newSR)
    if (removedIds) {
      removedIds.forEach((eventId) => {
        this.addOrRemoveEventFromEvent(SRId, eventId, true)
      })
    }
  }

  checkForRemoved(oldSR, newSR) {
    const removedIds = oldSR.events.filter((oe) => !newSR.events.find((ne) => ne === oe))
    return removedIds
  }

  addOrRemoveEventFromEvent(serviceRequestId, eventId, remove) {
    const event = this.props.events.find((ev) => ev.id === eventId)
    const serviceRequestExists = event.serviceRequests.find((srId) => srId === serviceRequestId)
    let newEvent
    if (!serviceRequestExists) {
      newEvent = { formData: { ...event, serviceRequests: [...event.serviceRequests, serviceRequestId] } }
    } else if (remove) {
      newEvent = {
        formData: { ...event, serviceRequests: event.serviceRequests.filter((sr) => sr !== serviceRequestId) },
      }
    }

    if (newEvent) {
      EventService.updateEvent(newEvent.formData.id, newEvent.formData)
        .then((event) => {
          this.props.actions.eventUpdated(event) // updates the file list
        })
        .catch((err) => {
          this.setStateIfMounted({ loading: false, error: err })
        })
    }
  }

  resolveServiceRequest(serviceRequest) {
    this.setState({ loading: true })
    ServiceRequestService.resolveServiceRequest(serviceRequest.id)
      .then(() => {
        this.setStateIfMounted({ loading: false })
        this.props.actions.serviceRequestUpdated(serviceRequest) // updates the file list selected file
      })
      .catch((err) => {
        this.setStateIfMounted({ loading: false, error: err })
      })
  }

  deleteServiceRequest(e) {
    e.preventDefault()
    const confirm = window.confirm(t("event.messages.confirm_delete_service"))
    if (confirm) {
      let serviceRequest = this.props.serviceRequest
      this.setState({ loading: true })
      ServiceRequestService.deleteServiceRequest(serviceRequest.id)
        .then(() => {
          this.setStateIfMounted({ loading: false })
          this.props.actions.serviceRequestDeleted(serviceRequest) // updates the file list selected file
        })
        .catch((err) => {
          this.setStateIfMounted({ loading: false, error: err })
        })
    }
  }

  /**
   * Download the service request.
   * @param e - The event.
   */
  downloadServiceRequest(e) {
    e.preventDefault()
    let serviceRequest = this.props.serviceRequest
    this.setState({ loading: true })

    ServiceRequestService.generateServiceRequest(serviceRequest.id, [this.props.username])
      .then((response) => {
        if (!response || !response.data) {
          throw new Error("Invalid response format")
        }

        const docId = response.uri.split("/").pop()

        const downloadUrl = `https://docs.google.com/document/d/${docId}/export?format=pdf`

        const link = document.createElement("a")
        link.target = "_blank"
        link.href = downloadUrl
        link.setAttribute("download", `ServiceRequest_${serviceRequest.id}.pdf`)

        // Append to body, click, and clean up
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)

        this.setStateIfMounted({ loading: false })
      })
      .catch((err) => {
        this.setStateIfMounted({
          loading: false,
          error: err.message || "Failed to download service request",
        })

        if (this.props.onError) {
          this.props.onError(err.message || "Failed to download service request")
        }
      })
      .finally(() => {
        this.setState({ loading: false })
      })
  }

  /**
   * Export a service request to Google Drive.
   * @param e - The event.
   */
  exportServiceRequest(e) {
    e.preventDefault()
    let serviceRequest = this.props.serviceRequest
    this.setState({ loading: true })

    ServiceRequestService.generateServiceRequest(serviceRequest.id, [this.props.username])
      .then((response) => {
        this.setStateIfMounted({ loading: false })

        // Create an element to interact with the retrieved Google Documents
        // URI.
        const link = document.createElement("a")
        link.target = "_blank"
        link.href = response.uri
        link.setAttribute("download", `${serviceRequest.id}.pdf`)
        document.body.appendChild(link)
        link.click()
        link.parentNode.removeChild(link)
      })
      .catch((err) => {
        this.setStateIfMounted({ loading: false, error: err })
      })
  }

  /**
   * Get a list of default tools associated with a service request.
   * @returns {string[]} - The default tools.
   */
  getDefaultTools() {
    return [
      t("event.defaultTools.multimeter"),
      t("event.defaultTools.dc_string_diagnostic"),
      t("event.defaultTools.teamviewer_laptop"),
      t("event.defaultTools.smart_phone_with_setup"),
      t("event.defaultTools.spare_ethernet_patch"),
      t("event.defaultTools.30ft_ethernet_cable"),
      t("event.defaultTools.ferrule_kit"),
      t("event.defaultTools.wire_nut_clip_connectors"),
      t("event.defaultTools.usb_hub"),
      t("event.defaultTools.spare_racking_and_replacment"),
      t("event.defaultTools.mc4_andstring_level"),
      t("event.defaultTools.ethernet_network_cable"),
      t("event.defaultTools.small_jeweler"),
      t("event.defaultTools.paperclip"),
      t("event.defaultTools.flir_camera"),
      t("event.defaultTools.spare_barrel_fuses"),
    ]
  }

  /**
   * Get a list of default tasks associated with a service request.
   * @returns {string[]} - The default tasks.
   */
  getDefaultTasks() {
    return [
      t("event.defaultTasks.replace_microinverters"),
      t("event.defaultTasks.replace_inverter"),
      t("event.defaultTasks.complete_string_testing"),
      t("event.defaultTasks.check_and_update_inverter"),
      t("event.defaultTasks.update_usr_router"),
      t("event.defaultTasks.update_sim_card"),
      t("event.defaultTasks.install_hukesflux"),
      t("event.defaultTasks.install_kippzonen"),
      t("event.defaultTasks.photograph_the_interior"),
    ]
  }

  /**
   * Get a list of default check list items. (EP-3257)
   * @returns {string[]} - The default check list items.
   */
  getDefaultCheckList() {
    return [
      t("event.defaultCheckLists.confirm_inverters"),
      t("event.defaultCheckLists.econode_enclosure"),
      t("event.defaultCheckLists.call_asset_management_team_contact"),
    ]
  }

  getServiceRequestProject() {
    if (this.state.formData) {
      return this.props.projects.find((project) => {
        return project.code === this.state.formData.location.project
      })
    }
  }

  getServiceRequestSite(siteCode) {
    if (this.state.formData) {
      let project = this.getServiceRequestProject()
      if (project) {
        let searchCode
        if (siteCode) {
          searchCode = siteCode
        } else {
          searchCode = this.state.formData.location.site
        }
        const site = Object.values(project.sites).find((site) => {
          return site.code === searchCode
        })
        return site
      }
    }
  }

  getServiceRequestSystem() {
    if (this.state.formData) {
      let site = this.getServiceRequestSite()
      if (site) {
        return Object.values(site.systems).find((system) => {
          return system.code === this.state.formData.location.system
        })
      }
    }
  }

  onFormChange(form) {
    if (this.state.formData && this.state.formData.location) {
      if (
        this.state.formData.location.project !== form.formData.location.project ||
        this.state.formData.location.site !== form.formData.location.site ||
        this.state.formData.location.system !== form.formData.location.system
      ) {
        let newData = form.formData
        if (form.formData.onSiteContactNotes === undefined) {
          let site = this.getServiceRequestSite(form.formData.location.site)
          if (site !== undefined && site.accessNotes !== undefined) {
            newData.onSiteContactNotes = site.accessNotes
          }
        }

        // When the location changes we need to update the formData so that the dropdown options can be updated
        // We don't do this for all changes as we don't want to rerender the form every time (e.g. when a date is being modified)
        this.setState({ formData: newData })
      }
    }
  }

  handleFormSubmit = (form) => {
    if (this.props.serviceRequest.id) {
      this.updateServiceRequest(form)
    } else {
      this.createServiceRequest(form)
    }
  }

  render() {
    if (!this.props.serviceRequestSchema || !this.props.events || this.state.loading) {
      return <Loading />
    }

    let schema = this.props.serviceRequestSchema
    // Remove the default value from the phone number
    delete schema.properties.contactNumber.default

    return (
      <div className="item-details content-with-controls" style={{ overflow: "auto" }}>
        <div
          className={"item-details-title " + (this.props.serviceRequest.id ? "item-edit-title" : "item-create-title")}
        >
          <h2>
            {this.isReadOnly()
              ? t("event.labels.view_service_request")
              : this.props.serviceRequest.id
                ? t("event.labels.edit_service_request")
                : t("event.labels.create_service_request")}
            {this.props.serviceRequest.id ? <span className="header-id">{this.props.serviceRequest.id}</span> : null}
            <div
              className="float-end"
              onClick={() => {
                this.props.actions.selectServiceRequest()
              }}
            >
              <Icon icon="close" className="close-button" />
            </div>
            {this.props.serviceRequest.userName ? (
              <span className="right-title">
                {t("labels.added_by")}: {this.props.serviceRequest.userName}
              </span>
            ) : null}
          </h2>
        </div>

        <div style={{ marginInline: "10px" }}>
          <Col className="ecogy-form">
            <ServiceRequestForm
              {...this.props}
              tools={this.getDefaultTools()}
              checklist={this.getDefaultCheckList()}
              tasks={this.getDefaultTasks()}
              organizationId={this.state.organizationId}
              onSubmit={this.handleFormSubmit}
              submitRef={this.submitRef}
              formData={this.state.formData}
              serviceRequestId={this.props.serviceRequest.id}
              actions={{
                changeView: this.props.actions.changeView
              }}
            />
            <Row className="ecogy-form-buttons" style={{ marginLeft: "-10px" }}>
              {this.props.serviceRequest.id ? (
                <React.Fragment>
                  <Col className="message-section" sm="10">
                    {this.renderMessages()}
                  </Col>
                  <Col className="button-section" sm="12">
                    {
                      <Button
                        color="primary"
                        onClick={() => this.submitRef.current.click()}
                        disabled={this.isReadOnly()}
                      >
                        {t("buttons.update")}
                      </Button>
                    }
                    <Button color="primary" type="submit" onClick={this.downloadServiceRequest}>
                      {t("buttons.download")}
                    </Button>
                    <Button color="primary" type="submit" onClick={this.exportServiceRequest}>
                      {t("buttons.export")}
                    </Button>
                    <Button
                      color="danger"
                      type="submit"
                      onClick={this.deleteServiceRequest}
                      disabled={this.isReadOnly()}
                    >
                      {t("buttons.delete")}
                    </Button>
                  </Col>
                </React.Fragment>
              ) : (
                <React.Fragment>
                  <Col className="message-section" sm="10">
                    {this.renderMessages()}
                  </Col>
                  <Col className="button-section" sm="12">
                    <Button color="primary" onClick={() => this.submitRef.current.click()} disabled={this.isReadOnly()}>
                      {t("buttons.create")}
                    </Button>
                  </Col>
                </React.Fragment>
              )}
            </Row>
          </Col>
        </div>
      </div>
    )
  }

  renderMessages() {
    return (
      <FormError
        error={this.state.error}
        toggle={() => {
          this.setStateIfMounted({ error: null })
        }}
      />
    )
  }
}

export default ServiceRequestDetails

import React from "react"

import Settings from "@common/Settings"
import EcosuiteComponent, { Error } from "@common/EcosuiteComponent"

import GraphSelector from "@dashboard/GraphSelector"
import EnergyUtils from "@dashboard/energy/EnergyUtils"
import DatumUtils from "@dashboard/energy/graphs/datums/DatumUtils"
import SourceDatumUtils from "@dashboard/energy/graphs/datums/SourceDatumUtils"

import SiteWeatherLine from "../weather/SiteWeatherLine"
import EventLineGraph from "@dashboard/event/line-graph/EventLineGraph"

import TotalAreaGraph from "@dashboard/energy/graphs/TotalAreaGraph"

import TotalGenerationBarGraph from "@dashboard/energy/graphs/generation/TotalGenerationBarGraph"
import GenerationLineGraph from "@dashboard/energy/graphs/generation/GenerationLineGraph"
import GenerationStackedBarGraph from "@dashboard/energy/graphs/generation/GenerationStackedBarGraph"
import ExpectedGenerationLineGraph from "@dashboard/energy/graphs/generation/ExpectedGenerationLineGraph"
import GenerationPowerLineGraph from "@dashboard/energy/graphs/generation/GenerationPowerLineGraph"

import TotalConsumptionBarGraph from "@dashboard/energy/graphs/consumption/TotalConsumptionBarGraph"
import PredictedConsumptionLineGraph from "@dashboard/energy/graphs/consumption/PredictedConsumptionLineGraph"
import ConsumptionPowerLineGraph from "@dashboard/energy/graphs/consumption/ConsumptionPowerLineGraph"

import SolarFluxGraph from "@dashboard/energy/graphs/SolarFluxGraph"
import TotalPowerLineGraph from "@dashboard/energy/graphs/TotalPowerLineGraph"
import PowerFactorBarGraph from "@dashboard/energy/graphs/PowerFactorBarGraph"

import SourceTable from "../source/SourceTable"
import AssetSummary from "../info/AssetSummary"

import System from "../system/System"
import ForecastGenerationLineGraph from "@dashboard/energy/graphs/generation/ForecastGenerationLineGraph"
import SourceGenerationLineGraph from "@dashboard/energy/graphs/generation/SourceGenerationLineGraph"
import EcosuiteForecastGenerationLineGraph from "@dashboard/energy/graphs/generation/EcosuiteForecastGenerationLineGraph"
import StoreageLineGraph from "@dashboard/energy/graphs/storage/StorageLineGraph"
import StoreageLineGraphBasic from "@dashboard/energy/graphs/storage/StorageLineGraphBasic"
import i18n from "src/i18n"

const { t } = i18n

class Site extends EcosuiteComponent {
  constructor(props) {
    super(props)

    this.state = {
      energyProjectGraphView: Settings.getSetting("energyProjectGraphView", "total"),
    }
    this.selectGraph = this.selectGraph.bind(this)
  }

  selectGraph(graph) {
    Settings.setSetting("energyProjectGraphView", graph)
    this.setState({ energyProjectGraphView: graph })
  }

  renderContent() {
    let siteStatus = this.props.siteStatus

    let unmatchedNodes = this.isContentValid(siteStatus)
      ? EnergyUtils.filterOutChildNodes(siteStatus, Object.values(siteStatus.systems))
      : []

    return (
      <div className="site-content">
        <h4
          className={
            "content-title " + (this.isContentValid(siteStatus) && siteStatus.status ? siteStatus.status : "no-status")
          }
        >
          {this.props.site.name} ({this.props.site.code})
        </h4>

        <AssetSummary
          className="details-info"
          range={this.props.range}
          asset={this.props.site}
          project={this.props.project}
          site={this.props.site}
          status={siteStatus}
          readings={this.props.readings}
          consumptionCost={this.props.consumptionCost}
          predictedConsumption={this.props.predictedConsumption}
          expectedGeneration={this.props.expectedGeneration}
          predictedGeneration={this.props.predictedGeneration}
          showGeneration={this.props.showGeneration}
          showConsumption={this.props.showConsumption}
          showStorage={this.props.showStorage}
        />

        {EnergyUtils.getSystems(this.props.site).map((system) => {
          let systemStatus = this.isContentValid(siteStatus) ? siteStatus.systems[system.code] : undefined
          let systemReadings = this.isContentValid(this.props.readings)
            ? this.props.readings.systems[system.code]
            : this.props.readings
          let systemConsumptionCost = this.isContentValid(this.props.consumptionCost)
            ? this.props.consumptionCost.systems[system.code]
            : this.props.consumptionCost
          let systemPredictedConsumption = this.isContentValid(this.props.predictedConsumption)
            ? this.props.predictedConsumption.systems[system.code]
            : this.props.predictedConsumption
          let systemExpectedGeneration = this.isContentValid(this.props.expectedGeneration)
            ? this.props.expectedGeneration.systems[system.code]
            : this.props.expectedGeneration
          let systemPredictedGeneration = this.isContentValid(this.props.predictedGeneration)
            ? this.props.predictedGeneration.systems[system.code]
            : this.props.predictedGeneration
          return (
            <System
              key={system.code}
              project={this.props.project}
              site={this.props.site}
              system={system}
              systemStatus={systemStatus}
              metaData={this.props.metaData}
              readings={systemReadings}
              datums={this.props.datums}
              consumptionCost={systemConsumptionCost}
              predictedConsumption={systemPredictedConsumption}
              expectedGeneration={systemExpectedGeneration}
              predictedGeneration={systemPredictedGeneration}
              range={this.props.range}
              showGeneration={this.props.showGeneration}
              showConsumption={this.props.showConsumption}
              showStorage={this.props.showStorage}
              userAlerts={this.props.userAlerts}
            />
          )
        })}

        {unmatchedNodes.length > 0 ? (
          <React.Fragment>
            <h5 className="content-subtitle">{t("energy.headings.unmatched_sources")}</h5>
            <SourceTable
              metaData={this.props.metaData}
              nodesStatus={unmatchedNodes}
              showGeneration={this.props.showGeneration}
              showConsumption={this.props.showConsumption}
              showStorage={this.props.showStorage}
              range={this.props.range}
              datums={this.props.datums}
              project={this.props.project}
            />
          </React.Fragment>
        ) : null}

        {this.getSiteGraph()}
      </div>
    )
  }

  getSiteGraph() {
    if (this.isContentError(this.props.datums)) {
      return <Error error={this.props.datums.getError()} />
    } else if (this.isContentValid(this.props.datums)) {
      let graphDatums = DatumUtils.groupDatums(Object.values(this.props.site.systems), this.props.datums.systems)
      let consumptionCostDatums = this.isContentValid(this.props.consumptionCost)
        ? DatumUtils.groupConsumptionCostDatums(
            this.props.consumptionCost.consumptionCost.totalCost
              ? [this.props.site]
              : Object.values(this.props.site.systems),
            this.props.consumptionCost.consumptionCost.totalCost
              ? { [this.props.site.code]: this.props.consumptionCost }
              : this.props.consumptionCost.systems,
          ) // TODO add site!
        : null
      let predictedConsumptionDatums = this.isContentValid(this.props.predictedConsumption)
        ? DatumUtils.groupPredictedConsumptionDatums(
            Object.values(this.props.site.systems),
            this.props.predictedConsumption.systems,
          )
        : null
      let expectedGenerationDatums = this.isContentValid(this.props.expectedGeneration)
        ? DatumUtils.groupExpectedGenerationDatums(
            Object.values(this.props.site.systems),
            this.props.expectedGeneration.systems,
          )
        : null
      let predictedGenerationDatums = this.isContentValid(this.props.predictedGeneration)
        ? DatumUtils.groupPredictedGenerationDatums(
            Object.values(this.props.site.systems),
            this.props.predictedGeneration.systems,
          )
        : null

      let batteryGraphDatums = this.isContentValid(this.props.batteryDatums)
        ? DatumUtils.groupDatumsBySource(this.props.batteryDatums.data)
        : null

      let sourceGraphDatums = SourceDatumUtils.groupSystemSourceDatums(Object.values(this.props.datums.systems))

      let range = this.props.datumsRange
      let aggregation = this.props.datumsAggregation

      let centered =
        this.state.energyProjectGraphView === "siteGeneration" ||
        this.state.energyProjectGraphView === "siteConsumption" ||
        this.state.energyProjectGraphView === "systemGenerationStacked"

      let eventLine = (
        <EventLineGraph
          groups={this.props.groups}
          projects={[this.props.project]}
          range={this.props.range}
          dotAggregate={aggregation + "s"}
          type="energy"
          centered={centered}
          project={this.props.project.code}
          site={this.props.site.code}
          style={{
            marginLeft: "60px",
            width: "calc(100% - 90px)",
          }}
        />
      )

      let weatherLine = (
        <SiteWeatherLine
          range={this.props.range}
          project={this.props.project}
          site={this.props.site}
          style={{
            marginLeft: "60px",
            width: "calc(100% - 90px)",
          }}
        />
      )

      if (graphDatums && Object.keys(graphDatums).length > 0) {
        let graphs = {
          total: {
            graph: (
              <React.Fragment>
                {this.props.showConsumption ? (
                  <React.Fragment>
                    <SiteWeatherLine
                      range={this.props.range}
                      project={this.props.project}
                      site={this.props.site}
                      style={{
                        marginLeft: "60px",
                        marginRight: "60px",
                        width: "calc(100% - 150px)",
                      }}
                    />
                    <EventLineGraph
                      groups={this.props.groups}
                      projects={[this.props.project]}
                      range={this.props.range}
                      dotAggregate={aggregation + "s"}
                      type="energy"
                      centered={centered}
                      project={this.props.project.code}
                      site={this.props.site.code}
                      style={{
                        marginLeft: "60px",
                        marginRight: "60px",
                        width: "calc(100% - 150px)",
                      }}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {weatherLine}
                    {eventLine}
                  </React.Fragment>
                )}

                <TotalAreaGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  datums={this.props.datums}
                  consumptionCostDatums={consumptionCostDatums}
                  predictedConsumptionDatums={predictedConsumptionDatums}
                  expectedGenerationDatums={expectedGenerationDatums}
                  predictedGenerationDatums={predictedGenerationDatums}
                  range={range}
                  aggregation={aggregation}
                  showGeneration={this.props.showGeneration}
                  showConsumption={this.props.showConsumption}
                  showStorage={this.props.showStorage}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: `${t("energy.graphNames.site_generation_and_consumption")} (kWh)`,
          },
        }

        if (this.props.showGeneration) {
          graphs.siteGeneration = {
            graph: (
              <React.Fragment>
                {weatherLine}
                {eventLine}
                <TotalGenerationBarGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  datums={this.props.datums}
                  consumptionCostDatums={consumptionCostDatums}
                  predictedConsumptionDatums={predictedConsumptionDatums}
                  expectedGenerationDatums={expectedGenerationDatums}
                  predictedGenerationDatums={predictedGenerationDatums}
                  range={range}
                  aggregation={aggregation}
                  showGeneration={this.props.showGeneration}
                  showConsumption={this.props.showConsumption}
                  showStorage={this.props.showStorage}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: `${t("energy.graphNames.site_generation")} (kWh)`,
          }
          graphs.systemGeneration = {
            graph: (
              <React.Fragment>
                {weatherLine}
                {eventLine}
                <GenerationLineGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  datums={this.props.datums}
                  range={range}
                  aggregation={aggregation}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: `${t("energy.graphNames.system_generation")} (kWh)`,
          }
          graphs.systemGenerationStacked = {
            graph: (
              <React.Fragment>
                {weatherLine}
                {eventLine}
                <GenerationStackedBarGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  datums={this.props.datums}
                  range={range}
                  aggregation={aggregation}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: `${t("energy.graphNames.system_generation_stacked")} (kWh)`,
          }
          graphs.systemGenerationNormalised = {
            graph: (
              <React.Fragment>
                {weatherLine}
                {eventLine}
                <GenerationLineGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  datums={this.props.datums}
                  range={range}
                  aggregation={aggregation}
                  normalise={true}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: t("energy.graphNames.system_generation_normalized"),
          }
          graphs.sourceGenerationNormalised = {
            graph: (
              <React.Fragment>
                {weatherLine}
                {eventLine}
                <SourceGenerationLineGraph
                  project={this.props.project}
                  site={this.props.site}
                  range={this.props.range}
                  aggregation={this.props.datumsAggregation}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: t("energy.graphNames.inverter_generation_normalized"),
          }
          graphs.expectedSiteGeneration = {
            graph: (
              <React.Fragment>
                {weatherLine}
                {eventLine}
                <ExpectedGenerationLineGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  predictedConsumptionDatums={predictedConsumptionDatums}
                  expectedGenerationDatums={expectedGenerationDatums}
                  predictedGenerationDatums={predictedGenerationDatums}
                  datums={this.props.datums}
                  range={range}
                  aggregation={aggregation}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: `${t("energy.graphNames.expected_site_generation")} (kWh)`,
          }
          graphs.forecastSiteGeneration = {
            graph: (
              <React.Fragment>
                {weatherLine}
                {eventLine}
                <ForecastGenerationLineGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  predictedConsumptionDatums={predictedConsumptionDatums}
                  expectedGenerationDatums={expectedGenerationDatums}
                  predictedGenerationDatums={predictedGenerationDatums}
                  datums={this.props.datums}
                  range={range}
                  aggregation={aggregation}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: `${t("energy.graphNames.forecast_site_generation")} (kWh)`,
          }
          graphs.ecosuiteForecastSiteGeneration = {
            graph: (
              <React.Fragment>
                {weatherLine}
                {eventLine}
                <EcosuiteForecastGenerationLineGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  datums={this.props.datums}
                  range={range}
                  aggregation={aggregation}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: `${t("energy.graphNames.ecosuite_forecast_site_generation")} (kWh)`,
          }
        }
        if (this.props.showConsumption) {
          graphs.siteConsumption = {
            graph: (
              <React.Fragment>
                {this.props.showConsumption ? (
                  <React.Fragment>
                    <SiteWeatherLine
                      range={this.props.range}
                      project={this.props.project}
                      site={this.props.site}
                      style={{
                        marginLeft: "60px",
                        marginRight: "60px",
                        width: "calc(100% - 150px)",
                      }}
                    />
                    <EventLineGraph
                      groups={this.props.groups}
                      projects={[this.props.project]}
                      range={this.props.range}
                      dotAggregate={aggregation + "s"}
                      type="energy"
                      centered={centered}
                      project={this.props.project.code}
                      site={this.props.site.code}
                      style={{
                        marginLeft: "60px",
                        marginRight: "60px",
                        width: "calc(100% - 150px)",
                      }}
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {weatherLine}
                    {eventLine}
                  </React.Fragment>
                )}

                <TotalConsumptionBarGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  datums={this.props.datums}
                  consumptionCostDatums={consumptionCostDatums}
                  predictedConsumptionDatums={predictedConsumptionDatums}
                  expectedGenerationDatums={expectedGenerationDatums}
                  predictedGenerationDatums={predictedGenerationDatums}
                  range={range}
                  aggregation={aggregation}
                  showGeneration={this.props.showGeneration}
                  showConsumption={this.props.showConsumption}
                  showStorage={this.props.showStorage}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: `${t("energy.graphNames.site_consumption")} (kWh)`,
          }

          graphs.expectedSiteConsumption = {
            graph: (
              <React.Fragment>
                {weatherLine}
                {eventLine}
                <PredictedConsumptionLineGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  predictedConsumptionDatums={predictedConsumptionDatums}
                  datums={this.props.datums}
                  range={range}
                  aggregation={aggregation}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: `${t("energy.graphNames.expected_site_consumption")} (kWh)`,
          }
        }

        let readingsGraphName = `${t("energy.graphNames.site_generation_and_demand")} (kWh)`
        if (!this.props.showConsumption) {
          readingsGraphName = `${t("energy.graphNames.site_generation")} (kWh)`
        } else if (!this.props.showGeneration) {
          readingsGraphName = `${t("energy.graphNames.site_demand")} (kWh)`
        }

        graphs.siteReadings = {
          graph: (
            <React.Fragment>
              {eventLine}
              <TotalPowerLineGraph
                project={this.props.project}
                site={this.props.site}
                assets={EnergyUtils.getSystems(this.props.site)}
                graphDatums={graphDatums}
                datums={this.props.datums}
                range={range}
                aggregation={aggregation}
                showGeneration={this.props.showGeneration}
                showConsumption={this.props.showConsumption}
                showStorage={this.props.showStorage}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>
          ),
          name: readingsGraphName,
        }

        if (this.props.showGeneration) {
          graphs.systemGenerationPower = {
            graph: (
              <React.Fragment>
                {eventLine}
                <GenerationPowerLineGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  datums={this.props.datums}
                  range={range}
                  aggregation={aggregation}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: `${t("energy.graphNames.system_generation")} (kW)`,
          }
        }

        if (this.props.showConsumption) {
          graphs.systemConsumptionPower = {
            graph: (
              <React.Fragment>
                {eventLine}
                <ConsumptionPowerLineGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  datums={this.props.datums}
                  range={range}
                  aggregation={aggregation}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: `${t("energy.graphNames.system_demand")} (kW)`,
          }
        }

        if (this.props.showStorage) {
          graphs.systemStorage = {
            graph: (
              <React.Fragment>
                {weatherLine}
                {eventLine}
                <StoreageLineGraph
                  project={this.props.project}
                  site={this.props.site}
                  assets={EnergyUtils.getSystems(this.props.site)}
                  graphDatums={graphDatums}
                  datums={this.props.datums}
                  range={range}
                  aggregation={aggregation}
                  selectRange={this.props.selectRange}
                />
              </React.Fragment>
            ),
            name: `${t("energy.graphNames.system_storage")} (kWh)`,
          }
          graphs.systemStorageFull = {
            graph: (
              <React.Fragment>
                {this.props.batteryDatums ? (
                  <StoreageLineGraphBasic
                    key={this.props.site.code}
                    project={this.props.project}
                    site={this.props.site}
                    assets={EnergyUtils.getSystems(this.props.site)}
                    batteryGraphDatums={batteryGraphDatums}
                    graphDatums={graphDatums}
                    range={this.props.range}
                    aggregation={aggregation}
                    selectRange={this.props.selectRange}
                  />
                ) : null}
              </React.Fragment>
            ),
            name: `System Storage Basic (kWh)`,
          }
        }

        graphs.solarFluxGraph = {
          graph: (
            <SolarFluxGraph
              project={this.props.project}
              site={this.props.site}
              showGeneration={this.props.showGeneration}
              showConsumption={this.props.showConsumption}
              showStorage={this.props.showStorage}
            />
          ),
          name: `${t("energy.graphNames.live_energy_graph")} (kW)`,
        }

        graphs.powerFactorGraph = {
          graph: (
            <React.Fragment>
              {eventLine}
              <PowerFactorBarGraph
                project={this.props.project}
                site={this.props.site}
                assets={EnergyUtils.getSystems(this.props.site)}
                graphDatums={sourceGraphDatums}
                datums={this.props.datums}
                range={range}
                aggregation={aggregation}
                selectRange={this.props.selectRange}
              />
            </React.Fragment>
          ),
          name: t("energy.graphNames.power_factor"),
        }

        return (
          <GraphSelector
            selectedGraph={this.state.energyProjectGraphView}
            selectGraph={this.selectGraph}
            graphs={graphs}
            selectRange={this.props.selectRange}
          />
        )
      }
    } else {
      return <GraphSelector range={this.props.range} selectRange={this.props.selectRange} />
    }
  }
}

export default Site

import React from "react"
import { ResponsiveContainer, XAxis, YAxis, Tooltip, ComposedChart, Line, Legend, Area } from "recharts"
import moment from "moment-timezone"

import { Loading, Error } from "@common/EcosuiteComponent"
import { GRAPH_COLORS } from "@common/module/EcosuiteView"
import DateRangeGraph from "../DateRangeGraph"
import GraphUtils from "@common/utils/GraphUtils"
import EnergyUtils from "@dashboard/energy/EnergyUtils"

export default class StoreageLineGraphBasic extends DateRangeGraph {
  getData() {
    let wattHours = 0
    let bounds = {
      floor: 200000,
      ceiling: 0,
    }

    let data = Object.entries(this.props.batteryGraphDatums).map(([dateKey, datum]) => {
      let date = moment(dateKey)
      let BatteryOutput = 0
      let systemSOC = 0
      let systemBESSCount = 0
      let powerRate = 0

      const getDeviceTypeFromPath = (sourceId) => {
        const parts = sourceId.split("/")
        return parts[4]
      }

      Object.entries(datum).forEach(([sourceId, source]) => {
        if (getDeviceTypeFromPath(sourceId) === "STO") {
          BatteryOutput = source.wattHours ? source.wattHours * -1 : 0
          wattHours += wattHours < 50000 ? source.wattHours : 0
        }
        if (getDeviceTypeFromPath(sourceId) === "BESS") {
          systemSOC += source.soc ? source.soc : 0
          powerRate += source.powerRate
          systemBESSCount += 1
        }
      })

      const time = date.toDate().getTime()
      const timeMod = (time / 518400) % 180
      const timeModSin = Math.sin(timeMod - 91)
      const SolarGeneration = timeModSin > 0 ? timeModSin * 40000 : 0

      const updateBounds = (wattHours) => {
        bounds.ceiling = Math.max(bounds.ceiling, wattHours)
        bounds.floor = Math.min(bounds.floor, wattHours)
      }

      updateBounds(wattHours)

      return {
        date: date.toDate(),
        time: date.toDate().getTime(),
        name: date.format("lll"),
        BatteryOutput,
        SOC: systemSOC / systemBESSCount,
        SolarGeneration: SolarGeneration,
        SystemOutput: SolarGeneration + BatteryOutput,
        powerRate,
      }
    })

    return data
  }

  renderContent() {
    if (this.props.batteryGraphDatums && this.props.graphDatums) {
      if (Object.keys(this.props.batteryGraphDatums).length === 0) {
        return <Error error={"No Batteries configured for project"} />
      }
      let data = this.getData()
      const graphType = "monotone"
      const dot = false

      return (
        <div style={{ marginTop: "20px", display: "inline-flex", width: "100%" }}>
          <ResponsiveContainer width="100%" height={250}>
            <ComposedChart data={data} margin={{ top: 5, right: 30, left: 0, bottom: 10 }} onClick={this.zoomDateRange}>
              <XAxis
                dataKey="time"
                type="number"
                scale="time"
                domain={[this.props.range.start, this.props.range.end]}
                tickFormatter={(time) => {
                  return GraphUtils.dateFormat(time, this.props.range, this.props.aggregation)
                }}
              />
              <YAxis
                yAxisId="left"
                tickFormatter={(value) => {
                  return EnergyUtils.formatWattHoursAsKiloWattHours(value)
                }}
              />
              <YAxis yAxisId="right" orientation="right" />

              <Tooltip
                wrapperStyle={{ color: "#000", pointerEvents: "auto" }}
                // eslint-disable-next-line no-unused-vars
                formatter={(value, name, props) => {
                  if (props.dataKey === "SOC") {
                    return Math.round(value) + "%"
                  }
                  if (props.dataKey === "powerRate") {
                    return EnergyUtils.formatWattsAsKiloWatts(value)
                  }

                  return EnergyUtils.formatWattHoursAsKiloWattHours(value)
                }}
                labelFormatter={(label) => {
                  return GraphUtils.formatDateTooltip(label, this.props.range, this.props.aggregation)
                }}
              />

              <Line yAxisId={"left"} type={graphType} dataKey={"powerRate"} stroke={GRAPH_COLORS[1]} dot={dot} />
              <Area
                yAxisId={"left"}
                type={graphType}
                dataKey={"BatteryOutput"}
                fill={GRAPH_COLORS[6]}
                stroke={GRAPH_COLORS[6]}
                dot={dot}
              />
              <Area
                yAxisId={"left"}
                type={graphType}
                dataKey={"SolarGeneration"}
                fill={GRAPH_COLORS[7]}
                stroke={GRAPH_COLORS[7]}
                dot={dot}
              />
              <Line
                yAxisId={"left"}
                type={graphType}
                dataKey={"SystemOutput"}
                stroke={GRAPH_COLORS[2]}
                strokeWidth={3}
                dot={dot}
              />
              <Line yAxisId={"right"} type={graphType} dataKey={"SOC"} stroke={GRAPH_COLORS[21]} dot={dot} />
              <Legend />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      )
    }
    return <Loading />
  }
}

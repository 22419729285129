import React from "react"
import moment from "moment"

import EcosuiteComponent from "@common/EcosuiteComponent"
import FinanceUtils from "@dashboard/finance/FinanceUtils"
import Utils from "@common/utils/Utils"
import ProFormaUtils from "./ProFormaUtils"
import ProFormaIRRTooltip from "../ProFormaIRRTooltip"
import ProFormaInputsGeneration from "./ProFormaInputsGeneration"
import i18n from "src/i18n"
import UncontrolledTooltip from "@common/display/ToolTip/UncontrolledTooltip"

const { t } = i18n
export default class ProFormaInputsDetails extends EcosuiteComponent {
  renderContent() {
    const { project, proForma, proFormaIRR } = this.props

    const incomeCashFlows = proForma.cashFlows.filter((cashFlow) => cashFlow.category === "Income")
    const ppaCashFlows = incomeCashFlows.filter((cashFlow) => cashFlow.account === "PPA/FIT")
    const srecCashFlows = incomeCashFlows.filter((cashFlow) => cashFlow.account === "SREC Revenue")
    const csiCashFlows = incomeCashFlows.filter((cashFlow) => cashFlow.account.startsWith("Community Solar"))
    const grantCashFlows = incomeCashFlows.filter((cashFlow) => cashFlow.account.startsWith("Grant"))

    const expenseCashFlows = proForma.cashFlows.filter((cashFlow) => cashFlow.category === "Expense")

    return (
      <table className="project-table pro-forma-inputs">
        <thead>
          <tr>
            <th colSpan={3}>{t("economics.headings.solar_project_assumptions", { projectName: project.name })}</th>
          </tr>
        </thead>
        <tbody>
          <tr className="sub-account">
            <td>
              {t("economics.labels.Unlevered IRR")}
              <ProFormaIRRTooltip project={project} proForma={proForma} id="unlevered-irr" />
            </td>
            <td>{Utils.formatPercent(proFormaIRR.unleveredIrr)}</td>
            <td></td>
          </tr>
          <tr className="sub-account">
            <td>{t("economics.labels.State / Province")}</td>
            <td>{project.state}</td>
            <td></td>
          </tr>
          <tr className="sub-account">
            <td>{t("economics.labels.system_size")}</td>
            <td>{project.dcSize ?? proForma.systemSize}</td>
            <td></td>
          </tr>
          {<ProFormaInputsGeneration {...this.props} />}
          <tr className="sub-account">
            <td>{t("economics.labels.PTO/COD Date")}</td>
            <td>{ProFormaUtils.getPTO_CODDate(project, proForma).format("ll")}</td>
            <td></td>
          </tr>
          <tr className="sub-account">
            <td>{t("economics.labels.Project Life")}</td>
            <td>
              {proForma.projectLife} {t("economics.labels.Years")}
            </td>
            <td></td>
          </tr>
          <tr className="account">
            <td>{t("economics.labels.Operational Revenues")}</td>
            <td>{t("economics.labels.$/W DC")}</td>
          </tr>
          {ppaCashFlows.map((ppaCashFlow) => {
            return this.renderGenerationPayments(ppaCashFlow)
          })}
          {srecCashFlows.map((srecCashFlow) => {
            return this.renderGenerationPayments(srecCashFlow)
          })}
          {csiCashFlows.map((csiCashFlow) => {
            return this.renderGenerationPayments(csiCashFlow)
          })}
          {grantCashFlows.map((grantCashFlow) => {
            return this.renderScheduledPayments(grantCashFlow)
          })}
          <tr className="account">
            <td>{t("economics.labels.Operational Expenses")}</td>
          </tr>
          {expenseCashFlows.map((expenseCashFlow) => {
            return this.renderExpense(expenseCashFlow)
          })}
        </tbody>
      </table>
    )
  }

  renderGenerationPayments(cashFlow) {
    const payments = cashFlow.payments.filter(
      (payment) =>
        FinanceUtils.isOperatingRevenuePayment(cashFlow, payment) &&
        (payment.recurrence.rateType !== "fixed" || payment.recurrence.startRate),
    )
    if (payments.length) {
      return (
        <React.Fragment key={cashFlow.id}>
          <tr className="sub-account">
            <td>{cashFlow.name}</td>
          </tr>
          {payments.map((payment, idx) => {
            return (
              <React.Fragment key={idx}>
                <tr className="sub-account-title">
                  <td>{`${payment.path} ${payment.paymentType}`}</td>
                </tr>
                {
                  <React.Fragment key={idx}>
                    {this.renderRate(payment)}
                    <tr className="sub-account-detail">
                      <td>{t("labels.start")}</td>
                      <td>{moment(payment.recurrence.startDate).format("ll")}</td>
                    </tr>
                    <tr className="sub-account-detail">
                      <td>{t("table_headings.term")}</td>
                      <td>{payment.recurrence.term}</td>
                    </tr>
                  </React.Fragment>
                }
              </React.Fragment>
            )
          })}
        </React.Fragment>
      )
    } else {
      return null
    }
  }

  renderRate(payment) {
    if (payment.recurrence.rateType === "fixed") {
      return (
        <React.Fragment>
          <tr className="sub-account-detail">
            <td>{t("table_headings.rate")}</td>
            <td>
              {Utils.formatCurrency(payment.recurrence.startRate, undefined, 20, 2)}
              {ProFormaUtils.getPaymentUnit(payment)}
            </td>
          </tr>
          <tr className="sub-account-detail">
            <td>{t("table_headings.frequency")}</td>
            <td>{Utils.capitalizeFirstLetter(payment.recurrence.frequency)}</td>
          </tr>
          <tr className="sub-account-detail">
            <td>{t("table_headings.escalator")}</td>
            <td>{Utils.formatPercent(payment.recurrence.escalator ? payment.recurrence.escalator : 0)}</td>
          </tr>
        </React.Fragment>
      )
    } else if (payment.recurrence.rateType === "variable") {
      return (
        <tr className="sub-account-detail">
          <td>{t("economics.labels.Year Rates / kWh")}</td>
          <td>{payment.recurrence.rates.map((rate) => `$${rate}`).join(", ")}</td>
        </tr>
      )
    } else if (payment.recurrence.rateType === "monthly") {
      const getRateAverage = (rate) => {
        const subRateArray = Object.values(rate)
        const total = subRateArray.reduce((count, subRate) => {
          return count + subRate
        })
        return total / subRateArray.length
      }

      return (
        <tr className="sub-account-detail">
          <td>Year/Month rates / kWh</td>
          <td>
            {payment.recurrence.rates.map((rate, index) => {
              const id = payment.path.replace(/\//g, "-") + index
              return (
                <>
                  <span id={id}>${Math.round(getRateAverage(rate) * 100) / 100},&nbsp;</span>
                  <UncontrolledTooltip target={id}>
                    {Object.values(rate).map((subRate) => {
                      return "$" + subRate + ", "
                    })}
                  </UncontrolledTooltip>
                </>
              )
            })}
          </td>
        </tr>
      )
    }
  }

  renderScheduledPayments(cashFlow) {
    const payments = cashFlow.payments.filter(
      (payment) => payment.paymentType === "scheduled" && ProFormaUtils.getTotalScheduledPayments(payment),
    )
    if (payments.length) {
      return (
        <React.Fragment key={cashFlow.id}>
          <tr className="sub-account">
            <td>{cashFlow.name}</td>
          </tr>
          {payments.map((payment, idx) => {
            return (
              <React.Fragment key={idx}>
                <tr className="sub-account-title">
                  <td>{payment.paymentType}</td>
                  <td>{FinanceUtils.formatCurrencyWithCents(ProFormaUtils.getTotalScheduledPayments(payment))}</td>
                </tr>
              </React.Fragment>
            )
          })}
        </React.Fragment>
      )
    }
  }

  renderExpense(cashFlow) {
    return (
      <React.Fragment key={cashFlow.id}>
        <tr className="sub-account">
          <td>{cashFlow.name}</td>
        </tr>
        {cashFlow.payments.map((payment, idx) => {
          switch (payment.paymentType) {
            case "scheduled": {
              const total = ProFormaUtils.getTotalScheduledPayments(payment)
              if (total) {
                return (
                  <React.Fragment key={idx}>
                    <tr className="sub-account-title">
                      <td>{payment.paymentType}</td>
                      <td>{FinanceUtils.formatCurrencyWithCents(total)}</td>
                    </tr>
                  </React.Fragment>
                )
              }
              break
            }

            case "size":
            case "generation":
            case "recurring":
              return (
                <React.Fragment key={idx}>
                  <tr className="sub-account-title">
                    <td>{`${payment.path} ${payment.paymentType}`}</td>
                  </tr>
                  {this.renderRate(payment)}
                  <tr className="sub-account-detail">
                    <td>{t("labels.term")}</td>
                    <td>{moment(payment.recurrence.startDate).format("ll")}</td>
                  </tr>
                  <tr className="sub-account-detail">
                    <td>{t("table_headings.term")}</td>
                    <td>{payment.recurrence.term}</td>
                  </tr>
                </React.Fragment>
              )
            default:
              return null
          }
        })}
      </React.Fragment>
    )
  }
}
